import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Badge, Card, CardBody, CardHeader, Col, FormGroup, Input, InputGroup, Label, Row } from "reactstrap";
import { getAPI } from "../Base/API";
import CustomToolTip from "../../Utils/CustomToolTip";

export class ChannelAdd extends Component {
	state = {
		error: [],
		block: true,

		channelInstance: {
			id: null,
			propertyCode: null,
			user: null,
			password: null,
			secondaryUser: null,
			secondaryPassword: null,
			name: null,
			channelConfigId: null,
			active: false,
			maxInventoryDays: 0,
			sendAvailability: true,
			sendPrice: true,
			sendStopSales: true,
			sendCta: true,
			sendCtd: true,
			sendMinStay: true,
			sendMaxStay: true
		},
		supported: [],
		mandatoryFields: []
	};

	componentDidMount() {
		if (this.props.selectedChannel === "Airbnb") {
			const script = document.createElement("script");

			script.src = "https://www.airbnb.com/platform_js";
			script.async = true;

			document.body.appendChild(script);
		} else {
			this.GetChannelConfigState();
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.selectedChannel !== this.props.selectedChannel) {
			this.GetChannelConfigState();
		}
	}

    GetChannelConfigState() {
		getAPI(result => {
			const { data, error } = result;

			if (error) {
				var errorMessage = [];
				errorMessage.push({
					message: error.message,
					stack: error.stack,
					messageType: "danger"
				});
				this.setState({ error: errorMessage, block: false });
				return;
			}
			if (data) {
				const { channelInstance } = this.state;
				let mandatoryFields = [];
				let supported = [];

				if (data.response) {
					mandatoryFields = data.response.mandatoryFields;

					if (data.response.channelConfig) {
						supported = data.response.channelConfig.supported;
						if (mandatoryFields.some(field => field === "PropertyCode" && data.response.channelConfig.code === "HEYBE")) {
							channelInstance.propertyCode = 'heybe';
							this.changeState({
								target: {
									name: "propertyCode",
									value: 'heybe'
								}
							});
						}
					}					
				}

				this.setState({ block: false, mandatoryFields, supported, channelInstance }, () => this.updateSupportedData());
			}
			else {
				this.setState({ error: errorMessage, block: false });
            }
		}, `/api/Rate/ChannelConfig/v1/ChannelConfigState/${this.props.selectedChannelValue}`);

        this.changeState({
			target: {
				name: "channelConfigId",
				value: this.props.selectedChannelValue
			}
		});

		this.changeState({
			target: {
				name: "name",
				value: this.props.selectedChannel
			}
		});
	}

	updateSupportedData = () => {
		const info = ["Availability", "Price", "CTA", "CTD", "MaxStays", "MinStays", "StopSales", "MinAdvancedBookingOffset", "MaxAdvancedBookingOffset"];
		var channelInstance = { ...this.state.channelInstance };

		if (this.state.supported && this.state.supported.length > 0) {
			for (var i = 0; i < info.length; i++) {
				var name = 'send' + (info[i] === 'MaxStays' ? 'MaxStay' : info[i] === 'MinStays' ? 'MinStay' : info[i]);
				var value = false;

				if (this.state.supported.includes(info[i])) {
					value = true;
				}

				channelInstance[name] = value;
				this.props.handleChange({ target: { name, value } });
			}
		}
		else {
			info.forEach(el => {
				var name = 'send' + (el === 'MaxStays' ? 'MaxStay' : el === 'MinStays' ? 'MinStay' : el);
				const value = false;

				channelInstance[name] = value;
				this.props.handleChange({ target: { name, value } });
			})

		}
		this.setState({ channelInstance })
	}

	changeState({ target: { name, value } }) {
		this.setState(prevState => ({
			...prevState,
			channelInstance: {
				...prevState.channelInstance,
				[name]: value
			}
		}));
		this.props.handleChange({ target: { name, value } });
	}

	changeSendInfoState({ target: { name } }) {
		if (this.props.confirm) return;

		this.props.handleChange({
			target: { name, value: !this.state.channelInstance[name] }
		});

		this.setState(prevState => ({
			...prevState,
			channelInstance: {
				...prevState.channelInstance,
				[name]: !prevState.channelInstance[name]
			}
		}));
	}

	render() {
		const { selectedChannel, confirm } = this.props;
		const { supported } = this.state;

		const canEditChannelName = global.operationMode === "PmsLimited" || (global.operationMode === 'PmsFull' && global.sendRoomRate);

		const supportedOptions = [
			{
				name: "sendAvailability",
				tag: "Availability"
			},
			{
				name: "sendPrice",
				tag: "Price"
			},
			{
				name: "sendCta",
				tag: "CTA"
			},
			{
				name: "sendCtd",
				tag: "CTD"
			},
			{
				name: "sendMaxStay",
				tag: "MaxStays"
			},
			{
				name: "sendMinStay",
				tag: "MinStays"
			},
			{
				name: "sendStopSales",
				tag: "StopSales"
			},
			{
				name: "sendMinAdvancedBookingOffset",
				tag: "MinAdvancedBookingOffset"
			},
			{
				name: "sendMaxAdvancedBookingOffset",
				tag: "MaxAdvancedBookingOffset"
			}
		]

		return (
			<>
				<CardBody
					style={{
						display: selectedChannel === "Airbnb" ? "block" : "none"
					}}
				>
					<p className="lead">
						When you are ready to connect press the "Connect to
						AirBnB" to give permission to link the account.
					</p>
					<span
						className="airbnb-connect"
                        data-client-id={process.env.REACT_APP_AIRBNB_CODE}
						data-scope="property_management,messages_read,messages_write"
                        data-redirect-uri={process.env.REACT_APP_AIRBNB_URL}
					></span>
				</CardBody>
				<div
					style={{
						display: selectedChannel !== "Airbnb" ? "block" : "none"
					}}
				>
					<Card className=" shadow border-0 mb-2">
						<CardHeader className="bg-white border-bottom">
							<strong>{selectedChannel}</strong>
						</CardHeader>
						<CardBody>
							{canEditChannelName &&
								<Row>
									<Col className="col-6">
										<FormGroup row>
											<Label for="user" sm={4}>
												<FormattedMessage id="ChannelList.ChannelName" />
											</Label>
											<Col sm={8}>
												<InputGroup size="sm">
													<FormattedMessage id="ChannelList.ChannelName">
														{placeholder =>
															<Input
																name="name"
																type="text"
																placeholder={placeholder}
																disabled={!canEditChannelName}
																onChange={this.changeState.bind(this)}
																maxLength="20"
																readOnly={ confirm }
															/>
														}
													</FormattedMessage>
												</InputGroup>
											</Col>
										</FormGroup>
									</Col>
								</Row>
							}

							<Row>
								{this.state.mandatoryFields.find(
									f => f === "User"
								) && (
									<Col className="col-6">
										<FormGroup row>
											<Label for="user" sm={4}>
												<FormattedMessage id="ChannelList.User" />
											</Label>
											<Col sm={8}>
												<InputGroup size="sm">
													<FormattedMessage id="ChannelList.User">
														{placeholder => (
															<Input
																name="user"
																value={
																	this.state.channelInstance.user ? this.state.channelInstance.user : ''
																}
																onChange={this.changeState.bind(
																	this
																)}
																placeholder={
																	placeholder
																}
																required={
																	!confirm
																}
																readOnly={
																	confirm
																}
															/>
														)}
													</FormattedMessage>
												</InputGroup>
											</Col>
										</FormGroup>
									</Col>
								)}
								{this.state.mandatoryFields.find(
									f => f === "Password"
								) && (
									<Col className="col-6">
										<FormGroup row>
											<Label for="password" sm={4}>
												<FormattedMessage id="ChannelList.Password" />
											</Label>
											<Col sm={8}>
												<InputGroup size="sm">
													<FormattedMessage id="ChannelList.Password">
														{placeholder => (
															<Input
																type="password"
																name="password"
																value={
																	this.state.channelInstance.password ? this.state.channelInstance.password : ''
																}
																onChange={this.changeState.bind(
																	this
																)}
																placeholder={
																	placeholder
																}
																required={
																	!confirm
																}
																readOnly={
																	confirm
																}
															/>
														)}
													</FormattedMessage>
												</InputGroup>
											</Col>
										</FormGroup>
									</Col>
								)}
							</Row>
							<Row>
								{this.state.mandatoryFields.find(
									f => f === "PropertyCode"
								) && (
									<Col className="col-6">
										<FormGroup row>
											<Label for="user" sm={4}>
												<FormattedMessage id="ChannelList.propertyCode" />
											</Label>
											<Col sm={8}>
												<InputGroup size="sm">
													<FormattedMessage id="ChannelList.propertyCode">
														{placeholder => (
															<Input
																name="propertyCode"
																value={
																	this.state.channelInstance.propertyCode ? this.state.channelInstance.propertyCode : ''
																}
																onChange={this.changeState.bind(
																	this
																)}
																placeholder={
																	placeholder
																}
																required={
																	!confirm
																}
																readOnly={
																	confirm
																}
															/>
														)}
													</FormattedMessage>
												</InputGroup>
											</Col>
										</FormGroup>
									</Col>
								)}

								{this.state.mandatoryFields.find(
									f => f === "MaxInventoryDays"
								) && (
									<Col className="col-6">
										<FormGroup row>
											<Label
												for="maxInventoryDays"
												sm={4}
											>
												<FormattedMessage id="ChannelList.maxInventoryDays" />
											</Label>
											<Col sm={8}>
												<InputGroup size="sm">
													<FormattedMessage id="ChannelList.maxInventoryDays">
														{placeholder => (
															<Input
																name="maxInventoryDays"
																type="number"
																min="125"
																max={this.props.selectedChannel === "BOOKING" ? "730" : "720"}
																value={
																	this.state.channelInstance.maxInventoryDays ? this.state.channelInstance.maxInventoryDays: ''
																}
																onChange={this.changeState.bind(
																	this
																)}
																placeholder={
																	placeholder
																}
																required={
																	!confirm
																}
																readOnly={
																	confirm
																}
															/>
														)}
													</FormattedMessage>
												</InputGroup>
											</Col>
										</FormGroup>
									</Col>
								)}
							</Row>
							<Row>
								{this.state.mandatoryFields.find(
									f => f === "SecondaryUser"
								) && (
									<Col className="col-6">
										<FormGroup row>
											<Label for="user" sm={4}>
												<FormattedMessage id="ChannelConfigState.SecondaryUser" />
											</Label>
											<Col sm={8}>
												<InputGroup size="sm">
													<FormattedMessage id="ChannelConfigState.SecondaryUser">
														{placeholder => (
															<Input
																name="secondaryUser"
																value={
																	this.state.channelInstance.secondaryUser ? this.state.channelInstance.secondaryUser : ''
																}
																onChange={this.changeState.bind(
																	this
																)}
																placeholder={
																	placeholder
																}
																required={
																	!confirm
																}
																readOnly={
																	confirm
																}
															/>
														)}
													</FormattedMessage>
												</InputGroup>
											</Col>
										</FormGroup>
									</Col>
								)}

								{this.state.mandatoryFields.find(
									f => f === "SecondaryPassword"
								) && (
									<Col className="col-6">
										<FormGroup row>
											<Label for="password" sm={4}>
												<FormattedMessage id="ChannelConfigState.SecondaryPassword" />
											</Label>
											<Col sm={8}>
												<InputGroup size="sm">
													<FormattedMessage id="ChannelConfigState.SecondaryPassword">
														{placeholder => (
															<Input
																type="password"
																name="secondaryPassword"
																value={
																	this.state.channelInstance.secondaryPassword ? this.state.channelInstance.secondaryPassword : ''
																}
																onChange={this.changeState.bind(
																	this
																)}
																placeholder={
																	placeholder
																}
																required={
																	!confirm
																}
																readOnly={
																	confirm
																}
															/>
														)}
													</FormattedMessage>
												</InputGroup>
											</Col>
										</FormGroup>
									</Col>
								)}
							</Row>
						</CardBody>
					</Card>

					<Card className=" shadow border-0 mb-2">
						<CardHeader className="bg-white border-bottom">
							<strong>
								<FormattedMessage id="ChannelList.InformationSentToChannel" />
							</strong>
							<div className="float-right">
								<a id="SentToChannelTooltip">
									<i className="fas fa-question-circle"></i>
								</a>

								<CustomToolTip
									placement="bottom"
									target="SentToChannelTooltip"
								>
									<Badge color="primary" className="p-1">
										<FormattedMessage id="ChannelList.Active" />
									</Badge>
									<br />
									<Badge color="secondary"><FormattedMessage id="ChannelList.Inactive" /></Badge>
								</CustomToolTip>
							</div>
						</CardHeader>
						<CardBody>
							<Row>
								<Col>
									{[
										"sendAvailability",
										"sendPrice",
										"sendCta",
										"sendCtd",
										"sendMaxStay",
										"sendMinStay",
										"sendStopSales",
										"sendMinAdvancedBookingOffset",
										"sendMaxAdvancedBookingOffset"
									].map((el, key) => (
										<Badge
											key={key} style={{ cursor: 'pointer' }}
											className="mr-2 p-2 mb-2"
											color={supported === null || (supported && !supported.includes(supportedOptions.find(s => s.name === el).tag)) ? "secondary" : this.state.channelInstance[el] ? "primary" : "secondary"}
										>
											<div
												id={el}
												name={el}
												onClick={() =>
													supported && supported.includes(supportedOptions.find(s => s.name === el).tag) ?
														this.changeSendInfoState({
															target: { name: el }
														})
													:''
												}
											>
												<FormattedMessage
													id={
														"ChannelList." +
														el
															.charAt(0)
															.toUpperCase() +
														el.slice(1)
													}
												/>
											</div>
										</Badge>
									))}
								</Col>
							</Row>
						</CardBody>
					</Card>
				</div>
			</>
		);
	}
}
