import React, { Component } from 'react';
import { Col, FormGroup, Input, Label } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { PaymentGatewayMultiLang } from '../Base/Common/SupportedMultiLang';

export class RenderBankTransferForm extends Component {

    render() {
        const { paymentGateway, handleData, idx, onlyHasBankTransfer, handleEditorStateChange } = this.props;

        return (
            <div>
                <FormGroup row>
                    <Label sm={3}>
                        <FormattedMessage id="PaymentGateway.AccountName" />
                    </Label>
                    <Col sm={9}>
                        <Input type="text" maxLength="100" name="accountName" id="accountName" value={paymentGateway.accountName ? paymentGateway.accountName : ''} onChange={evt => handleData(evt, idx)} required />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label sm={3}>
                        <FormattedMessage id="PaymentGateway.Iban" />
                    </Label>
                    <Col sm={9}>
                        <Input type="text" maxLength="50" name="iban" id="iban" value={paymentGateway.iban ? paymentGateway.iban : ''} onChange={evt => handleData(evt, idx)} required />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label sm={3}>
                        <FormattedMessage id="PaymentGateway.Swift" />
                    </Label>
                    <Col sm={9}>
                        <Input type="text" maxLength="50" name="swift" id="swift" value={paymentGateway.swift ? paymentGateway.swift : ''} onChange={evt => handleData(evt, idx)} required />
                    </Col>
                </FormGroup>
                {!onlyHasBankTransfer ?
                    <FormGroup row>
                        <Label sm={3}>
                            <FormattedMessage id="PaymentGateway.BankTransferValidaty" />
                        </Label>
                        <Col sm={9}>
                            <Input type="number" min="1" name="mbValidatyDays" id="mbValidatyDays" value={paymentGateway.mbValidatyDays ? paymentGateway.mbValidatyDays : ''} onChange={evt => handleData(evt, idx)} />
                        </Col>
                    </FormGroup>
                    : ''}
                <FormGroup row>
                    <Col>
                        <PaymentGatewayMultiLang
                            data={{
                                texts: paymentGateway.paymentGatewayMultilanguals,
                                handleChange: handleData,
                                handleEditorStateChange: handleEditorStateChange,
                                idx: idx
                            }}
                            noButton={true}
                        />
                    </Col>
                </FormGroup>
            </div>
        );
    }
}