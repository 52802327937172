import React, { Component } from 'react';
import {  Modal, ModalBody, ModalHeader } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { getPaymentGatewayType, getPaymentModes, getPaymentStatusOptions, getPaymentTypes } from '../Base/Common/ReferenceDataFunctions';
import { postAPI, putAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';
import { getGatewayErrors } from '../Admin/Payments/GatewayErrors';
import Details from './Details';
import DetailsMobile from './DetailsMobile';

export class PaymentDetails extends Component {

    constructor(props) {
        super(props);
        this.togglePaymentRefundModal = this.togglePaymentRefundModal.bind(this);
        this.togglePaymentCancelModal = this.togglePaymentCancelModal.bind(this);
        this.toggleCreatePaymentModal = this.toggleCreatePaymentModal.bind(this);
        const isPaybyrd = this.props.payment.paymentGateway === getPaymentGatewayType()[3].value;
        const isRedsys = this.props.payment.paymentGateway === getPaymentGatewayType()[6].value;
        const isSibs = this.props.payment.paymentGateway === getPaymentGatewayType()[7].value;
        const isCreditCard = this.props.payment.mode === getPaymentModes()[0].value;
        const isSuccess = this.props.payment.paymentStatus === getPaymentStatusOptions()[3].value;

        this.state = {
            block: false,
            paymentRefundModal: false,
            paymentCancelModal: false,
            createPaymentModal: false,
            expandableEmail: false,
            expandableIntentId: false,
            expandableReciptUrl: false,
            expandableSessionId: false,
            expandableNotes: false,
            isBankTransferOrCustom: this.props.payment.paymentGateway === getPaymentGatewayType()[1].value || this.props.payment.paymentGateway === getPaymentGatewayType()[4].value,
            isHeyCard: this.props.payment.paymentGateway === getPaymentGatewayType()[8].value,
            isStripe: this.props.payment.paymentGateway === getPaymentGatewayType()[0].value,
            isIfThenPay: this.props.payment.paymentGateway === getPaymentGatewayType()[5].value,
            isRedsys: isRedsys,
            isCreditCard: isCreditCard,
            isMultibanco: this.props.payment.mode === getPaymentModes()[3].value,
            logo: getPaymentGatewayType().filter(p => p.value === this.props.payment.paymentGateway).map((p, key) => p.showLogoHasText ? <h6 key={key} style={{fontWeight: 'bold', fontSize: '18px'}}>{p.logo}</h6> : <img key={key} height="30" src={p.logo} />),
            paymentType: getPaymentTypes().find(opt => opt.value === this.props.payment.type),
            canPerformRecurringPayment: isCreditCard && isSuccess && (isPaybyrd || isRedsys || isSibs)
        };
    }

    togglePaymentRefundModal() {
        this.setState(prevState => ({ paymentRefundModal: !prevState.paymentRefundModal }));
    }

    togglePaymentCancelModal() {
        this.setState(prevState => ({ paymentCancelModal: !prevState.paymentCancelModal }));
    }

    toggleCreatePaymentModal() {
        this.setState(prevState => ({ createPaymentModal: !prevState.createPaymentModal }));
    }

    toggleExpandable = (name) => {
        this.setState(prevState => ({ [name]: !prevState[name] }));
    }

    checkPayment = () => {
        const { payment, updatePayment } = this.props;
        this.setState({ block: true });

        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else  {
                    handleNotification(data, <FormattedMessage id="Payments.CheckPaymentSuccess" />, <FormattedMessage id="General.Success" />);
                }
                if (data.response && data.response.length > 0) {
                    updatePayment(data.response[0]);
                }
            }
            this.setState(({ error: errorMessage, block: false }));
        }, `/api/Price/Payment/v1/checkPayment/${payment.id}`);
    }

    getErrorMessage = (payment) => {
        if (payment.paymentErrorCode && payment.paymentErrorCode !== "UnknownReason") {
            return <FormattedMessage id={`Payments.${payment.paymentErrorCode}`} />
        }
        else {
            const gatewayError = getGatewayErrors()[payment.gatewayErrorCode];

            return gatewayError ? gatewayError : payment.gatewayErrorCode
        }
    }

    isCancelBtnDisabled = (payment) => {
        if (payment.paymentStatus === 'requires_payment_method' || payment.paymentStatus === 'requires_capture' ||
            payment.paymentStatus === 'requires_confirmation' || payment.paymentStatus === 'requires_action' || payment.paymentStatus === 'processing') {
            return false;
        }
        else {
            return true;
        }
    }

    confirmPayment = () => {
        const { payment, updatePayment } = this.props;

        this.setState({ block: true });
        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="Payments.SuccessfulConfirm" />, <FormattedMessage id="General.Success" />);
                    updatePayment(data.response[0]);
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/Price/Payment/v1/confirm/${payment.id}`);
    }

    render() {
        const { isOpen, toggle, payment, updatePayment, updateRefunds, addPayment, refundsTotalAmount } = this.props;
        const { block, error, paymentRefundModal, paymentCancelModal, createPaymentModal, isBankTransferOrCustom, isStripe, isIfThenPay, isRedsys, isHeyCard,
            isCreditCard, isMultibanco, logo, paymentType, canPerformRecurringPayment, expandableEmail, expandableIntentId, expandableReciptUrl, expandableSessionId, expandableNotes } = this.state;

        const paymentStatusOptions = getPaymentStatusOptions();

        return (
            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalHeader toggle={toggle}>
                    <FormattedMessage id="PaymentDetails.Title" values={{ type: paymentType ? paymentType.label : paymentType }} />
                </ModalHeader>
                <ModalBody style={{ maxHeight: global.isMobile ? '80vh' : '', overflow: global.isMobile ? 'auto' : '' }}>
                    {!global.isMobile ?
                        <Details 
                            payment={payment}
                            updatePayment={updatePayment}
                            updateRefunds={updateRefunds}
                            addPayment={addPayment}
                            refundsTotalAmount={refundsTotalAmount}
                            isHeyCard={isHeyCard}
                            block={block}
                            error={error}
                            paymentRefundModal={paymentRefundModal}
                            paymentCancelModal={paymentCancelModal}
                            createPaymentModal={createPaymentModal}
                            isBankTransferOrCustom={isBankTransferOrCustom}
                            isStripe={isStripe}
                            isIfThenPay={isIfThenPay}
                            isRedsys={isRedsys}
                            isCreditCard={isCreditCard}
                            isMultibanco={isMultibanco}
                            logo={logo}
                            canPerformRecurringPayment={canPerformRecurringPayment}
                            paymentStatusOptions={paymentStatusOptions}
                            togglePaymentRefundModal={this.togglePaymentRefundModal}
                            togglePaymentCancelModal={this.togglePaymentCancelModal}
                            toggleCreatePaymentModal={this.toggleCreatePaymentModal}
                            confirmPayment={this.confirmPayment}
                            checkPayment={this.checkPayment}
                            getErrorMessage={this.getErrorMessage}
                            isCancelBtnDisabled={this.isCancelBtnDisabled}
                            
                        />
                    :
                        <DetailsMobile
                            payment={payment}
                            updatePayment={updatePayment}
                            updateRefunds={updateRefunds}
                            addPayment={addPayment}
                            refundsTotalAmount={refundsTotalAmount}
                            isHeyCard={isHeyCard}
                            block={block}
                            error={error}
                            paymentRefundModal={paymentRefundModal}
                            paymentCancelModal={paymentCancelModal}
                            createPaymentModal={createPaymentModal}
                            isBankTransferOrCustom={isBankTransferOrCustom}
                            isStripe={isStripe}
                            isIfThenPay={isIfThenPay}
                            isRedsys={isRedsys}
                            isCreditCard={isCreditCard}
                            isMultibanco={isMultibanco}
                            logo={logo}
                            canPerformRecurringPayment={canPerformRecurringPayment}
                            paymentStatusOptions={paymentStatusOptions}
                            expandableEmail={expandableEmail}
                            expandableIntentId={expandableIntentId}
                            expandableReciptUrl={expandableReciptUrl}
                            expandableSessionId={expandableSessionId}
                            expandableNotes={expandableNotes}
                            togglePaymentRefundModal={this.togglePaymentRefundModal}
                            togglePaymentCancelModal={this.togglePaymentCancelModal}
                            toggleCreatePaymentModal={this.toggleCreatePaymentModal}
                            confirmPayment={this.confirmPayment}
                            checkPayment={this.checkPayment}
                            getErrorMessage={this.getErrorMessage}
                            isCancelBtnDisabled={this.isCancelBtnDisabled}
                            toggleExpandable={this.toggleExpandable}
                        />
                    }
                </ModalBody>
            </Modal>    
        );
    }
}