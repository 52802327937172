import { FormattedMessage, injectIntl } from 'react-intl';
import React, { Component } from 'react';
import logoAirbnb from '../../../img/Airbnb.svg';
import logoAbreu from '../../../img/Abreu.png';
import logobooking from '../../../img/booking.svg';
import logoedge from '../../../img/d-edge.ico';
import logoexpedia from '../../../img/expedia.png';
import logoLastMinute from '../../../img/lastminute.png';
import logoLogitravel from '../../../img/logitravel.jfif';
import logoInterrias from '../../../img/Interrias.png';
import logoLusanova from '../../../img/lusanova.jfif';
import logoMArsol from '../../../img/MARSOL.png';
import logoMrMsSmith from '../../../img/MrMsSmith.jpg';
import logoSabre from '../../../img/sabre.ico';
import logoYoursHotels from '../../../img/YourHotels.png';
import logoHostelWorld from '../../../img/hostelworld.png';
import logoRoiback from '../../../img/roiback.jpg';
import logoHotusa from '../../../img/hotusa.jpg';
import logoKeytel from '../../../img/Keytel.ico';
import logoBeds4Travel from '../../../img/Beds4Travel.png';
import logoSapo from '../../../img/sapo.png';
import logoHsystem from '../../../img/hsystem.png';
import logoHotelBeds from '../../../img/hotelbeds.png';
import logoAgoda from '../../../img/agoda.ico';
import logoEdreams from '../../../img/edreams.ico';
import logoPrestigia from '../../../img/Prestigia.png';
import logoHostelsclub from '../../../img/hostelsclub.jfif';
import logoSplendia from '../../../img/splendia.png';
import logoBookAssist from '../../../img/bookassist.png';
import logoMirai from '../../../img/mirai.png';
import logoPlumGuide from '../../../img/PlumGuide.ico';
import logoMakeMyTrip from '../../../img/MakeMyTripActive.png';
import logoDnata from '../../../img/dnata.png';
import logoHomeAway from '../../../img/homeaway.ico';
import logoSiteminder from '../../../img/siteminder.png';
import logoYieldplanet from '../../../img/yieldplanet.png';
import logoGuestcentric from '../../../img/guestcentric.ico';
import logoClick2Portugal from '../../../img/click2portugal.png';
import logoEgds from '../../../img/e-GDS.png';
import logoOmnibees from '../../../img/omnibees.png';
import logoRateGain from '../../../img/RateGain.png';
import logoTravelclick from '../../../img/travelclick.png'
import logoCubilis from '../../../img/cubilis.png'
import logoB24 from '../../../img/beds24.png'
import logoRoomCloud from '../../../img/roomcloud.png'
import logoSideTours from '../../../img/sidetours.jpg'
import logoProfitRoom from '../../../img/profitroom.ico'
import logoMisterbeb from '../../../img/misterb&b.png'
import logoTripadvisor from '../../../img/tripadvisor.ico'
import logoJumboTours from '../../../img/jumboTours.png'
import logoQuotelier from '../../../img/Quotelier.jpg'
import logoHost from '../../../img/Google.ico'
import logoJuniper from '../../../img/juniper.png'
import heyTravel from '../../../img/heyCM.jpg'
import logoBookiply from '../../../img/bookiply.png'
import CTrip from '../../../img/trip.ico'
import ParatyTech from '../../../img/ParatyTech.ico'
import PortugalGetaways from '../../../img/PortugalGetaways.ico'
import Cultuzz from '../../../img/Cultuzz.ico'
import logoHeyBE from '../../../img/HeyBookingEng01.png'
import Fastpay from '../../../img/fastpay.png'
import LoveHolidaysLogo from '../../../img/LoveHolidays.png'
import Tour10Logo from '../../../img/Tour10Logo.png'
import logoViajesOlympia from '../../../img/ViajesOlympia.jpg'
import SolferiasLogo from '../../../img/SolferiasLogo.jpg'
import logoPortimar from '../../../img/logoPortimar.png'
import logoTopAtlantico from '../../../img/TopAtlantico.jpg'
import logoEcTravel from '../../../img/EcTravel.jpg'
import logoOnTravelSolutions from '../../../img/OnTravelSolutions.ico'
import logoRoibos from '../../../img/Roibos.png'
import logoTabletHotels from '../../../img/TabletHotels.ico'
import logoMTSGlobe from '../../../img/mtsGlobe.png'
import logoGoogle from '../../../img/Google.ico'
import logoRateTiger from '../../../img/RateTiger.png'
import logoOlympia from '../../../img/Olympia.png'
import logoSynergy from '../../../img/Synergy.png'
import logoHotelNetSolutions from '../../../img/HotelNetSolutions.png'
import logoHomelike from '../../../img/homelike.png'
import HyperGuest from '../../../img/HyperGuest.png'
import logoHotelRez from '../../../img/logoHotelRez.png'
import logoViajesElCorteIngles from '../../../img/logoViajesElCorteIngles.png'
import logoAtrapalo from '../../../img/atrapalo.ico'
import logoICastelli from '../../../img/ICastelli.png'
import logoWeekendesk from '../../../img/Weekendesk.jpg'
import logoHotelTonight from '../../../img/HotelTonight.png'
import logoBritishAirways from '../../../img/BritishAirways.png'
import logoHrs from '../../../img/hrs.png'
import logoGetARoom from '../../../img/getaroom.png'
import logoWorld2Meet from '../../../img/world2meet.ico'
import logoDoubleland from '../../../img/doubleland.png'
import logoCnTravel from '../../../img/logoCnTravel.jpg'
import logoCvc from '../../../img/logoCvc.png'
import logoVerticalBooking from '../../../img/verticalBooking.ico'
import logoB2bReservas from '../../../img/b2bReservas.ico'
import logoVeturis from '../../../img/logoVeturis.png'
import logoShrGroup from '../../../img/logoShrGroup.png'
import logoTravTravel from '../../../img/logoTravTravel.png'
import logoWellBeds from '../../../img/WellBeds.png'

import stripeLogo from '../../../img/Stripelogo.svg'
import reduniqLogo from '../../../img/reduniqLogo.jfif'
import paybyrdLogo from '../../../img/paybyrdLogo.png'
import mbWayLogo from '../../../img/mbWayLogo.png'
import mbLogo from '../../../img/mbLogo.png'
import ifThenPayLogo from '../../../img/IfThenPayLogo.png'
import redsysLogo from '../../../img/redsysLogo.png'
import sibsLogo from '../../../img/sibsLogo.ico'
import heyCardLogo from '../../../img/HeyCard.png'
import logoHotelSPider from '../../../img/hotelspider.png'
import logoAvoris from '../../../img/avoris.ico'
import WowDigital from '../../../img/WowDigital.ico'
import logoDingus from '../../../img/dingus.ico'
import logoIEscape from '../../../img/logoIEscape.jpg'
import guestIncomingLogo from '../../../img/guestIncomingIcon.png'
import logoJet2Holidays from '../../../img/logoJet2Holidays.ico'
import logoTopWorldHotel from '../../../img/logoTopWorldHotel.png'

import moment from "moment";
import 'moment-timezone';
import { handleNotification } from '../Notification';


const channel =
    [
        {
            "code": "agoda",
            "image": logoAgoda,
            "iconSize": "20px",
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'checkInventory', 'Delete' ],
            "Features": [],
            "PushType": 'all'
        },
        {
            "code": "airbnb",
            "image": logoAirbnb,
            "iconSize": "20px",
            "Actions": ['TestConnection', 'unlinkConnection', 'importListings', 'sendPrice', 'Enable', 'Delete', 'reservationHistory'],
            "Features": [],
            "PushType": 'all'
        },
        {
            "code": "booking",
            "image": logobooking,
            "iconSize": "20px",
            "Actions": ['TestConnection', 'sendPrice', 'importListings', 'checkInventory', 'Enable', 'Delete','reservationHistory'],
            "Features" : [],
            "PushType": 'Prices&Avail',
            "HasCommonData": false
        },
        {
            "code": "expedia",
            "image": logoexpedia,
            "iconSize": "20px",
            "PushType": 'all',
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'checkInventory', 'Enable', 'Delete', 'reservationHistory'],
            "Features": [],
            "HasCommonData": false
        },
        {
            "code": "hostelworld",
            "image": logoHostelWorld,
            "Actions": ['TestConnection', 'sendPrice', 'importListings', 'checkInventory', 'Enable', 'Delete' ],
            "Features": [],
            "PushType": '',
            "iconSize": "20px" 
        },
        {
            "code": "abreu",
            "image": logoAbreu,
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'checkInventory', 'Enable', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px" 
        },
        {
            "code": "lusanova",
            "image": logoLusanova,
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'checkInventory', 'Enable', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "emviagem",
            "image": logoYoursHotels,
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'checkInventory', 'Enable', 'Delete'],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "OLYMPIAEUROPE",
            "image": logoOlympia,
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'checkInventory', 'Enable', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "18px"
        },
        {
            "code": "AVAILPRO",
            "image": logoedge,
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'checkInventory', 'Enable', 'ChangePassword', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "synxis",
            "image": logoSabre,
            "Actions": ['importListings', 'sendPrice', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "sabre",
            "image": logoSabre,
            "Actions": ['importListings', 'sendPrice', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "SABREENTERPRISE",
            "image": logoSabre,
            "Actions": ['importListings', 'sendPrice', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "HOTELREZ",
            "image": logoHotelRez,
            "Actions": ['importListings', 'sendPrice', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "MRMRSSMITH",
            "image": logoMrMsSmith,
            "Actions": ['TestConnection', 'sendPrice', 'importListings', 'checkInventory', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "Marsol",
            "image": logoMArsol,
            "Actions": ['TestConnection', 'sendPrice', 'importListings', 'checkInventory', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "logitravel",
            "image": logoLogitravel,
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'checkInventory', 'Enable', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "interrias",
            "image": logoInterrias,
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'checkInventory', 'Enable', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "ViajesOlympia",
            "image": logoViajesOlympia,
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'checkInventory', 'Enable', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "LoveHolidays",
            "image": LoveHolidaysLogo,
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'checkInventory', 'Enable', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "16px"
        },
        {
            "code": "Tour10",
            "image": Tour10Logo,
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'checkInventory', 'Enable', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "SOLFERIAS",
            "image": SolferiasLogo,
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'checkInventory', 'Enable', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {   
            "code": "LastMinute",
            "image": logoLastMinute,
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'checkInventory', 'Enable', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "Roiback",
            "image": logoRoiback,
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'checkInventory', 'reservationHistory', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "hotusa",
            "image": logoHotusa,
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'checkInventory', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "keytel",
            "image": logoKeytel,
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'checkInventory', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "BEDS4TRAVEL",
            "image": logoBeds4Travel,
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'checkInventory', 'reservationHistory', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "Sapo",
            "image": logoSapo,
            "Actions": ['sendPrice', 'Enable', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "HeyBE",
            "image": logoHeyBE,
            "Actions": ['sendPrice', 'Enable', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "DoubleLand",
            "image": logoDoubleland,
            "Actions": ['sendPrice', 'checkInventory', 'Enable', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "Hsystem",
            "image": logoHsystem,
            "Actions": ['TestConnection', 'importListings', 'checkInventory', 'sendPrice', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "HOTELBEDS",
            "image": logoHotelBeds,
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "18px"
        },
        {
            "code": "Edreams",
            "image": logoEdreams,
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "Prestigia",
            "image": logoPrestigia,
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "Hostelsclub",
            "image": logoHostelsclub,
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "splendia",
            "image": logoSplendia,
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'checkInventory', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "18px"
        },
        {
            "code": "BOOKASSIST",
            "image": logoBookAssist,
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'checkInventory', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "MIRAI",
            "image": logoMirai,
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'checkInventory', 'reservationHistory', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "16px",
            "HasCommonData": false
        },
        {
            "code": "PLUM",
            "image": logoPlumGuide,
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'checkInventory', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px",
            "HasCommonData": false
        },
        {
            "code": "TravelRepublic",
            "image": logoDnata,
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'checkInventory', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px",
            "HasCommonData": false
        },
        {
            "code": "MakeMyTrip",
            "image": logoMakeMyTrip,
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px",
            "HasCommonData": false
        },
        {
            "code": "HomeAway",
            "image": logoHomeAway,
            "Actions": ['checkInventory', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px",
            "HasCommonData": false
        },
        {
            "code": "Siteminder",
            "image": logoSiteminder,
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'checkInventory', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px",
            "HasCommonData": false
        },
        {
            "code": "Yieldplanet",
            "image": logoYieldplanet,
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'checkInventory', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px",
            "HasCommonData": false
        },
        {
            "code": "Guestcentric",
            "image": logoGuestcentric,
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px",
            "HasCommonData": false
        },
        {
            "code": "Click2Portugal",
            "image": logoClick2Portugal,
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px",
            "HasCommonData": false
        },
        {
            "code": "EGDS",
            "image": logoEgds,
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'checkInventory', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px",
            "HasCommonData": false
        },
        {
            "code": "OMNIBEES",
            "image": logoOmnibees,
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'checkInventory', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px",
            "HasCommonData": false
        },
        {
            "code": "RATEGAIN",
            "image": logoRateGain,
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'checkInventory', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px",
            "HasCommonData": false
        },
        {
            "code": "TRAVELCLICK",
            "image": logoTravelclick,
            "Actions": ['sendPrice', 'importListings', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px",
            "HasCommonData": false
        },
        {
            "code": "CUBILIS",
            "image": logoCubilis,
            "Actions": ['TestConnection', 'sendPrice', 'importListings', 'checkInventory', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px",
            "HasCommonData": false
        },
        {
            "code": "BEDS24",
            "image": logoB24,
            "Actions": ['TestConnection', 'sendPrice', 'importListings', 'checkInventory', 'reservationHistory', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "12px",
            "HasCommonData": false
        },
        {
            "code": "ROOMCLOUD",
            "image": logoRoomCloud,
            "Actions": ['TestConnection', 'sendPrice', 'importListings', 'checkInventory', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "16px",
            "HasCommonData": false
        },
        {
            "code": "SIDETOURS",
            "image": logoSideTours,
            "Actions": ['TestConnection', 'sendPrice', 'importListings', 'checkInventory', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px",
            "HasCommonData": false
        },
        {
            "code": "PROFITROOM",
            "image": logoProfitRoom,
            "Actions": ['TestConnection', 'sendPrice', 'importListings', 'checkInventory', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px",
            "HasCommonData": false
        },
        {
            "code": "MisterBnB",
            "image": logoMisterbeb,
            "Actions": [],
            "Features": [],
            "PushType": '',
            "iconSize": "20px",
            "HasCommonData": false
        },
        {
            "code": "TRIPADVISOR",
            "image": logoTripadvisor,
            "Actions": [],
            "Features": [],
            "PushType": '',
            "iconSize": "20px",
            "HasCommonData": false
        },
        {
            "code": "JUMBOTOURS",
            "image": logoJumboTours,
            "Actions": ['TestConnection', 'sendPrice', 'importListings', 'checkInventory', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px",
            "HasCommonData": false
        },
        {
            "code": "quotelier",
            "image": logoQuotelier,
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'checkInventory', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "Host",
            "image": logoHost,
            "Actions": ['importListings', 'sendPrice'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "HEYTRAVEL",
            "image": heyTravel,
            "Actions": [],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "HEYCHANNELGENERIC",
            "image": '',
            "Actions": [],
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'checkInventory', 'reservationHistory', 'Enable', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "Bookiply",
            "image": logoBookiply,
            "Actions": ['checkInventory', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px",
            "HasCommonData": false
        },
        {
            "code": "C-TRIP",
            "image": CTrip,
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'Enable', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "ParatyTech",
            "image": ParatyTech,
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'checkInventory', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "PortugalGetaways",
            "image": PortugalGetaways,
            "Actions": ['TestConnection', 'sendPrice', 'importListings', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "18px"
        },
        {
            "code": "Cultuzz",
            "image": Cultuzz,
            "Actions": ['TestConnection', 'sendPrice', 'importListings', 'reservationHistory', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "FASTPAYHOTELS",
            "image": Fastpay,
            "Actions": ['TestConnection', 'sendPrice', 'importListings', 'checkInventory', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "MTSGLOBE",
            "image": logoMTSGlobe,
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'checkInventory', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "18px"
        },
        {
            "code": "HOTELNETSOLUTIONS",
            "image": logoHotelNetSolutions,
            "Actions": ['TestConnection', 'sendPrice', 'importListings', 'checkInventory', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "Ratetiger",
            "image": logoRateTiger,
            "Actions": ['importListings', 'sendPrice', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "JUNIPER",
            "image": logoJuniper,
            "Actions": ['TestConnection', 'sendPrice', 'importListings', 'checkInventory', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "PORTIMAR",
            "image": logoPortimar,
            "Actions": ['TestConnection', 'sendPrice', 'importListings', 'checkInventory', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "TOPATLANTICO",
            "image": logoTopAtlantico,
            "Actions": ['TestConnection', 'sendPrice', 'importListings', 'checkInventory', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "ROIBOS",
            "image": logoRoibos,
            "Actions": ['TestConnection', 'sendPrice', 'importListings', 'checkInventory', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "ONTRAVELSOLUTIONS",
            "image": logoOnTravelSolutions,
            "Actions": ['TestConnection', 'sendPrice', 'importListings', 'checkInventory', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "ECTRAVEL",
            "image": logoEcTravel,
            "Actions": ['TestConnection', 'sendPrice', 'importListings', 'checkInventory', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "TABLETHOTELS",
            "image": logoTabletHotels,
            "Actions": ['TestConnection', 'sendPrice', 'importListings', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "16px"
        },
        {
            "code": "Hyperguest",
            "image": HyperGuest,
            "Actions": ['TestConnection', 'sendPrice', 'importListings', 'checkInventory', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "Synergy",
            "image": logoSynergy,
            "Actions": ['TestConnection', 'sendPrice', 'importListings', 'checkInventory', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "GoogleHotelAds",
            "image": logoGoogle,
            "Actions": ['importListings', 'sendPrice', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "18px"
        },
        {
            "code": "HOMELIKE",
            "image": logoHomelike,
            "Actions": ['TestConnection', 'sendPrice', 'importListings', 'reservationHistory', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "18px"
        },
        {
            "code": "VIAJESELCORTEINGLES",
            "image": logoViajesElCorteIngles,
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'checkInventory', 'Enable', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "ATRAPALO",
            "image": logoAtrapalo,
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'checkInventory', 'Enable', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "ICASTELLI",
            "image": logoICastelli,
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'Enable', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "WEEKENDESK",
            "image": logoWeekendesk,
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'Enable', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "HOTELTONIGHT",
            "image": logoHotelTonight,
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'Enable', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        }, 
        {
            "code": "BRITISHAIRWAYS",
            "image": logoBritishAirways,
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'checkInventory', 'Enable', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "16px"
        },
        {
            "code": "HRS",
            "image": logoHrs,
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'checkInventory', 'Enable', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "GETAROOM",
            "image": logoGetARoom,
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'Enable', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "World2Meet",
            "image": logoWorld2Meet,
            "Actions": ['TestConnection', 'sendPrice', 'importListings', 'checkInventory', 'Enable', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "HOTELSPIDER",
            "image": logoHotelSPider,
            "Actions": ['TestConnection', 'sendPrice', 'importListings', 'Enable', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "AVORIS",
            "image": logoAvoris,
            "Actions": ['TestConnection', 'sendPrice', 'importListings', 'checkInventory', 'reservationHistory', 'Enable', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "CNTRAVEL",
            "image": logoCnTravel,
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'checkInventory', 'Enable', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "CVC",
            "image": logoCvc,
            "Actions": ['TestConnection', 'sendPrice', 'importListings', 'checkInventory', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "WOWDIGITAL",
            "image": WowDigital,
            "Actions": ['TestConnection', 'sendPrice', 'importListings', 'checkInventory', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "DINGUS",
            "image": logoDingus,
            "Actions": ['TestConnection', 'sendPrice', 'importListings', 'checkInventory', 'reservationHistory', 'Enable', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "IESCAPE",
            "image": logoIEscape,
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'checkInventory', 'Enable', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "GUESTINCOMING",
            "image": guestIncomingLogo,
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'checkInventory', 'Enable', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "VERTICALBOOKING",
            "image": logoVerticalBooking,
            "Actions": ['TestConnection', 'sendPrice', 'importListings', 'checkInventory', 'reservationHistory', 'Enable', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "B2BRESERVAS",
            "image": logoB2bReservas,
            "Actions": ['sendPrice', 'importListings', 'Enable', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "VETURIS",
            "image": logoVeturis,
            "Actions": ['TestConnection', 'sendPrice', 'importListings', 'checkInventory', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "SHRGROUP",
            "image": logoShrGroup,
            "Actions": ['sendPrice', 'importListings', 'Enable', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "JET2HOLIDAYS",
            "image": logoJet2Holidays,
            "Actions": ['TestConnection', 'sendPrice', 'importListings', 'checkInventory', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "TRAVTRAVEL",
            "image": logoTravTravel,
            "Actions": ['TestConnection', 'sendPrice', 'importListings', 'checkInventory', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "TOPWORLDHOTEL",
            "image": logoTopWorldHotel,
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'checkInventory', 'Enable', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
        {
            "code": "WELLBEDS",
            "image": logoWellBeds,
            "Actions": ['TestConnection', 'importListings', 'sendPrice', 'checkInventory', 'Enable', 'Delete'],
            "Features": [],
            "PushType": '',
            "iconSize": "20px"
        },
    ];

const Status = [
    { value: 'enabled', label: 'Enabled' },
    { value: 'disabled', label: 'Disabled' }
];

const HelpLink = [
    {
        id: 'ArrivalDepartureHelp',
        linkPT:'https://hostpms.atlassian.net/wiki/spaces/CM/pages/277119029/Dashboard',
        linkEN: 'https://hostpms.atlassian.net/wiki/spaces/CME/pages/602865738/Dashboard'
    },
    {
        id: 'PaymentGatewaysHelp',
        linkPT:'https://hostpms.atlassian.net/wiki/spaces/CM/pages/2306342913/Gateways+de+Pagamento',
        linkEN: 'https://hostpms.atlassian.net/wiki/spaces/CM/pages/2306342913/Gateways+de+Pagamento'
    },
    {
        id: 'RevenueHelp',
        linkPT: 'https://hostpms.atlassian.net/wiki/spaces/CM/pages/277119029/Dashboard',
        linkEN: 'https://hostpms.atlassian.net/wiki/spaces/CME/pages/602865738/Dashboard'
    },
    {
        id: 'LastAlertsHelp',
        linkPT: 'https://hostpms.atlassian.net/wiki/spaces/CM/pages/277119029/Dashboard',
        linkEN: 'https://hostpms.atlassian.net/wiki/spaces/CME/pages/602865738/Dashboard'
    },
    {
        id: 'InventoryHelp',
        linkPT: 'https://hostpms.atlassian.net/wiki/spaces/CM/pages/276987967/Calend+rio',
        linkEN: 'https://hostpms.atlassian.net/wiki/spaces/CME/pages/602865755/Planning'
    },
    {
        id: 'BookingPromotionsHelp',
        linkPT: 'https://hostpms.atlassian.net/wiki/spaces/CM/pages/1767604245/Promo%2Bes%2BBooking',
        linkEN: 'https://hostpms.atlassian.net/wiki/spaces/CME/pages/1788215297/Promotions+Booking'
    },
    {
        id: 'ExpediaPromotionsHelp',
        linkPT: 'https://hostpms.atlassian.net/wiki/spaces/CM/pages/1773862950/Promo%2Bes%2BExpedia',
        linkEN: 'https://hostpms.atlassian.net/wiki/spaces/CME/pages/1788182533/Promotions+Expedia'
    },
    {
        id: 'ChanneldashoardHelp',
        linkPT: 'https://hostpms.atlassian.net/wiki/spaces/CM/pages/277020789/Lista+de+Canais',
        linkEN: 'https://hostpms.atlassian.net/wiki/spaces/CME/pages/602833088/Channels+List'
    },
    {
        id: 'logsdashboardchanneleventsinfo',
        linkPT: 'https://hostpms.atlassian.net/wiki/spaces/CM/pages/277741739/Conectividade',
        linkEN: 'https://hostpms.atlassian.net/wiki/spaces/CME/pages/610271246/Connectivity'
    },
    {
        id: 'logsdashboardinformation',
        linkPT: 'https://hostpms.atlassian.net/wiki/spaces/CM/pages/276627641/Dados+e+Mapeamentos',
        linkEN: 'https://hostpms.atlassian.net/wiki/spaces/CME/pages/610238476/Data+Mapping'
    },
    {
        id: 'reservationdashboardcheckinhelp',
        linkPT: 'https://hostpms.atlassian.net/wiki/spaces/CM/pages/277020829/Chegadas+e+Partidas',
        linkEN: 'https://hostpms.atlassian.net/wiki/spaces/CME/pages/610598982/Check-in+Check-out'
    },
    {
        id: 'reservationdashboardcheckouthelp',
        linkPT: 'https://hostpms.atlassian.net/wiki/spaces/CM/pages/277020829/Chegadas+e+Partidas',
        linkEN: 'https://hostpms.atlassian.net/wiki/spaces/CME/pages/610598982/Check-in+Check-out'
    },
    {
        id: 'ReservationList',
        linkPT: 'https://hostpms.atlassian.net/wiki/spaces/CM/pages/276725872/Relat+rio+de+Reservas',
        linkEN: 'https://hostpms.atlassian.net/wiki/spaces/CME/pages/610369728/Reservation+Report'
    },
    {
        id: 'maildashboardoverview',
        linkPT: 'https://hostpms.atlassian.net/wiki/spaces/CM/pages/277283022/Mensagens',
        linkEN: 'https://hostpms.atlassian.net/wiki/spaces/CME/pages/610238735/Messages'
    },
    {
        id: 'onlineratesdashboardhelp',
        linkPT: 'https://hostpms.atlassian.net/wiki/spaces/CM/pages/277741791/Reputa+o',
        linkEN: 'https://hostpms.atlassian.net/wiki/spaces/CME/pages/610238743/Reviews'
    },
    {
        id: 'opportunitiesdashboardhelp',
        linkPT: 'https://hostpms.atlassian.net/wiki/spaces/CM/pages/277708950/Oportunidades',
        linkEN: 'https://hostpms.atlassian.net/wiki/spaces/CME/pages/608370824/Opportunities'
    },
    {
        id: 'analyticsdashboardhelp',
        linkPT: 'https://hostpms.atlassian.net/wiki/spaces/CM/pages/276693199/Desempenho',
        linkEN: 'https://hostpms.atlassian.net/wiki/spaces/CME/pages/610369744/Revenue'
    },
    {
        id: 'manageruserhelp',
        linkPT: 'https://hostpms.atlassian.net/wiki/spaces/CM/pages/297533441/Utilizadores',
        linkEN: 'https://hostpms.atlassian.net/wiki/spaces/CME/pages/610271434/Users'
    },
    {
        id: 'PropertyScore',
        linkPT: 'https://hostpms.atlassian.net/wiki/spaces/CM/pages/948535297/Pontua+o+da+propriedade',
        linkEN: 'https://hostpms.atlassian.net/wiki/spaces/CME/pages/1675526157/Property+Score'
    },
    {
        id: 'RuleConfig',
        linkPT: 'https://hostpms.atlassian.net/wiki/spaces/CM/pages/413990930/Regras',
        linkEN: 'https://hostpms.atlassian.net/wiki/spaces/CME/pages/602931337/Rules'
    },
    {
        id: 'MailDashboard',
        linkPT: 'https://hostpms.atlassian.net/wiki/spaces/CM/pages/277283022/Mensagens',
        linkEN: 'https://hostpms.atlassian.net/wiki/spaces/CME/pages/610238735/Messages'
    },
    {
        id: 'BulkUpdateRestrictions',
        linkPT: 'https://hostpms.atlassian.net/wiki/spaces/CM/pages/2336325633/Atualiza+es+de+Restri+es',
        linkEN: 'https://hostpms.atlassian.net/wiki/spaces/CME/pages/2558165006/Update+Restrictions'
    },
    {
        id: 'RoomCategorySetup',
        linkPT: 'https://hostpms.atlassian.net/wiki/spaces/CM/pages/297369655/Quartos',
        linkEN: 'https://hostpms.atlassian.net/wiki/spaces/CME/pages/610500732/Rooms'
    },
    {
        id: 'PackageList',
        linkPT: 'https://hostpms.atlassian.net/wiki/spaces/CM/pages/297500673/Pacotes',
        linkEN: 'https://hostpms.atlassian.net/wiki/spaces/CME/pages/608403593/Packages'
    },
    {
        id: 'RoomRateConfig',
        linkPT: 'https://hostpms.atlassian.net/wiki/spaces/CM/pages/297435137/Rates',
        linkEN: 'https://hostpms.atlassian.net/wiki/spaces/CME/pages/610500741/Rates'
    },
    {
        id: 'ManualReservation',
        linkPT: 'https://hostpms.atlassian.net/wiki/spaces/CM/pages/1881735177/Nova+Reserva',
        linkEN: 'https://hostpms.atlassian.net/wiki/spaces/CME/pages/1907785739/New+Reservation'
    },
    //BE 
    {
        id: 'BEConfigDashboardHelp',
        linkPT: 'https://hostpms.atlassian.net/wiki/spaces/CMSup/pages/2096168983/Hey+BE+-+Configura+es+-+Validar+Publicar',
        linkEN: 'https://hostpms.atlassian.net/wiki/spaces/CMSup/pages/2096168983/Hey+BE+-+Configura+es+-+Validar+Publicar'
    },
    {
        id: 'EngagementHelp',
        linkPT: 'https://hostpms.atlassian.net/wiki/spaces/CMSup/pages/2093809679/Hey+BE+-+Estat+sticas+-+Intera+o',
        linkEN: 'https://hostpms.atlassian.net/wiki/spaces/CMSup/pages/2093809679/Hey+BE+-+Estat+sticas+-+Intera+o'
    }, 
    {
        id: 'CancelationPolicies',
        linkPT: 'https://hostpms.atlassian.net/wiki/spaces/CMSup/pages/2095480856/Hey+BE+-+Configura+es+-+Politicas+de+Cancelamento',
        linkEN: 'https://hostpms.atlassian.net/wiki/spaces/CMSup/pages/2095480856/Hey+BE+-+Configura+es+-+Politicas+de+Cancelamento'
    },
    {
        id: 'Offers',
        linkPT: 'https://hostpms.atlassian.net/wiki/spaces/CMSup/pages/2096070680/Hey+BE+-+Configura+es+-+Ofertas',
        linkEN: 'https://hostpms.atlassian.net/wiki/spaces/CMSup/pages/2096070680/Hey+BE+-+Configura+es+-+Ofertas'
    },
    {
        id: 'PromoCodes',
        linkPT: 'https://hostpms.atlassian.net/wiki/spaces/CMSup/pages/2095775765/Hey+BE+-+Configura+es+-+C+digos+Promocionais',
        linkEN: 'https://hostpms.atlassian.net/wiki/spaces/CMSup/pages/2095775765/Hey+BE+-+Configura+es+-+C+digos+Promocionais'
    }, 
    {
        id: 'BEPromotionsHelp',
        linkPT: 'https://hostpms.atlassian.net/wiki/spaces/CMSup/pages/2095448082/Hey+BE+-+Configura+es+-+Promo+es',
        linkEN: 'https://hostpms.atlassian.net/wiki/spaces/CMSup/pages/2095448082/Hey+BE+-+Configura+es+-+Promo+es'
    },  
    {
        id: 'RatesIntegrationsHelp',
        linkPT: 'https://hostpms.atlassian.net/wiki/spaces/CMSup/pages/2095087622/Hey+BE+-+Configura+es+-+Tarifas',
        linkEN: 'https://hostpms.atlassian.net/wiki/spaces/CMSup/pages/2095087622/Hey+BE+-+Configura+es+-+Tarifas'
    },  
    {
        id: 'RoomCategoryConfigHelp',
        linkPT: 'https://hostpms.atlassian.net/wiki/spaces/CMSup/pages/2095808548/Hey+BE+-+Configura+es+-+Un.+Alojamento+-+Configura+o',
        linkEN: 'https://hostpms.atlassian.net/wiki/spaces/CMSup/pages/2095808548/Hey+BE+-+Configura+es+-+Un.+Alojamento+-+Configura+o'
    },  
    {
        id: 'RoomCategoryDescHelp',
        linkPT: 'https://hostpms.atlassian.net/wiki/spaces/CMSup/pages/2095808555/Hey+BE+-+Configura+es+-+Un.+Alojamento+-+Descri+o',
        linkEN: 'https://hostpms.atlassian.net/wiki/spaces/CMSup/pages/2095808555/Hey+BE+-+Configura+es+-+Un.+Alojamento+-+Descri+o'
    },  
    {
        id: 'RoomCategoryAmenitiesHelp',
        linkPT: 'https://hostpms.atlassian.net/wiki/spaces/CMSup/pages/2096267301/Hey+BE+-+Configura+es+-+Un.+Alojamento+-+Comodidades',
        linkEN: 'https://hostpms.atlassian.net/wiki/spaces/CMSup/pages/2096267301/Hey+BE+-+Configura+es+-+Un.+Alojamento+-+Comodidades'
    },
    {
        id: 'RoomCategoryPhotosHelp',
        linkPT: 'https://hostpms.atlassian.net/wiki/spaces/CMSup/pages/2095775805/Hey+BE+-+Configura+es+-+Un.+Alojamento+-+Fotos',
        linkEN: 'https://hostpms.atlassian.net/wiki/spaces/CMSup/pages/2095775805/Hey+BE+-+Configura+es+-+Un.+Alojamento+-+Fotos'
    }, 
    {
        id: 'MappingHelp',
        linkPT: 'https://hostpms.atlassian.net/wiki/spaces/CMSup/pages/2096070673/Hey+BE+-+Configura+es+-+Mapeamentos',
        linkEN: 'https://hostpms.atlassian.net/wiki/spaces/CMSup/pages/2096070673/Hey+BE+-+Configura+es+-+Mapeamentos'
    },  
    {
        id: 'ComplexClassificationHelp',
        linkPT: 'https://hostpms.atlassian.net/wiki/spaces/CMSup/pages/2096267291/Hey+BE+-+Configura+es+-+Propriedade+-+Dados+Gerais',
        linkEN: 'https://hostpms.atlassian.net/wiki/spaces/CMSup/pages/2096267291/Hey+BE+-+Configura+es+-+Propriedade+-+Dados+Gerais'
    }, 
    {
        id: 'ComplexPhotosHelp',
        linkPT: 'https://hostpms.atlassian.net/wiki/spaces/CMSup/pages/2095579154/Hey+BE+-+Configura+es+-+Propriedade+-+Fotos',
        linkEN: 'https://hostpms.atlassian.net/wiki/spaces/CMSup/pages/2095579154/Hey+BE+-+Configura+es+-+Propriedade+-+Fotos'
    },
    {
        id: 'CustomGA',
        linkPT: 'https://support.google.com/analytics/answer/9539598?hl=pt',
        linkEN: 'https://support.google.com/analytics/answer/9539598?hl=en'
    },
    {
        id: 'CustomGTM',
        linkPT: 'https://support.google.com/tagmanager/answer/6103696?hl=pt',
        linkEN: 'https://support.google.com/tagmanager/answer/6103696?hl=en'
    },
    {
        id: 'CustomFBP',
        linkPT: 'https://www.facebook.com/business/help/171689010314963',
        linkEN: 'https://www.facebook.com/business/help/171689010314963'
    },
    {
        id: 'CompareAvailabilityHelp',
        linkPT: 'https://hostpms.atlassian.net/wiki/spaces/CM/pages/2332000257/Comparar+disponibilidade',
        linkEN: 'https://hostpms.atlassian.net/wiki/spaces/CME/pages/2526642177/Compare+Availability'
    },
    {
        id: 'ComparePmsHelp',
        linkPT: 'https://hostpms.atlassian.net/wiki/spaces/CM/pages/2727018515/Compare+PMS+data',
        linkEN: 'https://hostpms.atlassian.net/wiki/spaces/CME/pages/2726985746/Compare+PMS+data'
    },
    {
        id: 'InternalChangesHelp',
        linkPT: 'https://hostpms.atlassian.net/wiki/spaces/CM/pages/2338553857/Hist+rico+das+atualiza+es',
        linkEN: 'https://hostpms.atlassian.net/wiki/spaces/CME/pages/2526380099/Internal+Changes+Logs'
    },
    {
        id: 'BaseParamsHelp',
        linkPT: 'https://hostpms.atlassian.net/wiki/spaces/CM/pages/2204729347/Hey+BE+-+Configura+es+-+Criar+URL',
        linkEN: 'https://hostpms.atlassian.net/wiki/spaces/CME/pages/2511863906/Hey+BE+-+Configurations+-+Create+URL'
    },
    {
        id: 'BulkPricesUpdateHelp',
        linkPT: 'https://hostpms.atlassian.net/wiki/spaces/CM/pages/2257682433/Atualiza+es+de+Pre+os',
        linkEN: 'https://hostpms.atlassian.net/wiki/spaces/CME/pages/2265972737/Update+Prices'
    },
    {
        id: 'ComplexSocialProfile',
        linkPT: 'https://hostpms.atlassian.net/wiki/spaces/CM/pages/2141159425/Hey+BE+-+Configura+es+-+Propriedade+-+Configura+o',
        linkEN: 'https://hostpms.atlassian.net/wiki/spaces/CME/pages/2143716202/Hey+BE+-+Configurations-+Property+-+Configuration'
    },
    {
        id: 'MicroUpdateHelp',
        linkPT: 'https://hostpms.atlassian.net/wiki/spaces/CM/pages/2520678403/Atualiza+o+de+Calend+rio',
        linkEN: 'https://hostpms.atlassian.net/wiki/spaces/CME/pages/2557902858/Calendar+Update'
    },
    {
        id: 'experiences',
        linkPT: 'https://hostpms.atlassian.net/wiki/spaces/CM/pages/2685632513/Hey+BE+-+Configura+es+-+Experi+ncias',
        linkEN: 'https://hostpms.atlassian.net/wiki/spaces/CME/pages/2752937985/Hey+BE+-+Configurations+-+Experiences'
    }
];

const AirbnbCurrency = ["","AED", "ARS", "AUD", "BGN", "BRL", "CAD", "CHF", "CLP", "CNY", "COP", "CRC", "CZK", "DKK", "EUR", "GBP", "HKD", "HRK", "HUF", "IDR", "ILS", "INR", "JPY", "KRW", "MAD", "MXN", "MYR", "NOK", "NZD", "PEN", "PHP", "PLN", "RON", "RUB", "SAR", "SEK", "SGD", "THB", "TRY", "TWD", "UAH", "USD", "UYU", "VND", "ZAR"];

const CheckInTime = [{ value: '00:00', label: '00:00' }, { value: '00:30', label: '00:30' }, { value: '01:00', label: '01:00' }, { value: '01:30', label: '01:30' }, { value: '02:00', label: '02:00' }, { value: '02:30', label: '02:30' }, { value: '03:00', label: '03:00' }, { value: '03:30', label: '03:30' }, { value: '04:00', label: '04:00' }, { value: '04:30', label: '04:30' }, { value: '05:00', label: '05:00' }, { value: '05:30', label: '05:30' }, { value: '06:00', label: '06:00' }, { value: '06:30', label: '06:30' }, { value: '07:00', label: '07:00' }, { value: '07:30', label: '07:30' }, { value: '08:00', label: '08:00' }, { value: '08:30', label: '08:30' }, { value: '09:00', label: '09:00' }, { value: '09:30', label: '09:30' }, { value: '10:00', label: '10:00' }, { value: '10:30', label: '10:30' }, { value: '11:00', label: '11:00' }, { value: '11:30', label: '11:30' }, { value: '12:00', label: '12:00' }, { value: '12:30', label: '12:30' }, { value: '13:00', label: '13:00' }, { value: '13:30', label: '13:30' }, { value: '14:00', label: '14:00' }, { value: '14:30', label: '14:30' }, { value: '15:00', label: '15:00' }, { value: '15:30', label: '15:30' }, { value: '16:00', label: '16:00' }, { value: '16:30', label: '16:30' }, { value: '17:00', label: '17:00' }, { value: '17:30', label: '17:30' }, { value: '18:00', label: '18:00' }, { value: '18:30', label: '18:30' }, { value: '19:00', label: '19:00' }, { value: '19:30', label: '19:30' }, { value: '20:00', label: '20:00' }, { value: '20:30', label: '20:30' }, { value: '21:00', label: '21:00' }, { value: '21:30', label: '21:30' }, { value: '22:00', label: '22:00' }, { value: '22:30', label: '22:30' }, { value: '23:00', label: '23:00' }, { value: '23:30', label: '23:30' }]

const Currency = [
    { value: 'AX', label: 'American Express' },
    { value: 'BC', label : 'Bankcard' },
    { value: 'CB', label : 'Carte Blanche' },
    { value: 'MA', label : 'Switch' },
    { value: 'DS', label : 'Discover' },
    { value: 'JC', label : 'JCB' },
    { value: 'DN', label : 'Diners Club' },
    { value: 'MC', label : 'Euro/Mastercard' },
    { value: 'VI', label : 'Visa' },
    { value: 'CU', label: 'UnionPay credit card' }];

const AirbnbCancelationPolicy = [
    { value: 'flexible', label: 'Full refund 1 day prior to arrival, except fees' },
    { value: 'moderate', label: 'Full refund 5 days prior to arrival, except fees' },
    { value: 'strict_14_with_grace_period', label: 'Full refund within 48 hours of booking if before 14 days of checkin. After, 50% refund up until 1 week prior to arrival' },
    { value: 'super_strict_30', label: '50% refund up until 30 days prior to arrival, except fees' },
    { value: 'super_strict_60', label: '50% refund up until 60 days prior to arrival, except fees.' }
];

const AirbnbAmenity = [
    { value: 'essentials', label: 'Essentials. Towels, bed sheets, soap, and toilet paper' },
    { value: 'kitchen', label: 'Kitchen. Space where guests can cook their own meals' },
    { value: 'ac', label: 'Air conditioning' },
    { value: 'heating', label: 'Heating. Central heating or a heater in the listing' },
    { value: 'hair-dryer', label: 'Hair dryer' },
    { value: 'hangers', label: 'Hangers' },
    { value: 'iron', label: 'Iron' },
    { value: 'washer', label: 'Washer. In the building, free or for a feee' },
    { value: 'dryer', label: 'Dryer. In the building, free or for a feee' },
    { value: 'hot_water', label: 'Hot water' },
    { value: 'tv', label: 'TV' },
    { value: 'cable', label: 'Cable TV' },
    { value: 'fireplace', label: 'Indoor fireplace' },
    { value: 'private-entrance', label: 'Private entrance. Separate street or building entrance' },
    { value: 'private-living-room', label: 'Private living room' },
    { value: 'lock_on_bedroom_door', label: 'Lock on bedroom door. Private room can be locked for safety and privacy' },
    { value: 'shampoo', label: 'Shampoo' },
    { value: 'bed_linens', label: 'Bed linens' },
    { value: 'extra_pillows_and_blankets', label: 'Extra pillows and blankets' },
    { value: 'wireless_internet', label: 'Wireless Internet. Continuous access in the listing' },
    { value: 'ethernet_connection', label: 'Ethernet connection.' },
    { value: 'pocket_wifi', label: 'Pocket wifi.' },
    { value: 'laptop-friendly', label: 'Laptop friendly workspace. A table or desk with space for a laptop and a chair that’s comfortable to work in' }
];

const AirbnbFacility = [
    { value: 'free_parking', label: 'Free parking on premises.' },
    { value: 'street_parking', label: 'Free street parking.' },
    { value: 'paid_parking', label: 'Paid parking off premises.' },
    { value: 'paid_parking_on_premises', label: 'Paid parking on premises.' },
    { value: 'ev_charger', label: 'EV charger' },
    { value: 'gym', label: 'Gym. Free, in the building or nearby' },
    { value: 'pool', label: 'Pool. Private or Shared' },
    { value: 'jacuzzi', label: 'Hot tub.' },
    { value: 'single_level_home', label: 'Single level home. No stairs in home' },

];

const AirbnbLocation = [
    { value: 'beachfront', label: 'Beachfront' },
    { value: 'lake_access', label: 'Lake access' },
    { value: 'ski_in_ski_out', label: 'Ski in/Ski out' },
    { value: 'waterfront', label: 'Waterfront' }

];

const AirbnbBookingAllowedCategory = [
    { value: 'everyone', label: 'Everyone who meets standard Airbnb requirements can make Instant Book reservations' },
    { value: 'experienced', label: 'Only guests who have traveled on Airbnb, are recommended by other hosts, and have no negative reviews can make Instant Book reservations.' },
    { value: 'government_id', label: 'Only guests with verified government-issued ID can make Instant Book reservations.' },
    { value: 'experienced_guest_with_government_id', label: 'Only guests with verified government-issued ID and who have traveled on Airbnb, are recommended by other hosts, and have no negative reviews can make Instant Book reservations.' }

];

const AirbnbExpectationTypes  = [
    { key: '1' , value: 'requires_stairs', label: 'Must climb stairs. Describe the stairs (for example, how many flights).' },
    { key: '2' , value: 'potential_noise', label: 'Potential for noise. Describe the noise and when it’s likely to take place.' },
    { key: '3' ,value: 'has_pets', label: 'Pet(s) live on property. Describe the pets.' },
    { key: '4',value: 'limited_parking', label: 'No parking on property. Describe the parking situation around the listing.' },
    { key: '5',value: 'shared_spaces', label: 'Some spaces are shared. Describe the spaces the guests will share.' },
    { key: '6',value: 'limited_amenities', label: 'Amenity limitations. Describe an amenity or service that’s limited, such as weak wifi or limited hot water.' },
    { key: '7',value: 'surveillance', label: 'Surveillance or recording devices on property. Describe any device that records or sends video, audio, or still images. Specify each device’s location and whether it will be on or off' },
    { key: '8',value: 'weapons', label: 'Weapons on property. Describe any weapons (firearms, airguns, tasers, etc.), their location, and how they’re secured.' },
    { key: '9',value: 'animals', label: 'Dangerous animals on property. Describe any domesticated or wild animals that could be a health or safety risk to guests or other animals.' }

];

const AirbnbGuestControl= [
    { value: 'allows_children_as_host', label: 'Is listing suitable for children (2-12 years)?', required: true, type : 'boolean'},
    { value: 'allows_infants_as_host', label: 'Is listing suitable for infants (under 2 years)? ', required: true, type: 'boolean'},
    { value: 'children_not_allowed_details', label: 'If you selected false for any of the above, this field is required to provide details about why the place is not suitable for children.', required: false, type: 'string'},
    { value: 'allows_smoking_as_host', label: 'Is smoking allowed?', required: true, type: 'boolean'},
    { value: 'allows_pets_as_host', label: 'Are pets allowed?', required: true, type: 'boolean'},
    { value: 'allows_events_as_host', label: 'Are parties or events allowed?', required: true, type: 'boolean'}
];

const ReservationDeclineRegion = [
    { value: 'dates_not_available', label: 'dates_not_available' },
    { value: 'not_a_good_fit', label: 'not_a_good_fit' },
    { value: 'waiting_for_better_reservation', label: 'waiting_for_better_reservation' },
    { value: 'not_comfortable', label: 'not_comfortable' }

];

const AirbnbStandardFees = [
    { value: 'PASS_THROUGH_CLEANING_FEE', label: <FormattedMessage id="AirbnbPriceSettings.cleaningfee" /> },
    { value: 'PASS_THROUGH_RESORT_FEE', label: <FormattedMessage id="ReferenceDataFunctions.ResortFee" /> },
    { value: 'PASS_THROUGH_MANAGEMENT_FEE', label: <FormattedMessage id="ReferenceDataFunctions.ManagementFee" /> },
    { value: 'PASS_THROUGH_COMMUNITY_FEE', label: <FormattedMessage id="ReferenceDataFunctions.CommunityFee" /> },
    { value: 'PASS_THROUGH_LINEN_FEE', label: <FormattedMessage id="ReferenceDataFunctions.LinenFee" /> }

];

export function getAirbnbReviewCategoryTags(isGuestReview) {
    if (isGuestReview) {
        return [
            //Positive
            { value: 'guest_review_host_positive_looked_like_photos', label: 'Looked like the photos', category: 'accuracy' },
            { value: 'guest_review_host_positive_matched_description', label: 'Matched the description', category: 'accuracy' },
            { value: 'guest_review_host_positive_had_listed_amenities_and_services', label: 'Had listed amenities & services', category: 'accuracy' },
            { value: 'guest_review_host_positive_responsive_host', label: 'Responsive Host', category: 'checkin' },
            { value: 'guest_review_host_positive_clear_instructions', label: 'Clear instructions', category: 'checkin' },
            { value: 'guest_review_host_positive_easy_to_find', label: 'Easy to find', category: 'checkin' },
            { value: 'guest_review_host_positive_easy_to_get_inside', label: 'Easy to get inside', category: 'checkin' },
            { value: 'guest_review_host_positive_flexible_check_in', label: 'Flexible check-in', category: 'checkin' },
            { value: 'guest_review_host_positive_felt_at_home', label: 'Felt right at home', category: 'checkin' },
            { value: 'guest_review_host_positive_spotless_furniture_and_linens', label: 'Spotless furniture & linens', category: 'cleanliness' },
            { value: 'guest_review_host_positive_free_of_clutter', label: 'Free of clutter', category: 'cleanliness' },
            { value: 'guest_review_host_positive_squeaky_clean_bathroom', label: 'Squeaky-clean bathroom', category: 'cleanliness' },
            { value: 'guest_review_host_positive_pristine_kitchen', label: 'Pristine kitchen', category: 'cleanliness' },
            { value: 'guest_review_host_positive_always_responsive', label: 'Always responsive', category: 'communication' },
            { value: 'guest_review_host_positive_local_recommendations', label: 'Local recommendations', category: 'communication' },
            { value: 'guest_review_host_positive_proactive', label: 'Proactive', category: 'communication' },
            { value: 'guest_review_host_positive_helpful_instructions', label: 'Helpful instructions', category: 'communication' },
            { value: 'guest_review_host_positive_considerate', label: 'Considerate', category: 'communication' },
            { value: 'guest_review_host_positive_peaceful', label: 'Peaceful', category: 'location' },
            { value: 'guest_review_host_positive_beautiful_surroundings', label: 'Beautiful surroundings', category: 'location' },
            { value: 'guest_review_host_positive_private', label: 'Private', category: 'location' },
            { value: 'guest_review_host_positive_great_restaurants', label: 'Great restaurants', category: 'location' },
            { value: 'guest_review_host_positive_lots_to_do', label: 'Lots to do', category: 'location' },
            { value: 'guest_review_host_positive_walkable', label: 'Walkable', category: 'location' },

            //Negative
            { value: 'guest_review_host_negative_smaller_than_expected', label: 'Smaller than expected', category: 'accuracy' },
            { value: 'guest_review_host_negative_did_not_match_photos', label: 'Didn’t match the photos', category: 'accuracy' },
            { value: 'guest_review_host_negative_needs_maintenance', label: 'Needs maintenance', category: 'accuracy' },
            { value: 'guest_review_host_negative_unexpected_fees', label: 'Unexpected fees', category: 'accuracy' },
            { value: 'guest_review_host_negative_excessive_rules', label: 'Excessive rules', category: 'accuracy' },
            { value: 'guest_review_host_negative_unexpected_noise', label: 'Unexpected noise', category: 'accuracy' },
            { value: 'guest_review_host_negative_inaccurate_location', label: 'Inaccurate location', category: 'accuracy' },
            { value: 'guest_review_host_negative_missing_amenity', label: 'Missing amenity or service', category: 'accuracy' },
            { value: 'guest_review_host_negative_hard_to_locate', label: 'Hard to locate', category: 'checkin' },
            { value: 'guest_review_host_negative_unclear_instructions', label: 'Unclear instructions', category: 'checkin' },
            { value: 'guest_review_host_negative_trouble_with_lock', label: 'Trouble with lock', category: 'checkin' },
            { value: 'guest_review_host_negative_unresponsive_host', label: 'Unresponsive Host', category: 'checkin' },
            { value: 'guest_review_host_negative_had_to_wait', label: 'Had to wait', category: 'checkin' },
            { value: 'guest_review_host_negative_hard_to_get_inside', label: 'Hard to get inside', category: 'checkin' },
            { value: 'guest_review_host_negative_dirty_or_dusty', label: 'Dirty or dusty', category: 'cleanliness' },
            { value: 'guest_review_host_negative_noticeable_smell', label: 'Noticeable smell', category: 'cleanliness' },
            { value: 'guest_review_host_negative_stains', label: 'Stains', category: 'cleanliness' },
            { value: 'guest_review_host_negative_excessive_clutter', label: 'Excessive clutter', category: 'cleanliness' },
            { value: 'guest_review_host_negative_messy_kitchen', label: 'Messy kitchen', category: 'cleanliness' },
            { value: 'guest_review_host_negative_hair_or_pet_hair', label: 'Hair or pet hair', category: 'cleanliness' },
            { value: 'guest_review_host_negative_dirty_bathroom', label: 'Dirty bathroom', category: 'cleanliness' },
            { value: 'guest_review_host_negative_trash_left_behind', label: 'Trash left behind', category: 'cleanliness' },
            { value: 'guest_review_host_negative_broken_or_missing_lock', label: 'Broken or missing lock on door', category: 'accuracy' },
            { value: 'guest_review_host_negative_unexpected_guests', label: 'Unexpected guest in space', category: 'accuracy' },
            { value: 'guest_review_host_negative_incorrect_bathroom', label: 'Incorrect bathroom type', category: 'accuracy' },
            { value: 'guest_review_host_negative_slow_to_respond', label: 'Slow to respond', category: 'communication' },
            { value: 'guest_review_host_negative_not_helpful', label: 'Not helpful', category: 'communication' },
            { value: 'guest_review_host_negative_missing_house_instructions', label: 'Missing house instructions', category: 'communication' },
            { value: 'guest_review_host_negative_unclear_checkout_tasks', label: 'Unclear checkout tasks', category: 'communication' },
            { value: 'guest_review_host_negative_inconsiderate', label: 'Inconsiderate', category: 'communication' },
            { value: 'guest_review_host_negative_excessive_checkout_tasks', label: 'Excessive checkout tasks', category: 'communication' },
            { value: 'guest_review_host_negative_noisy', label: 'Noisy', category: 'location' },
            { value: 'guest_review_host_negative_not_much_to_do', label: 'Not much to do', category: 'location' },
            { value: 'guest_review_host_negative_bland_surroundings', label: 'Bland surroundings', category: 'location' },
            { value: 'guest_review_host_negative_not_private', label: 'Not private', category: 'location' },
            { value: 'guest_review_host_negative_inconvenient_location', label: 'Inconvenient location', category: 'location' },

            //Other
            { value: 'accuracy_other', label: 'Other', category: 'accuracy' },
            { value: 'check_in_other', label: 'Other', category: 'checkin' },
            { value: 'cleanliness_other', label: 'Other', category: 'cleanliness' },
            { value: 'communication_other', label: 'Other', category: 'communication' },
            { value: 'location_other', label: 'Other', category: 'location' },
            { value: 'respect_house_rules_other', label: 'Other', category: 'respect_house_rules' },

        ]
    }
    else {
        return [
            //Positve
            { value: 'host_review_guest_positive_neat_and_tidy', label: 'Neat & tidy', category: 'cleanliness' },
            { value: 'host_review_guest_positive_kept_in_good_condition', label: 'Kept in good condition', category: 'cleanliness' },
            { value: 'host_review_guest_positive_took_care_of_garbage', label: 'Took care of garbage', category: 'cleanliness' },
            { value: 'host_review_guest_positive_helpful_messages', label: 'Helpful messages', category: 'communication' },
            { value: 'host_review_guest_positive_respectful', label: 'Respectful', category: 'communication' },
            { value: 'host_review_guest_positive_always_responded', label: 'Always responded', category: 'communication' },

            //Negative
            { value: 'host_review_guest_negative_ignored_checkout_directions', label: 'Ignored check-out directions', category: 'cleanliness' },
            { value: 'host_review_guest_negative_garbage', label: 'Excessive garbage', category: 'cleanliness' },
            { value: 'host_review_guest_negative_messy_kitchen', label: 'Messy kitchen', category: 'cleanliness' },
            { value: 'host_review_guest_negative_damage', label: 'Damaged property', category: 'cleanliness' },
            { value: 'host_review_guest_negative_ruined_bed_linens', label: 'Ruined bed linens', category: 'cleanliness' },
            { value: 'host_review_guest_negative_arrived_early', label: 'Arrived too early', category: 'respect_house_rules' },
            { value: 'host_review_guest_negative_stayed_past_checkout', label: 'Stayed past checkout', category: 'respect_house_rules' },
            { value: 'host_review_guest_negative_unapproved_guests', label: 'Unapproved guests', category: 'respect_house_rules' },
            { value: 'host_review_guest_negative_unapproved_pet', label: 'Unapproved pet', category: 'respect_house_rules' },
            { value: 'host_review_guest_negative_did_not_respect_quiet_hours', label: 'Didn’t respect quiet hours', category: 'respect_house_rules' },
            { value: 'host_review_guest_negative_unapproved_filming', label: 'Unapproved filming or photography', category: 'respect_house_rules' },
            { value: 'host_review_guest_negative_unapproved_event', label: 'Unapproved event', category: 'respect_house_rules' },
            { value: 'host_review_guest_negative_smoking', label: 'Smoking', category: 'respect_house_rules' },
            { value: 'host_review_guest_negative_unhelpful_messages', label: 'Unhelpful responses', category: 'communication' },
            { value: 'host_review_guest_negative_disrespectful', label: 'Disrespectful', category: 'communication' },
            { value: 'host_review_guest_negative_unreachable', label: 'Unreachable', category: 'communication' },
            { value: 'host_review_guest_negative_slow_responses', label: 'Slow responses', category: 'communication' },

            //Others
            { value: 'accuracy_other', label: 'Other', category: 'accuracy' },
            { value: 'check_in_other', label: 'Other', category: 'checkin' },
            { value: 'cleanliness_other', label: 'Other', category: 'cleanliness' },
            { value: 'communication_other', label: 'Other', category: 'communication' },
            { value: 'location_other', label: 'Other', category: 'location' },
            { value: 'respect_house_rules_other', label: 'Other', category: 'respect_house_rules' }
        ]
    }
}       

const Countries = [
    { value: 'Afghanistan', label: 'Afghanistan'},
    { value: 'Albania', label: 'Albania'},
    { value: 'Algeria', label: 'Algeria'},
    { value: 'Andorra', label: 'Andorra'},
    { value: 'Angola', label: 'Angola'},
    { value: 'Antigua and Barbuda', label: 'Antigua and Barbuda'},
    { value: 'Argentina', label: 'Argentina'},
    { value: 'Armenia', label: 'Armenia'},
    { value: 'Australia', label: 'Australia'},
    { value: 'Austria', label: 'Austria'},
    { value: 'Azerbaijan', label: 'Azerbaijan'},
    { value: 'Bahamas', label: 'Bahamas'},
    { value: 'Bahrain', label: 'Bahrain'},
    { value: 'Bangladesh', label: 'Bangladesh'},
    { value: 'Barbados', label: 'Barbados'},
    { value: 'Belarus', label: 'Belarus'},
    { value: 'Belgium', label: 'Belgium'},
    { value: 'Belize', label: 'Belize'},
    { value: 'Benin', label: 'Benin'},
    { value: 'Bhutan', label: 'Bhutan'},
    { value: 'Bolivia', label: 'Bolivia'},
    { value: 'Bosnia and Herzegovina', label: 'Bosnia and Herzegovina'},
    { value: 'Botswana', label: 'Botswana'},
    { value: 'Brazil', label: 'Brazil'},
    { value: 'Brunei', label: 'Brunei'},
    { value: 'Bulgaria', label: 'Bulgaria'},
    { value: 'Burkina Faso', label: 'Burkina Faso'},
    { value: 'Burundi', label: 'Burundi'},
    { value: 'Côte d\'Ivoire', label: 'Côte d\'Ivoire'},
    { value: 'Cabo Verde', label: 'Cabo Verde'},
    { value: 'Cambodia', label: 'Cambodia'},
    { value: 'Cameroon', label: 'Cameroon'},
    { value: 'Canada', label: 'Canada'},
    { value: 'Central African Republic', label: 'Central African Republic'},
    { value: 'Chad', label: 'Chad'},
    { value: 'Chile', label: 'Chile'},
    { value: 'China', label: 'China'},
    { value: 'Colombia', label: 'Colombia'},
    { value: 'Comoros', label: 'Comoros' },
    { value: 'Congo (Congo-Brazzaville)', label: 'Congo (Congo-Brazzaville)' },
    { value: 'Costa Rica', label: 'Costa Rica'},
    { value: 'Croatia', label: 'Croatia'},
    { value: 'Cuba', label: 'Cuba'},
    { value: 'Cyprus', label: 'Cyprus' },
    { value: 'Czechia (Czech Republic)', label: 'Czechia (Czech Republic)' },
    { value: 'Democratic Republic of the Congo', label: 'Democratic Republic of the Congo'},
    { value: 'Denmark', label: 'Denmark'},
    { value: 'Djibouti', label: 'Djibouti'},
    { value: 'Dominica', label: 'Dominica' },
    { value: 'Dominican Republic', label: 'Dominican Republic' },
    { value: 'Ecuador', label: 'Ecuador'},
    { value: 'Egypt', label: 'Egypt'},
    { value: 'El Salvador', label: 'El Salvador'},
    { value: 'Equatorial Guinea', label: 'Equatorial Guinea' },
    { value: 'Eritrea', label: 'Eritrea' },
    { value: 'Estonia', label: 'Estonia'},
    { value: 'Eswatini (fmr. "Swaziland")', label: 'Eswatini (fmr. "Swaziland")'},
    { value: 'Ethiopia', label: 'Ethiopia'},
    { value: 'Fiji', label: 'Fiji' },
    { value: 'Finland', label: 'Finland' },
    { value: 'France', label: 'France'},
    { value: 'Gabon', label: 'Gabon'},
    { value: 'Gambia', label: 'Gambia'},
    { value: 'Georgia', label: 'Georgia' },
    { value: 'Germany', label: 'Germany' },
    { value: 'Ghana', label: 'Ghana'},
    { value: 'Greece', label: 'Greece'},
    { value: 'Grenada', label: 'Grenada'},
    { value: 'Guatemala', label: 'Guatemala' },
    { value: 'Guinea', label: 'Guinea' },
    { value: 'Guinea-Bissau', label: 'Guinea-Bissau'},
    { value: 'Guyana', label: 'Guyana'},
    { value: 'Haiti', label: 'Haiti'},
    { value: 'Holy See', label: 'Holy See' },
    { value: 'Honduras', label: 'Honduras' },
    { value: 'Hungary', label: 'Hungary'},
    { value: 'Iceland', label: 'Iceland'},
    { value: 'India', label: 'India'},
    { value: 'Indonesia', label: 'Indonesia' },
    { value: 'Iran', label: 'Iran' },
    { value: 'Iraq', label: 'Iraq'},
    { value: 'Ireland', label: 'Ireland'},
    { value: 'Israel', label: 'Israel'},
    { value: 'Italy', label: 'Italy' },
    { value: 'Jamaica', label: 'Jamaica' },
    { value: 'Japan', label: 'Japan'},
    { value: 'Jordan', label: 'Jordan'},
    { value: 'Kazakhstan', label: 'Kazakhstan'},
    { value: 'Kenya', label: 'Kenya' },
    { value: 'Kiribati', label: 'Kiribati' },
    { value: 'Kuwait', label: 'Kuwait'},
    { value: 'Kyrgyzstan', label: 'Kyrgyzstan'},
    { value: 'Laos', label: 'Laos'},
    { value: 'Latvia', label: 'Latvia' },
    { value: 'Lebanon', label: 'Lebanon' },
    { value: 'Lesotho', label: 'Lesotho'},
    { value: 'Liberia', label: 'Liberia'},
    { value: 'Libya', label: 'Libya'},
    { value: 'Liechtenstein', label: 'Liechtenstein'},
    { value: 'Lithuania', label: 'Lithuania'},
    { value: 'Luxembourg', label: 'Luxembourg' },
    { value: 'Madagascar', label: 'Madagascar' },
    { value: 'Malawi', label: 'Malawi'},
    { value: 'Malaysia', label: 'Malaysia'},
    { value: 'Maldives', label: 'Maldives'},
    { value: 'Mali', label: 'Mali' },
    { value: 'Malta', label: 'Malta' },
    { value: 'Marshall Islands', label: 'Marshall Islands'},
    { value: 'Mauritania', label: 'Mauritania'},
    { value: 'Mauritius', label: 'Mauritius'},
    { value: 'Mexico', label: 'Mexico'},
    { value: 'Micronesia', label: 'Micronesia'},
    { value: 'Moldova', label: 'Moldova' },
    { value: 'Monaco', label: 'Monaco' },
    { value: 'Mongolia', label: 'Mongolia'},
    { value: 'Montenegro', label: 'Montenegro'},
    { value: 'Morocco', label: 'Morocco'},
    { value: 'Mozambique', label: 'Mozambique' },
    { value: 'Myanmar (formerly Burma)', label: 'Myanmar (formerly Burma)' },
    { value: 'Namibia', label: 'Namibia'},
    { value: 'Nauru', label: 'Nauru'},
    { value: 'Nepal', label: 'Nepal'},
    { value: 'Netherlands', label: 'Netherlands'},
    { value: 'New Zealand', label: 'New Zealand'},
    { value: 'Nicaragua', label: 'Nicaragua' },
    { value: 'Niger', label: 'Niger' },
    { value: 'Nigeria', label: 'Nigeria'},
    { value: 'North Korea', label: 'North Korea'},
    { value: 'North Macedonia', label: 'North Macedonia'},
    { value: 'Norway', label: 'Norway' },
    { value: 'Oman', label: 'Oman' },
    { value: 'Pakistan', label: 'Pakistan'},
    { value: 'Palau', label: 'Palau'},
    { value: 'Palestine State', label: 'Palestine State'},
    { value: 'Panama', label: 'Panama'},
    { value: 'Papua New Guinea', label: 'Papua New Guinea'},
    { value: 'Paraguay', label: 'Paraguay' },
    { value: 'Peru', label: 'Peru' },
    { value: 'Philippines', label: 'Philippines'},
    { value: 'Poland', label: 'Poland'},
    { value: 'Portugal', label: 'Portugal'},
    { value: 'Qatar', label: 'Qatar' },
    { value: 'Romania', label: 'Romania' },
    { value: 'Russia', label: 'Russia'},
    { value: 'Rwanda', label: 'Rwanda'},
    { value: 'Saint Kitts and Nevis', label: 'Saint Kitts and Nevis'},
    { value: 'Saint Lucia', label: 'Saint Lucia'},
    { value: 'Saint Vincent and the Grenadines', label: 'Saint Vincent and the Grenadines'},
    { value: 'Samoa', label: 'Samoa' },
    { value: 'San Marino', label: 'San Marino' },
    { value: 'Sao Tome and Principe', label: 'Sao Tome and Principe'},
    { value: 'Saudi Arabia', label: 'Saudi Arabia'},
    { value: 'Senegal', label: 'Senegal'},
    { value: 'Serbia', label: 'Serbia' },
    { value: 'Seychelles', label: 'Seychelles' },
    { value: 'Sierra Leone', label: 'Sierra Leone'},
    { value: 'Singapore', label: 'Singapore'},
    { value: 'Slovakia', label: 'Slovakia'},
    { value: 'Slovenia', label: 'Slovenia'},
    { value: 'Solomon Islands', label: 'Solomon Islands'},
    { value: 'Somalia', label: 'Somalia' },
    { value: 'South Africa', label: 'South Africa' },
    { value: 'South Korea', label: 'South Korea'},
    { value: 'South Sudan', label: 'South Sudan'},
    { value: 'Spain', label: 'Spain'},
    { value: 'Sri Lanka', label: 'Sri Lanka' },
    { value: 'Sudan', label: 'Sudan' },
    { value: 'Suriname', label: 'Suriname'},
    { value: 'Sweden', label: 'Sweden'},
    { value: 'Switzerland', label: 'Switzerland'},
    { value: 'Syria', label: 'Syria'},
    { value: 'Tajikistan', label: 'Tajikistan'},
    { value: 'Tanzania', label: 'Tanzania' },
    { value: 'Thailand', label: 'Thailand' },
    { value: 'Timor-Leste', label: 'Timor-Leste'},
    { value: 'Togo', label: 'Togo'},
    { value: 'Tonga', label: 'Tonga'},
    { value: 'Trinidad and Tobago', label: 'Trinidad and Tobago' },
    { value: 'Tunisia', label: 'Tunisia' },
    { value: 'Turkey', label: 'Turkey'},
    { value: 'Turkmenistan', label: 'Turkmenistan'},
    { value: 'Tuvalu', label: 'Tuvalu'},
    { value: 'Uganda', label: 'Uganda'},
    { value: 'Ukraine', label: 'Ukraine'},
    { value: 'United Arab Emirates', label: 'United Arab Emirates' },
    { value: 'United Kingdom', label: 'United Kingdom' },
    { value: 'United States of America', label: 'United States of America'},
    { value: 'Uruguay', label: 'Uruguay'},
    { value: 'Uzbekistan', label: 'Uzbekistan'},
    { value: 'Vanuatu', label: 'Vanuatu' },
    { value: 'Venezuela', label: 'Venezuela' },
    { value: 'Vietnam', label: 'Vietnam'},
    { value: 'Yemen', label: 'Yemen'},
    { value: 'Zambia', label: 'Zambia'},
    { value: 'Zimbabwe', label: 'Zimbabwe'}
]

var countryISOMapping = {
    AFG: "AF",
    ALA: "AX",
    ALB: "AL",
    DZA: "DZ",
    ASM: "AS",
    AND: "AD",
    AGO: "AO",
    AIA: "AI",
    ATA: "AQ",
    ATG: "AG",
    ARG: "AR",
    ARM: "AM",
    ABW: "AW",
    AUS: "AU",
    AUT: "AT",
    AZE: "AZ",
    BHS: "BS",
    BHR: "BH",
    BGD: "BD",
    BRB: "BB",
    BLR: "BY",
    BEL: "BE",
    BLZ: "BZ",
    BEN: "BJ",
    BMU: "BM",
    BTN: "BT",
    BOL: "BO",
    BIH: "BA",
    BWA: "BW",
    BVT: "BV",
    BRA: "BR",
    VGB: "VG",
    IOT: "IO",
    BRN: "BN",
    BGR: "BG",
    BFA: "BF",
    BDI: "BI",
    KHM: "KH",
    CMR: "CM",
    CAN: "CA",
    CPV: "CV",
    CYM: "KY",
    CAF: "CF",
    TCD: "TD",
    CHL: "CL",
    CHN: "CN",
    HKG: "HK",
    MAC: "MO",
    CXR: "CX",
    CCK: "CC",
    COL: "CO",
    COM: "KM",
    COG: "CG",
    COD: "CD",
    COK: "CK",
    CRI: "CR",
    CIV: "CI",
    HRV: "HR",
    CUB: "CU",
    CYP: "CY",
    CZE: "CZ",
    DNK: "DK",
    DJI: "DJ",
    DMA: "DM",
    DOM: "DO",
    ECU: "EC",
    EGY: "EG",
    SLV: "SV",
    GNQ: "GQ",
    ERI: "ER",
    EST: "EE",
    ETH: "ET",
    FLK: "FK",
    FRO: "FO",
    FJI: "FJ",
    FIN: "FI",
    FRA: "FR",
    GUF: "GF",
    PYF: "PF",
    ATF: "TF",
    GAB: "GA",
    GMB: "GM",
    GEO: "GE",
    DEU: "DE",
    GHA: "GH",
    GIB: "GI",
    GRC: "GR",
    GRL: "GL",
    GRD: "GD",
    GLP: "GP",
    GUM: "GU",
    GTM: "GT",
    GGY: "GG",
    GIN: "GN",
    GNB: "GW",
    GUY: "GY",
    HTI: "HT",
    HMD: "HM",
    VAT: "VA",
    HND: "HN",
    HUN: "HU",
    ISL: "IS",
    IND: "IN",
    IDN: "ID",
    IRN: "IR",
    IRQ: "IQ",
    IRL: "IE",
    IMN: "IM",
    ISR: "IL",
    ITA: "IT",
    JAM: "JM",
    JPN: "JP",
    JEY: "JE",
    JOR: "JO",
    KAZ: "KZ",
    KEN: "KE",
    KIR: "KI",
    PRK: "KP",
    KOR: "KR",
    KWT: "KW",
    KGZ: "KG",
    LAO: "LA",
    LVA: "LV",
    LBN: "LB",
    LSO: "LS",
    LBR: "LR",
    LBY: "LY",
    LIE: "LI",
    LTU: "LT",
    LUX: "LU",
    MKD: "MK",
    MDG: "MG",
    MWI: "MW",
    MYS: "MY",
    MDV: "MV",
    MLI: "ML",
    MLT: "MT",
    MHL: "MH",
    MTQ: "MQ",
    MRT: "MR",
    MUS: "MU",
    MYT: "YT",
    MEX: "MX",
    FSM: "FM",
    MDA: "MD",
    MCO: "MC",
    MNG: "MN",
    MNE: "ME",
    MSR: "MS",
    MAR: "MA",
    MOZ: "MZ",
    MMR: "MM",
    NAM: "NA",
    NRU: "NR",
    NPL: "NP",
    NLD: "NL",
    ANT: "AN",
    NCL: "NC",
    NZL: "NZ",
    NIC: "NI",
    NER: "NE",
    NGA: "NG",
    NIU: "NU",
    NFK: "NF",
    MNP: "MP",
    NOR: "NO",
    OMN: "OM",
    PAK: "PK",
    PLW: "PW",
    PSE: "PS",
    PAN: "PA",
    PNG: "PG",
    PRY: "PY",
    PER: "PE",
    PHL: "PH",
    PCN: "PN",
    POL: "PL",
    PRT: "PT",
    PRI: "PR",
    QAT: "QA",
    REU: "RE",
    ROU: "RO",
    RUS: "RU",
    RWA: "RW",
    BLM: "BL",
    SHN: "SH",
    KNA: "KN",
    LCA: "LC",
    MAF: "MF",
    SPM: "PM",
    VCT: "VC",
    WSM: "WS",
    SMR: "SM",
    STP: "ST",
    SAU: "SA",
    SEN: "SN",
    SRB: "RS",
    SYC: "SC",
    SLE: "SL",
    SGP: "SG",
    SVK: "SK",
    SVN: "SI",
    SLB: "SB",
    SOM: "SO",
    ZAF: "ZA",
    SGS: "GS",
    SSD: "SS",
    ESP: "ES",
    LKA: "LK",
    SDN: "SD",
    SUR: "SR",
    SJM: "SJ",
    SWZ: "SZ",
    SWE: "SE",
    CHE: "CH",
    SYR: "SY",
    TWN: "TW",
    TJK: "TJ",
    TZA: "TZ",
    THA: "TH",
    TLS: "TL",
    TGO: "TG",
    TKL: "TK",
    TON: "TO",
    TTO: "TT",
    TUN: "TN",
    TUR: "TR",
    TKM: "TM",
    TCA: "TC",
    TUV: "TV",
    UGA: "UG",
    UKR: "UA",
    ARE: "AE",
    GBR: "GB",
    USA: "US",
    UMI: "UM",
    URY: "UY",
    UZB: "UZ",
    VUT: "VU",
    VEN: "VE",
    VNM: "VN",
    VIR: "VI",
    WLF: "WF",
    ESH: "EH",
    YEM: "YE",
    ZMB: "ZM",
    ZWE: "ZW",
    XKX: "XK"
}

function getbookingmisconductcategory(instance) {
    const bookingmisconductcategory =
    [
        {
            id: "1",
            title: instance.props.intl.formatMessage({ id: "ReferenceDataFunctions.PaymentIssue" }),
            subcategory: [
                { id: "110", title: instance.props.intl.formatMessage({ id: "ReferenceDataFunctions.DidntPayReservationAmount" }) },
                { id: "111", title: instance.props.intl.formatMessage({ id: "ReferenceDataFunctions.DidntPayAdditionalFees" }) },
                { id: "112", title: instance.props.intl.formatMessage({ id: "ReferenceDataFunctions.CreditCardDeclinedInvalid" }) },
                { id: "113", title: instance.props.intl.formatMessage({ id: "ReferenceDataFunctions.ChargebackClaim" }) },
                { id: "114", title: instance.props.intl.formatMessage({ id: "ReferenceDataFunctions.FraudulentCreditCard" }) },
                { id: "115", title: instance.props.intl.formatMessage({ id: "ReferenceDataFunctions.Other" }) }
            ]
        },
        {
            id: "7",
            title: instance.props.intl.formatMessage({ id: "ReferenceDataFunctions.BreakingHouseRules" }),
            subcategory: [
                { id: "170", "title": instance.props.intl.formatMessage({ id: "ReferenceDataFunctions.Smoking" }) },
                { id: "171", "title": instance.props.intl.formatMessage({ id: "ReferenceDataFunctions.BroughtPets" })},
                { id: "172", "title": instance.props.intl.formatMessage({ id: "ReferenceDataFunctions.ThrewAParty" })},
                { id: "173", "title": instance.props.intl.formatMessage({ id: "ReferenceDataFunctions.BrokeQuietHours" }) },
                { id: "174", "title": instance.props.intl.formatMessage({ id: "ReferenceDataFunctions.BroughtAdditionalGuests" }) },
                { id: "175", "title": instance.props.intl.formatMessage({ id: "ReferenceDataFunctions.BreachedCheckInCheckOutPolicy" }) },
                { id: "176", "title": instance.props.intl.formatMessage({ id: "ReferenceDataFunctions.Other" }) }
            ]
        },
        {
            id: "0",
            title: instance.props.intl.formatMessage({ id: "ReferenceDataFunctions.AbusiveBehaviour" }),
            subcategory: [
                { id: "100", "title": instance.props.intl.formatMessage({ id: "ReferenceDataFunctions.VerbalAbuse" }) },
                { id: "101", "title": instance.props.intl.formatMessage({ id: "ReferenceDataFunctions.PhysicalAbuse" }) },
                { id: "102", "title": instance.props.intl.formatMessage({ id: "ReferenceDataFunctions.VerbalPhysicalThreats" }) },
                { id: "103", "title": instance.props.intl.formatMessage({ id: "ReferenceDataFunctions.DisturbingGuestsNeighbours" }) },
                { id: "104", "title": instance.props.intl.formatMessage({ id: "ReferenceDataFunctions.Other" }) }
            ]
        },
        {
            id: "2",
            title: instance.props.intl.formatMessage({ id: "ReferenceDataFunctions.LeftPropertyDirty" }),
            subcategory: [
                { id: "120", "title": instance.props.intl.formatMessage({ id: "ReferenceDataFunctions.StainedLinens" }) },
                { id: "121", "title": instance.props.intl.formatMessage({ id: "ReferenceDataFunctions.StainsOnCarpetWallsFurniture" }) },
                { id: "122", "title": instance.props.intl.formatMessage({ id: "ReferenceDataFunctions.ExcessiveRubbish" }) },
                { id: "123", "title": instance.props.intl.formatMessage({ id: "ReferenceDataFunctions.Other" }) }
            ]
        },
        {
            id: "9",
            title: instance.props.intl.formatMessage({ id: "ReferenceDataFunctions.PropertyDamage" }),
            additional_fields: {
                damage_type_id: {
                    "type": "integer",
                    "description": instance.props.intl.formatMessage({ id: "ReferenceDataFunctions.SelectTypeOfDamage" }),
                    "possible_values": {
                        "possible_value": [
                            {
                                "value": "1",
                                "description": instance.props.intl.formatMessage({ id: "ReferenceDataFunctions.SmallFurniture" })
                            },
                            {
                                "value": "2",
                                "description": instance.props.intl.formatMessage({ id: "ReferenceDataFunctions.LargeFurniture" })
                            },
                            {
                                "value": "3",
                                "description": instance.props.intl.formatMessage({ id: "ReferenceDataFunctions.DoorWindowWall" })
                            },
                            {
                                "value": "4",
                                "description": instance.props.intl.formatMessage({ id: "ReferenceDataFunctions.Other" })
                            }
                        ]
                    }
                },
                "damage_cost": {
                    "type": "double",
                    "description": instance.props.intl.formatMessage({ id: "ReferenceDataFunctions.CostOfDamage" })
                },
                "damage_unavailable": {
                    "type": "integer",
                    "description": instance.props.intl.formatMessage({ id: "ReferenceDataFunctions.IsTheDamagePreventingYouFromAccommodatingYourNextReservation" }),
                    "possible_values": {
                        "possible_value": [
                            {
                                "value": "0",
                                "description": instance.props.intl.formatMessage({ id: "ReferenceDataFunctions.No" })
                            },
                            {
                                "value": "1",
                                "description": instance.props.intl.formatMessage({ id: "ReferenceDataFunctions.Yes" })
                            }
                        ]
                    }
                }
            }
        },
        {
            "id": "5",
            "title": instance.props.intl.formatMessage({ id: "ReferenceDataFunctions.IllegalActivities" }),
            "subcategory": [
                { "id": "150", "title": instance.props.intl.formatMessage({ id: "ReferenceDataFunctions.DrugUseDrugDealing" }) },
                { "id": "151", "title": instance.props.intl.formatMessage({ id: "ReferenceDataFunctions.NotComplyingWithLocalLaw" }) },
                { "id": "160", "title": instance.props.intl.formatMessage({ id: "ReferenceDataFunctions.Theft" }) },
                { "id": "152", "title": instance.props.intl.formatMessage({ id: "ReferenceDataFunctions.Other" }) }
            ]            
        }
    ];
    return bookingmisconductcategory;
}

export function getCountryISO2(countryCode) {
    return countryISOMapping[countryCode]
}
export function getCountryISO3(countryCode) {
    var result = Object.keys(countryISOMapping).find(key => countryISOMapping[key] === countryCode.toUpperCase()); 
    if(countryCode.length === 3 && !result){
        result = countryCode.toUpperCase()
    }
    return result
}

export function getChannelConfig(channelCode) {
    return channel.find(s => s.code.toUpperCase() === (channelCode!=null ? channelCode.toUpperCase() : '') );
}


export function getAirbnbGuestControl() { return AirbnbGuestControl }
export function getAirbnbExpectationTypes() { return AirbnbExpectationTypes }
export function getAirbnbCurrency() {return AirbnbCurrency}
export function getAirbnbCancelationPolicy() {return AirbnbCancelationPolicy}
export function getCheckInTime() { return CheckInTime }
export function getAirbnbAmenity() { return AirbnbAmenity }
export function getAirbnbFacility() { return AirbnbFacility }
export function getAirbnbLocation() { return AirbnbLocation }
export function getAirbnbBookingAllowedCategory() { return AirbnbBookingAllowedCategory }
export function getAirbnbMessageRole(instance) {
    const AirbnbMessageRoleArray = [
        { value: 'all', label: instance.props.intl.formatMessage({ id: "ReferenceDataFunctions.AllNonArchivedThreads" }) },
        { value: 'hidden', label: instance.props.intl.formatMessage({ id: "ReferenceDataFunctions.ArchivedThreads" }) },
        { value: 'late_or_no_response', label: instance.props.intl.formatMessage({ id: "ReferenceDataFunctions.LateOrNoResponse" }) },
        { value: 'never_responded', label: instance.props.intl.formatMessage({ id: "ReferenceDataFunctions.NeverResponded" }) },
        { value: 'reservations', label: instance.props.intl.formatMessage({ id: "ReferenceDataFunctions.Reservations" }) }
    ]
    return AirbnbMessageRoleArray;
}
export function getAirbnbMessageOrder(instance) {
    const AirbnbMessageOrder = [
        { value: 'created_at desc', label: instance.props.intl.formatMessage({ id: "ReferenceDataFunctions.CreatedDesc" }) },
        { value: 'created_at asc', label: instance.props.intl.formatMessage({ id: "ReferenceDataFunctions.CreatedAsc" }) },
        { value: 'updated_at desc', label: instance.props.intl.formatMessage({ id: "ReferenceDataFunctions.UpdatedDesc" }) },
        { value: 'updated_at asc', label: instance.props.intl.formatMessage({ id: "ReferenceDataFunctions.UpdatedAsc" }) }
    ];
    return AirbnbMessageOrder
}
export function getAirbnbReservationDeclineReason() { return ReservationDeclineRegion }
export function getAirbnbStandardFees() { return AirbnbStandardFees }

export function getCurrency() {
    return Currency;
}

export function getCurrencyCode() {
    let opt = [
        { "value": "EUR", "label": "European Euro (EUR)" },
        { "value": "AED", "label": "UAE dirham (AED)" },
        { "value": "AFN", "label": "Afghan afghani (AFN)" },
        { "value": "ALL", "label": "Albanian lek (ALL)" },
        { "value": "AMD", "label": "Armenian dram (AMD)" },
        { "value": "ANG", "label": "Netherlands Antillean gulden (ANG)" },
        { "value": "AOA", "label": "Angolan kwanza (AOA)" },
        { "value": "ARS", "label": "Argentine peso (ARS)" },
        { "value": "AUD", "label": "Australian dollar (AUD)" },
        { "value": "AWG", "label": "Aruban florin (AWG)" },
        { "value": "AZN", "label": "Azerbaijani manat (AZN)" },
        { "value": "BAM", "label": "Bosnia and Herzegovina konvertibilna marka (BAM)" },
        { "value": "BBD", "label": "Barbadian dollar (BBD)" },
        { "value": "BDT", "label": "Bangladeshi taka (BDT)" },
        { "value": "BGN", "label": "Bulgarian lev (BGN)" },
        { "value": "BHD", "label": "Bahraini dinar (BHD)" },
        { "value": "BIF", "label": "Burundi franc (BIF)" },
        { "value": "BMD", "label": "Bermudian dollar (BMD)" },
        { "value": "BND", "label": "Brunei dollar (BND)" },
        { "value": "BOB", "label": "Bolivian boliviano (BOB)" },
        { "value": "BRL", "label": "Brazilian real (BRL)" },
        { "value": "BSD", "label": "Bahamian dollar (BSD)" },
        { "value": "BTN", "label": "Bhutanese ngultrum (BTN)" },
        { "value": "BWP", "label": "Botswana pula (BWP)" },
        { "value": "BYR", "label": "Belarusian ruble (BYR)" },
        { "value": "BZD", "label": "Belize dollar (BZD)" },
        { "value": "CAD", "label": "Canadian dollar (CAD)" },
        { "value": "CDF", "label": "Congolese franc (CDF)" },
        { "value": "CHF", "label": "Swiss franc (CHF)" },
        { "value": "CLP", "label": "Chilean peso (CLP)" },
        { "value": "CNY", "label": "Chinese/Yuan renminbi (CNY)" },
        { "value": "COP", "label": "Colombian peso (COP)" },
        { "value": "CRC", "label": "Costa Rican colon (CRC)" },
        { "value": "CUC", "label": "Cuban peso (CUC)" },
        { "value": "CVE", "label": "Cape Verdean escudo (CVE)" },
        { "value": "CZK", "label": "Czech koruna (CZK)" },
        { "value": "DJF", "label": "Djiboutian franc (DJF)" },
        { "value": "DKK", "label": "Danish krone (DKK)" },
        { "value": "DOP", "label": "Dominican peso (DOP)" },
        { "value": "DZD", "label": "Algerian dinar (DZD)" },
        { "value": "EEK", "label": "Estonian kroon (EEK)" },
        { "value": "EGP", "label": "Egyptian pound (EGP)" },
        { "value": "ERN", "label": "Eritrean nakfa (ERN)" },
        { "value": "ETB", "label": "Ethiopian birr (ETB)" },
        { "value": "FJD", "label": "Fijian dollar (FJD)" },
        { "value": "FKP", "label": "Falkland Islands pound (FKP)" },
        { "value": "GBP", "label": "British pound (GBP)" },
        { "value": "GEL", "label": "Georgian lari (GEL)" },
        { "value": "GHS", "label": "Ghanaian cedi (GHS)" },
        { "value": "GIP", "label": "Gibraltar pound (GIP)" },
        { "value": "GMD", "label": "Gambian dalasi (GMD)" },
        { "value": "GNF", "label": "Guinean franc (GNF)" },
        { "value": "GQE", "label": "Central African CFA franc (GQE)" },
        { "value": "GTQ", "label": "Guatemalan quetzal (GTQ)" },
        { "value": "GYD", "label": "Guyanese dollar (GYD)" },
        { "value": "HKD", "label": "Hong Kong dollar (HKD)" },
        { "value": "HNL", "label": "Honduran lempira (HNL)" },
        { "value": "HRK", "label": "Croatian kuna (HRK)" },
        { "value": "HTG", "label": "Haitian gourde (HTG)" },
        { "value": "HUF", "label": "Hungarian forint (HUF)" },
        { "value": "IDR", "label": "Indonesian rupiah (IDR)" },
        { "value": "ILS", "label": "Israeli new sheqel (ILS)" },
        { "value": "INR", "label": "Indian rupee (INR)" },
        { "value": "IQD", "label": "Iraqi dinar (IQD)" },
        { "value": "IRR", "label": "Iranian rial (IRR)" },
        { "value": "ISK", "label": "Icelandic króna (ISK)" },
        { "value": "JMD", "label": "Jamaican dollar (JMD)" },
        { "value": "JOD", "label": "Jordanian dinar (JOD)" },
        { "value": "JPY", "label": "Japanese yen (JPY)" },
        { "value": "KES", "label": "Kenyan shilling (KES)" },
        { "value": "KGS", "label": "Kyrgyzstani som (KGS)" },
        { "value": "KHR", "label": "Cambodian riel (KHR)" },
        { "value": "KMF", "label": "Comorian franc (KMF)" },
        { "value": "KPW", "label": "North Korean won (KPW)" },
        { "value": "KRW", "label": "South Korean won (KRW)" },
        { "value": "KWD", "label": "Kuwaiti dinar (KWD)" },
        { "value": "KYD", "label": "Cayman Islands dollar (KYD)" },
        { "value": "KZT", "label": "Kazakhstani tenge (KZT)" },
        { "value": "LAK", "label": "Lao kip (LAK)" },
        { "value": "LBP", "label": "Lebanese lira (LBP)" },
        { "value": "LKR", "label": "Sri Lankan rupee (LKR)" },
        { "value": "LRD", "label": "Liberian dollar (LRD)" },
        { "value": "LSL", "label": "Lesotho loti (LSL)" },
        { "value": "LTL", "label": "Lithuanian litas (LTL)" },
        { "value": "LVL", "label": "Latvian lats (LVL)" },
        { "value": "LYD", "label": "Libyan dinar (LYD)" },
        { "value": "MAD", "label": "Morovaluean dirham (MAD)" },
        { "value": "MDL", "label": "Moldovan leu (MDL)" },
        { "value": "MGA", "label": "Malagasy ariary (MGA)" },
        { "value": "MKD", "label": "Macedonian denar (MKD)" },
        { "value": "MMK", "label": "Myanma kyat (MMK)" },
        { "value": "MNT", "label": "Mongolian tugrik (MNT)" },
        { "value": "MOP", "label": "Macanese pataca (MOP)" },
        { "value": "MRO", "label": "Mauritanian ouguiya (MRO)" },
        { "value": "MUR", "label": "Mauritian rupee (MUR)" },
        { "value": "MVR", "label": "Maldivian rufiyaa (MVR)" },
        { "value": "MWK", "label": "Malawian kwacha (MWK)" },
        { "value": "MXN", "label": "Mexican peso (MXN)" },
        { "value": "MYR", "label": "Malaysian ringgit (MYR)" },
        { "value": "MZM", "label": "Mozambican metical (MZM)" },
        { "value": "NAD", "label": "Namibian dollar (NAD)" },
        { "value": "NGN", "label": "Nigerian naira (NGN)" },
        { "value": "NIO", "label": "Nicaraguan córdoba (NIO)" },
        { "value": "NOK", "label": "Norwegian krone (NOK)" },
        { "value": "NPR", "label": "Nepalese rupee (NPR)" },
        { "value": "NZD", "label": "New Zealand dollar (NZD)" },
        { "value": "OMR", "label": "Omani rial (OMR)" },
        { "value": "PAB", "label": "Panamanian balboa (PAB)" },
        { "value": "PGK", "label": "Papua New Guinean kina (PGK)" },
        { "value": "PEN", "label": "Peruvian nuevo sol (PEN)" },
        { "value": "PHP", "label": "Philippine peso (PHP)" },
        { "value": "PKR", "label": "Pakistani rupee (PKR)" },
        { "value": "PLN", "label": "Polish zloty (PLN)" },
        { "value": "PYG", "label": "Paraguayan guarani (PYG)" },
        { "value": "QAR", "label": "Qatari riyal (QAR)" },
        { "value": "RON", "label": "Romanian leu (RON)" },
        { "value": "RSD", "label": "Serbian dinar (RSD)" },
        { "value": "RUB", "label": "Russian ruble (RUB)" },
        { "value": "SAR", "label": "Saudi riyal (SAR)" },
        { "value": "SBD", "label": "Solomon Islands dollar (SBD)" },
        { "value": "SCR", "label": "Seychellois rupee (SCR)" },
        { "value": "SDG", "label": "Sudanese pound (SDG)" },
        { "value": "SEK", "label": "Swedish krona (SEK)" },
        { "value": "SGD", "label": "Singapore dollar (SGD)" },
        { "value": "SHP", "label": "Saint Helena pound (SHP)" },
        { "value": "SLL", "label": "Sierra Leonean leone (SLL)" },
        { "value": "SOS", "label": "Somali shilling (SOS)" },
        { "value": "SRD", "label": "Surilabelse dollar (SRD)" },
        { "value": "SYP", "label": "Syrian pound (SYP)" },
        { "value": "SZL", "label": "Swazi lilangeni (SZL)" },
        { "value": "THB", "label": "Thai baht (THB)" },
        { "value": "TJS", "label": "Tajikistani somoni (TJS)" },
        { "value": "TMT", "label": "Turkmen manat (TMT)" },
        { "value": "TND", "label": "Tunisian dinar (TND)" },
        { "value": "TRY", "label": "Turkish new lira (TRY)" },
        { "value": "TTD", "label": "Trinidad and Tobago dollar (TTD)" },
        { "value": "TWD", "label": "New Taiwan dollar (TWD)" },
        { "value": "TZS", "label": "Tanzanian shilling (TZS)" },
        { "value": "UAH", "label": "Ukrainian hryvnia (UAH)" },
        { "value": "UGX", "label": "Ugandan shilling (UGX)" },
        { "value": "USD", "label": "United States dollar (USD)" },
        { "value": "UYU", "label": "Uruguayan peso (UYU)" },
        { "value": "UZS", "label": "Uzbekistani som (UZS)" },
        { "value": "VEB", "label": "Venezuelan bolivar (VEB)" },
        { "value": "VND", "label": "Vietlabelse dong (VND)" },
        { "value": "VUV", "label": "Vanuatu vatu (VUV)" },
        { "value": "WST", "label": "Samoan tala (WST)" },
        { "value": "XAF", "label": "Central African CFA franc (XAF)" },
        { "value": "XCD", "label": "East Caribbean dollar (XCD)" },
        { "value": "XDR", "label": "Special Drawing Rights (XDR)" },
        { "value": "XOF", "label": "West African CFA franc (XOF)" },
        { "value": "XPF", "label": "CFP franc (XPF)" },
        { "value": "YER", "label": "Yemeni rial (YER)" },
        { "value": "ZAR", "label": "South African rand (ZAR)" },
        { "value": "ZMK", "label": "Zambian kwacha (ZMK)" },
        { "value": "ZWR", "label": "Zimbabwean dollar (ZWR)" }
    ];
    return opt;
}

export function getCountries() {
    return Countries;
}

export function getOperationModes() {
    let arr =[
    {
        value: 'PmsFull',
        label: <FormattedMessage id="HotelLicenses.PmsFull" />
    },
    {
        value: 'PmsLimited',
        label: <FormattedMessage id="HotelLicenses.PmsLimited" />
    },
    {
        value: 'None',
        label: <FormattedMessage id="HotelLicenses.None" />
        }
    ];
    return arr;
}

export function getisLocalPms() {
    let arr = [
        {
            value: true,
            label: <FormattedMessage id="HotelLicenses.True" />
        },
        {
            value: false,
            label: <FormattedMessage id="HotelLicenses.False" />
        }
    ];

    return arr;
}

export function copyToClipboard(content) {
    navigator.clipboard.writeText(content);
    handleNotification({}, <FormattedMessage id="CopyToClipboard.Ok" />, "Success")
}

export function getStatus() { return Status; }
export function getActive(intl) {
    return [
        { value: true, label: intl.formatMessage({ id: "generic.active" }) },
        { value: false, label: intl.formatMessage({ id: "generic.inactive" }) }
    ]
}
export function getPriceMode() { return [{ 'label': <FormattedMessage id="generic.priceMode0" />, 'value': 0 }, { 'label': <FormattedMessage id="generic.priceMode1" />, 'value': 1 }, { 'label': <FormattedMessage id="generic.priceMode2" />, 'value': 2 }]; }
export function getInterfaceMode() { return [{ 'label': <FormattedMessage id="generic.inventoryMode1" />, 'value': 1 }, { 'label': <FormattedMessage id="generic.inventoryMode2" />, 'value': 2 }, { 'label': <FormattedMessage id="generic.inventoryMode3" />, 'value': 3 }, { 'label': <FormattedMessage id="generic.inventoryMode4" />, 'value': 4 }, { 'label': <FormattedMessage id="generic.inventoryMode5" />, 'value': 5 }, { 'label': <FormattedMessage id="generic.inventoryMode6" />, 'value': 6 }]; }
export function getMarkupType() { return [{ 'label': <FormattedMessage id="generic.markupType1" />, 'value': 1, icon: 'x' }, { 'label': <FormattedMessage id="generic.markupType2" />, 'value': 2, icon: '+' }]; }
export function getHelpLink(id, locale) {
    let lnk = HelpLink.find(el => el.id === id);
   
    if (lnk) return locale === 'pt' || locale === 'br' ? lnk.linkPT : lnk.linkEN
    else return null
}



export function getBookingMisconductCategories(instance) {
    let cat = []
    getbookingmisconductcategory(instance).map(el => cat.push({ label: el.title, value: el.id }));
    return cat;
}

export function getBookingMisconductSubCategories(cat, instance) {
    let subcat = []
    let bookingsubcat = getbookingmisconductcategory(instance).find(e => e.id === cat);

    if (bookingsubcat && bookingsubcat.subcategory !== undefined)
        bookingsubcat.subcategory.map(el => subcat.push({ label: el.title, value: el.id }));
    return subcat;
}

export function getPriceTypeList() {
    let arr = [
        {
            value: '1',
            label: <FormattedMessage id="ReferenceDataFunctions.FixedPrice" />
        }
    ];

    return arr;
}

export function getPostTypeList() {
    let arr = [
        {
            value: '0',
            label: <FormattedMessage id="ReferenceDataFunctions.PerPerson" />
        }
    ];

    return arr;
}

export function getModules(intl) {
    let modules = [
        {
            value: 'ManualReservations',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.ManualReservations"  })
        },
        {
            value: 'Google',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.Google" })
        },
        {
            value: 'Payments',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.Payments" })
        },
        {
            value: 'BookingEngine',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.BookingEngine" })
        },
        {
            value: 'BookingEngineChain',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.BookingEngineChain" })
        },
        {
            value: 'GoogleAnalytcs',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.GoogleAnalytics" })
        },
        {
            value: 'PriceRules',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.PriceRules" })
        },
        {
            value: 'ComplexPriceRules',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.ComplexPriceRules" })
        },
        {
            value: 'UpdateAvail',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.UpdateAvail" })
        },
        {
            value: 'MailReservation',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.MailReservation" })
        },
        {
            value: 'CurrencyConvertion',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.CurrencyConvertion" })
        },
        {
            value: 'OpenAI',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.OpenAI" })
        },
        {
            value: 'HeyCard',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.HeyCard" })
        },
        {
            value: 'FlexChildPrice',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.FlexChildPrice" })
        }
    ];

    return modules;
}

export function setCountryLabelsPT(countryList) {
    countryList().setLabel('AF', 'Afeganistão');
    countryList().setLabel('AX', 'Ilhas Åland');
    countryList().setLabel('AL', 'Albânia');
    countryList().setLabel('DZ', 'Argélia');
    countryList().setLabel('AS', 'Samoa Americana');
    countryList().setLabel('AD', 'Andorra');
    countryList().setLabel('AO', 'Angola');
    countryList().setLabel('AI', 'Anguilha');
    countryList().setLabel('AQ', 'Antártida');
    countryList().setLabel('AG', 'Antígua e Barbuda');
    countryList().setLabel('AR', 'Argentina');
    countryList().setLabel('AM', 'Arménia');
    countryList().setLabel('AW', 'Aruba');
    countryList().setLabel('AU', 'Austrália');
    countryList().setLabel('AT', 'Áustria');
    countryList().setLabel('AZ', 'Azerbaijão');
    countryList().setLabel('BS', 'Bahamas');
    countryList().setLabel('BH', 'Bahrein');
    countryList().setLabel('BD', 'Bangladesh');
    countryList().setLabel('BB', 'Barbados');
    countryList().setLabel('BY', 'Bielorrússia');
    countryList().setLabel('BE', 'Bélgica');
    countryList().setLabel('BZ', 'Belize');
    countryList().setLabel('BJ', 'Benim');
    countryList().setLabel('BM', 'Bermudas');
    countryList().setLabel('BT', 'Butão');
    countryList().setLabel('BO', 'Bolívia');
    countryList().setLabel('BQ', 'Bonaire');
    countryList().setLabel('BA', 'Bósnia e Herzegovina');
    countryList().setLabel('BW', 'Botswana');
    countryList().setLabel('BV', 'Ilha Bouvet');
    countryList().setLabel('BR', 'Brasil');
    countryList().setLabel('IO', 'Território Britânico do Oceano Índico');
    countryList().setLabel('BN', 'Brunei');
    countryList().setLabel('BG', 'Bulgária');
    countryList().setLabel('BF', 'Burkina Faso');
    countryList().setLabel('BI', 'Burundi');
    countryList().setLabel('KH', 'Camboja');
    countryList().setLabel('CM', 'Camarões');
    countryList().setLabel('CA', 'Canadá');
    countryList().setLabel('CV', 'Cabo Verde');
    countryList().setLabel('KY', 'Ilhas Caimão');
    countryList().setLabel('CF', 'República Centro Africana');
    countryList().setLabel('TD', 'Chade');
    countryList().setLabel('CL', 'Chile');
    countryList().setLabel('CN', 'China');
    countryList().setLabel('CX', 'Ilha Christmas');
    countryList().setLabel('CC', 'Ilhas Cocos (Keeling)');
    countryList().setLabel('CO', 'Colômbia');
    countryList().setLabel('KM', 'Comores');
    countryList().setLabel('CG', 'Congo');
    countryList().setLabel('CD', 'República Democrática do Congo');
    countryList().setLabel('CK', 'Ilhas Cook');
    countryList().setLabel('CR', 'Costa Rica');
    countryList().setLabel('CI', 'Costa do Marfim');
    countryList().setLabel('HR', 'Croácia');
    countryList().setLabel('CU', 'Cuba');
    countryList().setLabel('CW', 'Curaçao');
    countryList().setLabel('CY', 'Chipre');
    countryList().setLabel('CZ', 'República Checa');
    countryList().setLabel('DK', 'Dinamarca');
    countryList().setLabel('DJ', 'Djibouti');
    countryList().setLabel('DM', 'Dominica');
    countryList().setLabel('DO', 'República Dominicana');
    countryList().setLabel('EC', 'Equador');
    countryList().setLabel('EG', 'Egito');
    countryList().setLabel('SV', 'El Salvador');
    countryList().setLabel('GQ', 'Guiné Equatorial');
    countryList().setLabel('ER', 'Eritreia');
    countryList().setLabel('EE', 'Estónia');
    countryList().setLabel('ET', 'Etiópia');
    countryList().setLabel('FK', 'Ilhas Malvinas');
    countryList().setLabel('FO', 'Ilhas Feroe');
    countryList().setLabel('FJ', 'Fiji');
    countryList().setLabel('FI', 'Finlândia');
    countryList().setLabel('FR', 'França');
    countryList().setLabel('GF', 'Guiana Francesa');
    countryList().setLabel('PF', 'Polinésia Francesa');
    countryList().setLabel('TF', 'Território Sul Francês');
    countryList().setLabel('GA', 'Gabão');
    countryList().setLabel('GM', 'Gâmbia');
    countryList().setLabel('GE', 'Georgia');
    countryList().setLabel('DE', 'Alemanha');
    countryList().setLabel('GH', 'Gana');
    countryList().setLabel('GI', 'Gibraltar');
    countryList().setLabel('GR', 'Grécia');
    countryList().setLabel('GL', 'Gronelândia');
    countryList().setLabel('GD', 'Granada');
    countryList().setLabel('GP', 'Guadalupe');
    countryList().setLabel('GU', 'Guam');
    countryList().setLabel('GP', 'Guatemala');
    countryList().setLabel('GG', 'Guernsey');
    countryList().setLabel('GN', 'Guiné');
    countryList().setLabel('GW', 'Guiné-Bissau');
    countryList().setLabel('GY', 'Guiana');
    countryList().setLabel('HT', 'Haiti');
    countryList().setLabel('HM', 'Ilha Heard e Ilhas McDonald');
    countryList().setLabel('VA', 'Vaticano');
    countryList().setLabel('HN', 'Honduras');
    countryList().setLabel('HK', 'Hong Kong');
    countryList().setLabel('HU', 'Hungria');
    countryList().setLabel('IS', 'Islândia');
    countryList().setLabel('IN', 'Índia');
    countryList().setLabel('ID', 'Indonésia');
    countryList().setLabel('IR', 'Irão');
    countryList().setLabel('IQ', 'Iraque');
    countryList().setLabel('IE', 'Irlanda');
    countryList().setLabel('IM', 'Ilha de Man');
    countryList().setLabel('IL', 'Israel');
    countryList().setLabel('IT', 'Itália');
    countryList().setLabel('JM', 'Jamaica');
    countryList().setLabel('JP', 'Japão');
    countryList().setLabel('JE', 'Jersey');
    countryList().setLabel('JO', 'Jordânia');
    countryList().setLabel('KZ', 'Cazaquistão');
    countryList().setLabel('KE', 'Quênia');
    countryList().setLabel('KI', 'Kiribati');
    countryList().setLabel('KP', 'Coreia do Norte');
    countryList().setLabel('KR', 'Coreia do Sul');
    countryList().setLabel('KW', 'Kuwait');
    countryList().setLabel('KG', 'Quirguistão');
    countryList().setLabel('LA', 'Laos');
    countryList().setLabel('LV', 'Letónia');
    countryList().setLabel('LB', 'Líbano');
    countryList().setLabel('LS', 'Lesoto');
    countryList().setLabel('LR', 'Libéria');
    countryList().setLabel('LY', 'Líbia');
    countryList().setLabel('LI', 'Liechtenstein');
    countryList().setLabel('LT', 'Lituânia');
    countryList().setLabel('LU', 'Luxemburgo');
    countryList().setLabel('MO', 'Macau');
    countryList().setLabel('MK', 'Macedónia do Norte');
    countryList().setLabel('MG', 'Madagáscar');
    countryList().setLabel('MW', 'Malawi');
    countryList().setLabel('MY', 'Malásia');
    countryList().setLabel('MV', 'Maldivas');
    countryList().setLabel('ML', 'Mali');
    countryList().setLabel('MT', 'Malta');
    countryList().setLabel('MH', 'Ilhas Marshall');
    countryList().setLabel('MQ', 'Martinica');
    countryList().setLabel('MR', 'Mauritânia');
    countryList().setLabel('MU', 'Maurícia');
    countryList().setLabel('YT', 'Mayotte');
    countryList().setLabel('MX', 'México');
    countryList().setLabel('FM', 'Micronésia');
    countryList().setLabel('MD', 'Moldávia');
    countryList().setLabel('MC', 'Mónaco');
    countryList().setLabel('MN', 'Mongólia');
    countryList().setLabel('ME', 'Montenegro');
    countryList().setLabel('MS', 'Montserrat');
    countryList().setLabel('MA', 'Marrocos');
    countryList().setLabel('MZ', 'Moçambique');
    countryList().setLabel('MM', 'Myanmar');
    countryList().setLabel('NA', 'Namíbia');
    countryList().setLabel('NR', 'Nauru');
    countryList().setLabel('NP', 'Nepal');
    countryList().setLabel('NL', 'Holanda');
    countryList().setLabel('NC', 'Nova Caledónia');
    countryList().setLabel('NZ', 'Nova Zelândia');
    countryList().setLabel('NI', 'Nicarágua');
    countryList().setLabel('NE', 'Níger');
    countryList().setLabel('NG', 'Nigéria');
    countryList().setLabel('NU', 'Niue');
    countryList().setLabel('NF', 'Ilha Norfolk');
    countryList().setLabel('MP', 'Ilhas Marianas');
    countryList().setLabel('NO', 'Noruega');
    countryList().setLabel('OM', 'Omã');
    countryList().setLabel('PK', 'Paquistão');
    countryList().setLabel('PW', 'Palau');
    countryList().setLabel('PS', 'Palestina');
    countryList().setLabel('PA', 'Panamá');
    countryList().setLabel('PG', 'Papua-Nova Guiné');
    countryList().setLabel('PY', 'Paraguai');
    countryList().setLabel('PE', 'Peru');
    countryList().setLabel('PH', 'Filipinas');
    countryList().setLabel('PN', 'Ilhas Pitcairn');
    countryList().setLabel('PL', 'Polónia');
    countryList().setLabel('PT', 'Portugal');
    countryList().setLabel('PR', 'Porto Rico');
    countryList().setLabel('QA', 'Catar');
    countryList().setLabel('RE', 'Ilha da Reunião');
    countryList().setLabel('RO', 'Roménia');
    countryList().setLabel('RU', 'Rússia');
    countryList().setLabel('RW', 'Ruanda');
    countryList().setLabel('BL', 'São Bartolomeu');
    countryList().setLabel('SH', 'Santa Helena, Ascensão e Tristão da Cunha');
    countryList().setLabel('KN', 'São Cristóvão e Nevis');
    countryList().setLabel('LC', 'Santa Lúcia');
    countryList().setLabel('MF', 'São Martinho (França)');
    countryList().setLabel('PM', 'São Pedro e Miquelão');
    countryList().setLabel('VC', 'São Vicente e Granadinas');
    countryList().setLabel('WS', 'Samoa');
    countryList().setLabel('SM', 'San Marino');
    countryList().setLabel('ST', 'São Tomé e Príncipe');
    countryList().setLabel('SA', 'Arábia Saudita');
    countryList().setLabel('SN', 'Senegal');
    countryList().setLabel('RS', 'Sérvia');
    countryList().setLabel('SC', 'Seicheles');
    countryList().setLabel('SL', 'Serra Leoa');
    countryList().setLabel('SG', 'Singapura');
    countryList().setLabel('SX', 'São Martinho (Holanda)');
    countryList().setLabel('SK', 'Eslováquia');
    countryList().setLabel('SI', 'Eslovénia');
    countryList().setLabel('SB', 'Ilhas Salomão');
    countryList().setLabel('SO', 'Somália');
    countryList().setLabel('ZA', 'África do Sul');
    countryList().setLabel('GS', 'Ilhas Geórgia do Sul e Sandwich do Sul');
    countryList().setLabel('SS', 'Sudão do Sul');
    countryList().setLabel('ES', 'Espanha');
    countryList().setLabel('LK', 'Sri Lanka');
    countryList().setLabel('SD', 'Sudão');
    countryList().setLabel('SR', 'Suriname');
    countryList().setLabel('SJ', 'Svalbard e Jan Mayen');
    countryList().setLabel('SZ', 'Suazilândia');
    countryList().setLabel('SE', 'Suécia');
    countryList().setLabel('CH', 'Suíça');
    countryList().setLabel('SY', 'Síria');
    countryList().setLabel('TW', 'Taiwan');
    countryList().setLabel('TJ', 'Tajiquistão');
    countryList().setLabel('TZ', 'Tanzânia');
    countryList().setLabel('TH', 'Tailândia');
    countryList().setLabel('TL', 'Timor-Leste');
    countryList().setLabel('TG', 'Togo');
    countryList().setLabel('TK', 'Toquelau');
    countryList().setLabel('TO', 'Tonga');
    countryList().setLabel('TT', 'Trindade e Tobago');
    countryList().setLabel('TN', 'Tunísia');
    countryList().setLabel('TR', 'Turquia');
    countryList().setLabel('TM', 'Turquemenistão');
    countryList().setLabel('TC', 'Ilhas Turcas e Caicos');
    countryList().setLabel('TV', 'Tuvalu');
    countryList().setLabel('UG', 'Uganda');
    countryList().setLabel('UA', 'Ucrânia');
    countryList().setLabel('AE', 'Emirados Árabes Unidos');
    countryList().setLabel('GB', 'Reino Unido');
    countryList().setLabel('US', 'Estados Unidos da América');
    countryList().setLabel('UM', 'Ilhas Menores Distantes dos Estados Unidos');
    countryList().setLabel('UY', 'Uruguai');
    countryList().setLabel('UZ', 'Uzbequistão');
    countryList().setLabel('VU', 'Vanuatu');
    countryList().setLabel('VE', 'Venezuela');
    countryList().setLabel('VN', 'Vietname');
    countryList().setLabel('VG', 'Ilhas Virgens Britânicas');
    countryList().setLabel('VI', 'Ilhas Virgens Americanas');
    countryList().setLabel('WF', 'Wallis e Futuna');
    countryList().setLabel('EH', 'Saara Ocidental');
    countryList().setLabel('YE', 'Iémen');
    countryList().setLabel('ZM', 'Zâmbia');
    countryList().setLabel('ZW', 'Zimbaué');
    countryList().setLabel('XK', 'Kosovo');
}

export function setCountryLabelsEN(countryList) {
    countryList().setLabel('AF', 'Afghanistan');
    countryList().setLabel('AX', 'Åland Islands');
    countryList().setLabel('AL', 'Albania');
    countryList().setLabel('DZ', 'Algeria');
    countryList().setLabel('AS', 'American Samoa');
    countryList().setLabel('AD', 'Andorra');
    countryList().setLabel('AO', 'Angola');
    countryList().setLabel('AI', 'Anguilla');
    countryList().setLabel('AQ', 'Antarctica');
    countryList().setLabel('AG', 'Antigua and Barbuda');
    countryList().setLabel('AR', 'Argentina');
    countryList().setLabel('AM', 'Armenia');
    countryList().setLabel('AW', 'Aruba');
    countryList().setLabel('AU', 'Australia');
    countryList().setLabel('AT', 'Austria');
    countryList().setLabel('AZ', 'Azerbaijan');
    countryList().setLabel('BS', 'Bahamas');
    countryList().setLabel('BH', 'Bahrein');
    countryList().setLabel('BD', 'Bangladesh');
    countryList().setLabel('BB', 'Barbados');
    countryList().setLabel('BY', 'Belarus');
    countryList().setLabel('BE', 'Belgium');
    countryList().setLabel('BZ', 'Belize');
    countryList().setLabel('BJ', 'Benim');
    countryList().setLabel('BM', 'Bermuda');
    countryList().setLabel('BT', 'Bhutan');
    countryList().setLabel('BO', 'Bolivia, Plurinational State of');
    countryList().setLabel('BQ', 'Bonaire, Sint Eustatius and Saba');
    countryList().setLabel('BA', 'Bosnia and Herzegovina');
    countryList().setLabel('BW', 'Botswana');
    countryList().setLabel('BV', 'Bouvet Island');
    countryList().setLabel('BR', 'Brazil');
    countryList().setLabel('IO', 'British Indian Ocean Territory');
    countryList().setLabel('BN', 'Brunei Darussalam');
    countryList().setLabel('BG', 'Bulgaria');
    countryList().setLabel('BF', 'Burkina Faso');
    countryList().setLabel('BI', 'Burundi');
    countryList().setLabel('KH', 'Cambodia');
    countryList().setLabel('CM', 'Cameroon');
    countryList().setLabel('CA', 'Canada');
    countryList().setLabel('CV', 'Cape Verde');
    countryList().setLabel('KY', 'Cayman Islands');
    countryList().setLabel('CF', 'Central African Republic');
    countryList().setLabel('TD', 'Chad');
    countryList().setLabel('CL', 'Chile');
    countryList().setLabel('CN', 'China');
    countryList().setLabel('CX', 'Christmas Island');
    countryList().setLabel('CC', 'Cocos (Keeling) Islands');
    countryList().setLabel('CO', 'Colombia');
    countryList().setLabel('KM', 'Comores');
    countryList().setLabel('CG', 'Congo');
    countryList().setLabel('CD', 'Congo, the Democratic Republic of the');
    countryList().setLabel('CK', 'Cook Islands');
    countryList().setLabel('CR', 'Costa Rica');
    countryList().setLabel('CI', `Côte d'Ivoire`);
    countryList().setLabel('HR', 'Croatia');
    countryList().setLabel('CU', 'Cuba');
    countryList().setLabel('CW', 'Curaçao');
    countryList().setLabel('CY', 'Cyprus');
    countryList().setLabel('CZ', 'Czech Republic');
    countryList().setLabel('DK', 'Denmark');
    countryList().setLabel('DJ', 'Djibouti');
    countryList().setLabel('DM', 'Dominica');
    countryList().setLabel('DO', 'Dominican Republic');
    countryList().setLabel('EC', 'Ecuador');
    countryList().setLabel('EG', 'Egypt');
    countryList().setLabel('SV', 'El Salvador');
    countryList().setLabel('GQ', 'Equatorial Guinea');
    countryList().setLabel('ER', 'Eritrea');
    countryList().setLabel('EE', 'Estonia');
    countryList().setLabel('ET', 'Ethiopia');
    countryList().setLabel('FK', 'Falkland Islands (Malvinas)');
    countryList().setLabel('FO', 'Faroe Islands');
    countryList().setLabel('FJ', 'Fiji');
    countryList().setLabel('FI', 'Finland');
    countryList().setLabel('FR', 'France');
    countryList().setLabel('GF', 'French Guiana');
    countryList().setLabel('PF', 'French Polynesia');
    countryList().setLabel('TF', 'French Southern Territories');
    countryList().setLabel('GA', 'Gabon');
    countryList().setLabel('GM', 'Gambia');
    countryList().setLabel('GE', 'Georgia');
    countryList().setLabel('DE', 'Germany');
    countryList().setLabel('GH', 'Ghana');
    countryList().setLabel('GI', 'Gibraltar');
    countryList().setLabel('GR', 'Greece');
    countryList().setLabel('GL', 'Greenland');
    countryList().setLabel('GD', 'Grenada');
    countryList().setLabel('GP', 'Guadalupe');
    countryList().setLabel('GU', 'Guam');
    countryList().setLabel('GP', 'Guatemala');
    countryList().setLabel('GG', 'Guernsey');
    countryList().setLabel('GN', 'Guinea');
    countryList().setLabel('GW', 'Guinea-Bissau');
    countryList().setLabel('GY', 'Guyana');
    countryList().setLabel('HT', 'Haiti');
    countryList().setLabel('HM', 'Heard Island and McDonald Islands');
    countryList().setLabel('VA', 'Holy See (Vatican City State)');
    countryList().setLabel('HN', 'Honduras');
    countryList().setLabel('HK', 'Hong Kong');
    countryList().setLabel('HU', 'Hungary');
    countryList().setLabel('IS', 'Iceland');
    countryList().setLabel('IN', 'India');
    countryList().setLabel('ID', 'Indonesia');
    countryList().setLabel('IR', 'Iran, Islamic Republic of');
    countryList().setLabel('IQ', 'Iraq');
    countryList().setLabel('IE', 'Ireland');
    countryList().setLabel('IM', 'Isle of Man');
    countryList().setLabel('IL', 'Israel');
    countryList().setLabel('IT', 'Italy');
    countryList().setLabel('JM', 'Jamaica');
    countryList().setLabel('JP', 'Japan');
    countryList().setLabel('JE', 'Jersey');
    countryList().setLabel('JO', 'Jordan');
    countryList().setLabel('KZ', 'Kazakhstan');
    countryList().setLabel('KE', 'Kenya');
    countryList().setLabel('KI', 'Kiribati');
    countryList().setLabel('KP', `Korea, Democratic People's Republic of`);
    countryList().setLabel('KR', 'Korea, Republic of');
    countryList().setLabel('KW', 'Kuwait');
    countryList().setLabel('KG', 'Kyrgyzstan');
    countryList().setLabel('LA', `Lao People's Democratic Republic`);
    countryList().setLabel('LV', 'Latvia');
    countryList().setLabel('LB', 'Lebanon');
    countryList().setLabel('LS', 'Lesotho');
    countryList().setLabel('LR', 'Liberia');
    countryList().setLabel('LY', 'Libya');
    countryList().setLabel('LI', 'Liechtenstein');
    countryList().setLabel('LT', 'Lithuania');
    countryList().setLabel('LU', 'Luxemburg');
    countryList().setLabel('MO', 'Macao');
    countryList().setLabel('MK', 'Macedonia, the Former Yugoslav Republic of');
    countryList().setLabel('MG', 'Madagascar');
    countryList().setLabel('MW', 'Malawi');
    countryList().setLabel('MY', 'Malaysia');
    countryList().setLabel('MV', 'Maldives');
    countryList().setLabel('ML', 'Mali');
    countryList().setLabel('MT', 'Malta');
    countryList().setLabel('MH', 'Marshall Islands');
    countryList().setLabel('MQ', 'Martinique');
    countryList().setLabel('MR', 'Mauritania');
    countryList().setLabel('MU', 'Mauritius');
    countryList().setLabel('YT', 'Mayotte');
    countryList().setLabel('MX', 'Mexico');
    countryList().setLabel('FM', 'Micronesia, Federated States of');
    countryList().setLabel('MD', 'Moldova, Republic of');
    countryList().setLabel('MC', 'Monaco');
    countryList().setLabel('MN', 'Mongolia');
    countryList().setLabel('ME', 'Montenegro');
    countryList().setLabel('MS', 'Montserrat');
    countryList().setLabel('MA', 'Morocco');
    countryList().setLabel('MZ', 'Mozambique');
    countryList().setLabel('MM', 'Myanmar');
    countryList().setLabel('NA', 'Namibia');
    countryList().setLabel('NR', 'Nauru');
    countryList().setLabel('NP', 'Nepal');
    countryList().setLabel('NL', 'Netherlands');
    countryList().setLabel('NC', 'New Caledonia');
    countryList().setLabel('NZ', 'New Zealand');
    countryList().setLabel('NI', 'Nicaragua');
    countryList().setLabel('NE', 'Niger');
    countryList().setLabel('NG', 'Nigeria');
    countryList().setLabel('NU', 'Niue');
    countryList().setLabel('NF', 'Norfolk Island');
    countryList().setLabel('MP', 'Northern Mariana Islands');
    countryList().setLabel('NO', 'Norway');
    countryList().setLabel('OM', 'Oman');
    countryList().setLabel('PK', 'Pakistan');
    countryList().setLabel('PW', 'Palau');
    countryList().setLabel('PS', 'Palestine, State of');
    countryList().setLabel('PA', 'Panama');
    countryList().setLabel('PG', 'Papua New Guinea');
    countryList().setLabel('PY', 'Paraguay');
    countryList().setLabel('PE', 'Peru');
    countryList().setLabel('PH', 'Philippines');
    countryList().setLabel('PN', 'Pitcairn');
    countryList().setLabel('PL', 'Poland');
    countryList().setLabel('PT', 'Portugal');
    countryList().setLabel('PR', 'Puerto Rico');
    countryList().setLabel('QA', 'Qatar');
    countryList().setLabel('RE', 'Réunion');
    countryList().setLabel('RO', 'Romania');
    countryList().setLabel('RU', 'Russian Federation');
    countryList().setLabel('RW', 'Rwanda');
    countryList().setLabel('BL', 'Saint Barthélemy');
    countryList().setLabel('SH', 'Saint Helena, Ascension and Tristan da Cunha');
    countryList().setLabel('KN', 'Saint Kitts and Nevis');
    countryList().setLabel('LC', 'Saint Lucia');
    countryList().setLabel('MF', 'Saint Martin (French part)');
    countryList().setLabel('PM', 'Saint Pierre and Miquelon');
    countryList().setLabel('VC', 'Saint Vincent and the Grenadines');
    countryList().setLabel('WS', 'Samoa');
    countryList().setLabel('SM', 'San Marino');
    countryList().setLabel('ST', 'Sao Tome and Príncipe');
    countryList().setLabel('SA', 'Saudi Arabia');
    countryList().setLabel('SN', 'Senegal');
    countryList().setLabel('RS', 'Serbia');
    countryList().setLabel('SC', 'Seychelles');
    countryList().setLabel('SL', 'Sierra Leone');
    countryList().setLabel('SG', 'Singapore');
    countryList().setLabel('SX', 'Sint Maarten (Dutch part)');
    countryList().setLabel('SK', 'Slovakia');
    countryList().setLabel('SI', 'Slovenia');
    countryList().setLabel('SB', 'Solomon Islands');
    countryList().setLabel('SO', 'Somalia');
    countryList().setLabel('ZA', 'South Africa');
    countryList().setLabel('GS', 'South Georgia and the South Sandwich Islands');
    countryList().setLabel('SS', 'South Sudan');
    countryList().setLabel('ES', 'Spain');
    countryList().setLabel('LK', 'Sri Lanka');
    countryList().setLabel('SD', 'Sudan');
    countryList().setLabel('SR', 'Suriname');
    countryList().setLabel('SJ', 'Svalbard and Jan Mayen');
    countryList().setLabel('SZ', 'Swaziland');
    countryList().setLabel('SE', 'Sweden');
    countryList().setLabel('CH', 'Switzerland');
    countryList().setLabel('SY', 'Syrian Arab Republic');
    countryList().setLabel('TW', 'Taiwan, Province of China');
    countryList().setLabel('TJ', 'Tajikistan');
    countryList().setLabel('TZ', 'Tanzania, United Republic of');
    countryList().setLabel('TH', 'Thailand');
    countryList().setLabel('TL', 'Timor-Leste');
    countryList().setLabel('TG', 'Togo');
    countryList().setLabel('TK', 'Tokelau');
    countryList().setLabel('TO', 'Tonga');
    countryList().setLabel('TT', 'Trinidad and Tobago');
    countryList().setLabel('TN', 'Tunisia');
    countryList().setLabel('TR', 'Turkey');
    countryList().setLabel('TM', 'Turkmenistan');
    countryList().setLabel('TC', 'Turks and Caicos Islands');
    countryList().setLabel('TV', 'Tuvalu');
    countryList().setLabel('UG', 'Uganda');
    countryList().setLabel('UA', 'Ukraine');
    countryList().setLabel('AE', 'United Arab Emirates');
    countryList().setLabel('GB', 'United Kingdom');
    countryList().setLabel('US', 'United States');
    countryList().setLabel('UM', 'United States Minor Outlying Islands');
    countryList().setLabel('UY', 'Uruguay');
    countryList().setLabel('UZ', 'Uzbekistan');
    countryList().setLabel('VU', 'Vanuatu');
    countryList().setLabel('VE', 'Venezuela, Bolivarian Republic of');
    countryList().setLabel('VN', 'Vietnam');
    countryList().setLabel('VG', 'Virgin Islands, British');
    countryList().setLabel('VI', 'Virgin Islands, U.S.');
    countryList().setLabel('WF', 'Wallis and Futuna');
    countryList().setLabel('EH', 'Western Sahara');
    countryList().setLabel('YE', 'Yemen');
    countryList().setLabel('ZM', 'Zambia');
    countryList().setLabel('ZW', 'Zimbabwe');
    countryList().setLabel('XK', 'Kosovo');
}

export function getPaymentGatewayType() {
    const type = [        
        {
            value: 'Stripe',
            label: 'Stripe',
            logo: stripeLogo,
            showLogoHasText: false
        },
        {
            value: 'BankTransfer',
            label: <FormattedMessage id="PaymentGateway.BankTransfer" />,
            logo: <FormattedMessage id="PaymentGateway.BankTransfer" />,
            showLogoHasText: true
        },
        {
            value: 'Unicre',
            label: 'Unicre',
            logo: reduniqLogo,
            showLogoHasText: false
        },
        {
            value: 'Paybyrd',
            label: 'Paybyrd',
            logo: paybyrdLogo,
            showLogoHasText: false
        },
        {
            value: 'Custom',
            label: 'Custom',
            logo: <FormattedMessage id="PaymentGateway.Custom" />,
            showLogoHasText: true
        },
        {
            value: 'IfThenPay',
            label: 'IfThenPay',
            logo: ifThenPayLogo,
            showLogoHasText: false
        },
        {
            value: 'Redsys',
            label: 'Redsys',
            logo: redsysLogo,
            showLogoHasText: false
        },
        {
            value: 'Sibs',
            label: 'Sibs',
            logo: sibsLogo,
            showLogoHasText: false
        },
        {
            value: 'HeyCard',
            label: 'Hey!Card',
            logo: heyCardLogo,
            showLogoHasText: false
        }
    ];

    return type;
}

export function getPaymentModes() {
    const type = [        
        {
            value: 'CreditCard',
            label: <FormattedMessage id="Payments.CreditCard" />,
            img: (size, color) => <i className={`fas fa-credit-card ${color}`} style={{ fontSize: `${size}px` }} />
        },
        {
            value: 'BankTransfer',
            label: <FormattedMessage id="Payments.BankTransfer" />,
            img: (size, color) => <i className={`fas fa-hand-holding-usd ${color}`} style={{ fontSize: `${size}px` }} />
        },
        {
            value: 'MbWay',
            label: <FormattedMessage id="Payments.MbWay" />,
            img: size => <img height={size} src={mbWayLogo} />
        },
        {
            value: 'SibsMultibanco',
            label: <FormattedMessage id="Payments.SibsMultibanco" />,
            img: size => <img height={size} src={mbLogo} />
        },
        {
            value: 'None',
            label: <FormattedMessage id="Payments.None" />,
            img: (size, color) => <i className={`fas fa-hand-holding-usd ${color}`} style={{ fontSize: `${size}px` }} />
        },
        {
            value: 'Voucher',
            label: <FormattedMessage id="Payments.Voucher"/>,
            img: (size, color) => <i className={`fas fa-gift ${color}`} style={{ fontSize: `${size}px` }} />
        }
    ];

    return type;
}

export function getPaymentTypes() {
    const types = [
        {
            value: 'Deposit',
            label: <FormattedMessage id="Payments.Deposit" />
        },
        {
            value: 'Reservation',
            label: <FormattedMessage id="Payments.Reservation" />
        },
        {
            value: 'Services',
            label: <FormattedMessage id="Payments.Services" />
        }
    ];

    return types;
}

export function getOffersStatus() {
    const status = [
        {
            value: 'Active',
            label: <FormattedMessage id="generic.active" />,
            color: 'primary'
        },
        {
            value: 'Inactive',
            label: <FormattedMessage id="generic.inactive" />,
            color: 'secondary'
        }
    ];

    return status;
}

export function getExpTypes(intl) {
    const experinceTypesOptions = [
        { value: 'Wellness', label: intl.formatMessage({ id: "Experience.Wellness" })},
        { value: 'Romantic', label: intl.formatMessage({ id: "Experience.Romantic" })},
        { value: 'Adventure', label: intl.formatMessage({ id: "Experience.Adventure" })},
        { value: 'Wild', label: intl.formatMessage({ id: "Experience.Wild" })},
        { value: 'Event', label: intl.formatMessage({ id: "Experience.Event" })},
        { value: 'Experience', label: intl.formatMessage({ id: "Experience.Experience" })},
        { value: 'Sports', label: intl.formatMessage({ id: "Experience.Sports" })},
        { value: 'Gastronomy', label: intl.formatMessage({ id: "Experience.Gastronomy" })}
    ]

    return experinceTypesOptions;
}

export function getOffersServicePricingType() {
    const options = [
        { value: 'PerStay', label: <FormattedMessage id="ReferenceDataFunctions.PerStay" /> },
        { value: 'PerPerson', label: <FormattedMessage id="ReferenceDataFunctions.PerPerson" /> },
        { value: 'PerRoom', label: <FormattedMessage id="ReferenceDataFunctions.PerRoom" /> },
        { value: 'PerRoomPerNight', label: <FormattedMessage id="ReferenceDataFunctions.PerRoomPerNight" /> },
        { value: 'PerPersonPerNight', label: <FormattedMessage id="ReferenceDataFunctions.PerPersonPerNight" /> }
    ]

    return options;
}

export function getPaymentStatusOptions() {
    const paymentStatusOptions = [
        {
            value: 'N/A',
            label: 'N/A',
            badge: 'primary',
            isFinalSate: true
        },
        {
            value: 'Pending',
            label: <FormattedMessage id="Payments.Pending" />,
            badge: 'primary',
            isFinalSate: false
        },
        {
            value: 'requires_payment_method',
            label: <FormattedMessage id="Payments.RequiresPayment" />,
            badge: 'primary',
            isFinalSate: false
        },
        {
            value: 'succeeded',
            label: <FormattedMessage id="Payments.Succeeded" />,
            badge: 'success',
            isFinalSate: true
        },
        {
            value: 'canceled',
            label: <FormattedMessage id="Payments.Canceled" />,
            badge: 'danger',
            isFinalSate: true
        },
        {
            value: 'Rejected',
            label: <FormattedMessage id="Payments.Rejected" />,
            badge: 'danger',
            isFinalSate: true
        },
        {
            value: 'Invalid Id',
            label: <FormattedMessage id="Payments.InvalidId" />,
            badge: 'secondary',
            isFinalSate: true
        }
    ];

    return paymentStatusOptions;
}

export function getSocialMediaType() {
    const options = [
        {
            value: 'Facebook', label: 'Facebook',
            icon: <i className="fab fa-facebook" />
        },
        {
            value: 'Instagram', label: 'Instagram',
            icon: <i className="fab fa-instagram" />
        },
        {
            value: 'Linkedin', label: 'LinkedIn',
            icon: <i className="fab fa-linkedin" />
        },
        {
            value: 'Twitter', label: 'Twitter',
            icon: <i className="fab fa-twitter" />
        },
        {
            value: 'YouTube', label: 'YouTube',
            icon: <i className="fab fa-youtube" />
        },
        {
            value: 'Reddit', label: 'Reddit',
            icon: <i className="fab fa-reddit" />
        },
        {
            value: 'Discord', label: 'Discord',
            icon: <i className="fab fa-discord" />
        },
        {
            value: 'SnapChat', label: 'Snapchat',
            icon: <i className="fab fa-snapchat"></i>
        },
        {
            value: 'Pinterest', label: 'Pinterest',
            icon: <i className="fab fa-pinterest"></i>
        },
        {
            value: 'TikTok', label: 'TikTok',
            icon: <i className="fab fa-tiktok"></i>
        },
        {
            value: 'HotelUrl', label: 'Hotel',
            icon: <i className="fas fa-hotel"></i>
        }
    ]

    return options;
}

export function getReasonOptions() {
    const reasonOptions = [
        {
            value: 'requested_by_customer',
            label: <FormattedMessage id="Payments.RequestedByCustomer" />
        },
        {
            value: 'duplicate',
            label: <FormattedMessage id="Payments.Duplicate" />
        },
        {
            value: 'fraudulent',
            label: <FormattedMessage id="Payments.Fraudulent" />
        }
    ];

    return reasonOptions;
}


export function getFonts() {
    const fonts = [
        {
            value: 'Agency FB',
            label: 'Agency FB'
        },
        {
            value: 'Albertina',
            label: 'Albertina'
        },
        {
            value: 'Antiqua',
            label: 'Antiqua'
        },
        {
            value: 'Architect',
            label: 'Architect'
        },
        {
            value: 'Arial',
            label: 'Arial'
        },
        {
            value: 'BankFuturistic',
            label: 'BankFuturistic'
        },
        {
            value: 'BankGothic',
            label: 'BankGothic'
        },
        {
            value: 'Blackletter',
            label: 'Blackletter'
        },
        {
            value: 'Blagovest',
            label: 'Blagovest'
        },
        {
            value: 'Calibri',
            label: 'Calibri'
        },
        {
            value: 'Comic Sans MS',
            label: 'Comic Sans MS'
        },
        {
            value: 'Consolas',
            label: 'Consolas'
        },
        {
            value: 'Courier',
            label: 'Courier'
        },
        {
            value: 'Cursive',
            label: 'Cursive'
        },
        {
            value: 'Decorative',
            label: 'Decorative'
        },
        {
            value: 'Fantasy',
            label: 'Fantasy'
        },
        {
            value: 'Fraktur',
            label: 'Fraktur'
        },
        {
            value: 'Frosty',
            label: 'Frosty'
        },
        {
            value: 'Garamond',
            label: 'Garamond'
        },
        {
            value: 'Georgia',
            label: 'Georgia'
        },
        {
            value: 'Helvetica',
            label: 'Helvetica'
        },
        {
            value: 'Impact',
            label: 'Impact'
        },
        {
            value: 'Minion',
            label: 'Minion'
        },
        {
            value: 'Modern',
            label: 'Modern'
        },
        {
            value: 'Monospace',
            label: 'Monospace'
        },
        {
            value: 'Open Sans',
            label: 'Open Sans'
        },
        {
            value: 'Palatino',
            label: 'Palatino'
        },
        {
            value: 'Perpetua',
            label: 'Perpetua'
        },
        {
            value: 'Roman',
            label: 'Roman'
        },
        {
            value: 'Roboto',
            label: 'Roboto'
        },
        {
            value: 'Sans-serif',
            label: 'Sans-serif'
        },
        {
            value: 'Serif',
            label: 'Serif'
        },
        {
            value: 'Script',
            label: 'Script'
        },
        {
            value: 'Swiss',
            label: 'Swiss'
        },
        {
            value: 'Tahoma',
            label: 'Tahoma'
        },
        {
            value: 'Times',
            label: 'Times'
        },
        {
            value: 'Times New Roman',
            label: 'Times New Roman'
        },
        {
            value: 'Tw Cen MT',
            label: 'Tw Cen MT'
        },
        {
            value: 'Verdana',
            label: 'Verdana'
        }
    ];
    return fonts;
}
export function EventReportingExceptions() {
    return [
        {
            type: 'API',
            term: '/SendPrice'
        },
        {
            type: 'Error',
            term: 'Network Error'
        },
        {
            type: 'Error',
            term: 'Request aborted'
        }
    ]
}
export function getJobName(intl) {
    const name = [
        {
            value: "ScheduleTasks.jobs.CheckBookingReservationsJob",
            label: intl.formatMessage({ id: "ScheduleTasks.CheckBookingReservationsJob" }),
            description: intl.formatMessage({ id: "ScheduleTasks.CheckBookingReservationsJobDescription" })
        },
        {
            value: "ScheduleTasks.jobs.CheckDEdgeReservationsJob",
            label: intl.formatMessage({ id: "ScheduleTasks.CheckDEdgeReservationsJob" }),
            description: intl.formatMessage({ id: "ScheduleTasks.CheckDEdgeReservationsJobDescription" })
        },
        {
            value: "ScheduleTasks.jobs.CheckReservations.CheckSiteminderReservationsJob",
            label: intl.formatMessage({ id: "ScheduleTasks.CheckSiteminderReservationsJob" }),
            description: intl.formatMessage({ id: "ScheduleTasks.CheckSiteminderReservationsJobDescription" })
        },
        {
            value: 'ScheduleTasks.jobs.CheckReservations.CheckSingleReservationJob',
            label: intl.formatMessage({ id: "ScheduleTasks.CheckSingleReservationJob" }),
            description: intl.formatMessage({ id: "ScheduleTasks.CheckSingleReservationJobDescription" })
        },
        {
            value: "ScheduleTasks.jobs.CheckReservations.CheckExpediaReservationJob",
            label: intl.formatMessage({ id: "ScheduleTasks.CheckExpediaReservationJob" }),
            description: intl.formatMessage({ id: "ScheduleTasks.CheckExpediaReservationJobDescription" })
        },
        {
            value: "ScheduleTasks.jobs.CheckiCallReservationsJob",
            label: intl.formatMessage({ id: "ScheduleTasks.CheckiCallReservationsJob" }),
            description: intl.formatMessage({ id: "ScheduleTasks.CheckiCallReservationsJobDescription" })
        },
        {
            value: 'ScheduleTasks.jobs.CheckReservations.CheckDingusReservationJob',
            label: intl.formatMessage({ id: "ScheduleTasks.CheckDingusReservationJob" }),
            description: intl.formatMessage({ id: "ScheduleTasks.CheckDingusReservationJobDescription" })
        },
        {
            value: "ScheduleTasks.jobs.CheckReservations.CheckParatyTechReservationJob",
            label: intl.formatMessage({ id: "ScheduleTasks.CheckParatyTechReservationJob" }),
            description: intl.formatMessage({ id: "ScheduleTasks.CheckParatyTechReservationJobDescription" })
        },
        {
            value: "ScheduleTasks.jobs.CheckReservations.CheckVerticalReservationJob",
            label: intl.formatMessage({ id: "ScheduleTasks.CheckVerticalReservationJob" }),
            description: intl.formatMessage({ id: "ScheduleTasks.CheckVerticalReservationJobDescription" })
        },
        {
            value: "ScheduleTasks.jobs.AutomatedValidationsJob",
            label: intl.formatMessage({ id: "ScheduleTasks.AutomatedValidationsJob" }),
            description: intl.formatMessage({ id: "ScheduleTasks.AutomatedValidationsJobDescription" })
        },
        {
            value: 'ScheduleTasks.jobs.SendPricesAvailabilityRestrictions',
            label: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictions" }),
            description: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsDescription" })
        },
        {
            value: "ScheduleTasks.jobs.SendPricesAvailabilityRestrictionsAirbnb",
            label: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsAirbnb" }),
            description: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsAirbnbDescription" })
        },
        {
            value: "ScheduleTasks.jobs.SendPricesAvailabilityRestrictionsHostelWorld",
            label: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsHostelWorld" }),
            description: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsHostelWorldDescription" })
        },
        {
            value: 'ScheduleTasks.jobs.SendPricesAvailabilityRestrictionsBooking',
            label: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsBooking" }),
            description: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsBookingDescription" })
        },
        {
            value: "ScheduleTasks.jobs.SendPricesAvailabilityRestrictionsDingus",
            label: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsDingus" }),
            description: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsDingusDescription" })
        },
        {
            value: "ScheduleTasks.jobs.SendPricesAvailabilityRestrictionsExpedia",
            label: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsExpedia" }),
            description: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsExpediaDescription" })
        },
        {
            value: "ScheduleTasks.jobs.SendPricesAvailabilityRestrictionsHotelBeds",
            label: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsHotelBeds" }),
            description: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsHotelBedsDescription" })
        },
        {
            value: 'ScheduleTasks.jobs.SendPricesAvailabilityRestrictionsAvailPro',
            label: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsAvailPro" }),
            description: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsAvailProDescription" })
        },
        {
            value: "ScheduleTasks.jobs.SendPricesAvailabilityRestrictionsAvailProOdd",
            label: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsAvailProOdd" }),
            description: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsAvailProOddDescription" })
        },
        {
            value: "ScheduleTasks.jobs.SendPricesAvailabilityRestrictionsSynxis",
            label: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsSynxis" }),
            description: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsSynxisDescription" })
        },
        {
            value: 'ScheduleTasks.jobs.SendPricesAvailabilityRestrictionsYieldPlanet',
            label: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsYieldPlanet" }),
            description: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsYieldPlanetDescription" })
        },
        {
            value: "ScheduleTasks.jobs.SendPricesAvailabilityRestrictionsJumbotours",
            label: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsJumbotours" }),
            description: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsJumbotoursDescription" })
        },
        {
            value: "ScheduleTasks.jobs.SendPricesAvailabilityRestrictionsJuniper",
            label: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsJuniper" }),
            description: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsJuniperDescription" })
        },
        {
            value: "ScheduleTasks.jobs.SendPricesAvailabilityRestrictionsRoomCloud",
            label: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsRoomCloud" }),
            description: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsRoomCloudDescription" })
        },
        {
            value: 'ScheduleTasks.jobs.SendPricesAvailabilityRestrictionsSiteminder',
            label: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsSiteminder" }),
            description: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsSiteminderDescription" })
        },
        {
            value: "ScheduleTasks.jobs.SendPricesAvailabilityRestrictionsSiteminderOdd",
            label: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsSiteminderOdd" }),
            description: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsSiteminderOddDescription" })
        },
        {
            value: "ScheduleTasks.jobs.SendPricesAvailabilityRestrictionsHotusa",
            label: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsHotusa" }),
            description: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsHotusaDescription" })
        },
        {
            value: 'ScheduleTasks.jobs.SendPricesAvailabilityRestrictionsHrs',
            label: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsHrs" }),
            description: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsHrsDescription" })
        },
        {
            value: "ScheduleTasks.jobs.SendPricesAvailabilityRestrictionsAbreu",
            label: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsAbreu" }),
            description: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsAbreuDescription" })
        },
        {
            value: "ScheduleTasks.jobs.SendPricesAvailabilityRestrictionsTravelClick",
            label: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsTravelClick" }),
            description: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsTravelClickDescription" })
        },
        {
            value: "ScheduleTasks.jobs.SendPricesAvailabilityRestrictionsGoogleHeyBe",
            label: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsGoogleHeyBe" }),
            description: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsGoogleHeyBeDescription" })
        },
        {
            value: 'ScheduleTasks.jobs.SendPricesAvailabilityRestrictionsMTSGlobe',
            label: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsMTSGlobe" }),
            description: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsMTSGlobeDescription" })
        },
        {
            value: "ScheduleTasks.jobs.SendPricesAvailabilityRestrictionsHotelSpider",
            label: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsHotelSpider" }),
            description: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsHotelSpiderDescription" })
        },
        {
            value: "ScheduleTasks.jobs.SendPricesAvailabilityRestrictionsTravelGateX",
            label: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsTravelGateX" }),
            description: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsTravelGateXDescription" })
        },
        {
            value: 'ScheduleTasks.jobs.SendPricesAvailabilityRestrictionsCTrip',
            label: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsCTrip" }),
            description: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsCTripDescription" })
        },

        {
            value: "ScheduleTasks.jobs.SendPricesAvailabilityRestrictionsParatTech",
            label: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsParatTech" }),
            description: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsParatTechDescription" })
        },
        {
            value: "ScheduleTasks.jobs.SendReservationsToPmsJob",
            label: intl.formatMessage({ id: "ScheduleTasks.SendReservationsToPmsJob" }),
            description: intl.formatMessage({ id: "ScheduleTasks.SendReservationsToPmsJobDescription" })
        },
        {
            value: 'ScheduleTasks.jobs.RetentionPolicies.RetentionPolicyJob',
            label: intl.formatMessage({ id: "ScheduleTasks.RetentionPolicyJob" }),
            description: intl.formatMessage({ id: "ScheduleTasks.RetentionPolicyJobDescription" })
        },
        {
            value: "ScheduleTasks.jobs.DailyReportJob",
            label: intl.formatMessage({ id: "ScheduleTasks.DailyReportJob" }),
            description: intl.formatMessage({ id: "ScheduleTasks.DailyReportJobDescription" })
        },
        {
            value: "ScheduleTasks.jobs.CheckSendPricesAvailabilityProcessStatusJob",
            label: intl.formatMessage({ id: "ScheduleTasks.CheckSendPricesAvailabilityProcessStatusJob" }),
            description: intl.formatMessage({ id: "ScheduleTasks.CheckSendPricesAvailabilityProcessStatusJobDescription" })
        },
        {
            value: 'ScheduleTasks.jobs.ApplyStopSalesJob',
            label: intl.formatMessage({ id: "ScheduleTasks.ApplyStopSalesJob" }),
            description: intl.formatMessage({ id: "ScheduleTasks.ApplyStopSalesJobDescription" })
        },
        {
            value: 'ScheduleTasks.jobs.SendPricesAvailabilityRestrictionsGuestCentric',
            label: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsGuestCentric" }),
            description: intl.formatMessage({ id: "ScheduleTasks.SendPricesAvailabilityRestrictionsGuestCentricDescription" })
        },
        {
            value: "ScheduleTasks.jobs.UpdateLastSendDateForPriceAvailabilityRestrictionsJob",
            label: intl.formatMessage({ id: "ScheduleTasks.UpdateLastSendDateForPriceAvailabilityRestrictionsJob" }),
            description: intl.formatMessage({ id: "ScheduleTasks.UpdateLastSendDateForPriceAvailabilityRestrictionsJobDescription" })
        },
        {
            value: "ScheduleTasks.jobs.CancelAbandonedReservationsJob",
            label: intl.formatMessage({ id: "ScheduleTasks.CancelAbandonedReservationsJob" }),
            description: intl.formatMessage({ id: "ScheduleTasks.CancelAbandonedReservationsJobDescription" })
        },
        {
            value: "ScheduleTasks.jobs.MonitorScheduleJob",
            label: intl.formatMessage({ id: "ScheduleTasks.MonitorScheduleJob" }),
            description: intl.formatMessage({ id: "ScheduleTasks.MonitorScheduleJobDescription" })
        },
        {
            value: 'ScheduleTasks.jobs.CheckCurrencyRateJob',
            label: intl.formatMessage({ id: "ScheduleTasks.CheckCurrencyRateJob" }),
            description: intl.formatMessage({ id: "ScheduleTasks.CheckCurrencyRateJobDescription" })
        },
        {
            value: "ScheduleTasks.jobs.LowInventoryNotificationJob",
            label: intl.formatMessage({ id: "ScheduleTasks.LowInventoryNotificationJob" }),
            description: intl.formatMessage({ id: "ScheduleTasks.LowInventoryNotificationJobDescription" })
        }
    ];
    return name;
};

export function getBeTemplates() {
    const beTemplates = [
        {
            value: 'Blue',
            label: <FormattedMessage id="ComplexClassification.Blue" />,
            colors: [
                {
                    hex: '#4174F8',
                    description: <FormattedMessage id="ReferenceDataFunctions.MainColor" />
                },
                {
                    hex: '#07294C',
                    description: <FormattedMessage id="ReferenceDataFunctions.Text" />
                },
                {
                    hex: '#d9e3fe',
                    description: <FormattedMessage id="ReferenceDataFunctions.Secondary" />
                },
                {
                    hex: '#FF8686',
                    description: <FormattedMessage id="ReferenceDataFunctions.Warning" />
                },
                {
                    hex: '#FFBE7E',
                    description: <FormattedMessage id="ReferenceDataFunctions.Buttons" />
                },
                {
                    hex: '#ffbe7e',
                    description: <FormattedMessage id="ReferenceDataFunctions.SecondaryButtons" />
                }
            ],
            gradients: [
                {
                    hex: 'linear-gradient(48deg, #BAF0FC 0%, #6DCCF7 100%)',
                    description: <FormattedMessage id="ReferenceDataFunctions.Gradient" />
                },
                {
                    hex: 'linear-gradient(81deg, #8BE9FE 0%, #3AC0FC 100%)',
                    description: <FormattedMessage id="ReferenceDataFunctions.SecondGrandient" />
                }
            ]
        },
        {
            value: 'DarkMode',
            label: <FormattedMessage id="ComplexClassification.Dark" />,
            colors: [
                {
                    hex: '#B19664',
                    description: <FormattedMessage id="ReferenceDataFunctions.MainColor" />
                },
                {
                    hex: '#0d0d0d',
                    description: <FormattedMessage id="ReferenceDataFunctions.Background1" />
                },
                {
                    hex: '#d3d3d3',
                    description: <FormattedMessage id="ReferenceDataFunctions.Text" />
                },
                {
                    hex: '#BAA46B',
                    description: <FormattedMessage id="ReferenceDataFunctions.Secondary" />
                },
                {
                    hex: '#cb0c1c',
                    description: <FormattedMessage id="ReferenceDataFunctions.Warning" />
                },
                {
                    hex: '#d0bd7e',
                    description: <FormattedMessage id="ReferenceDataFunctions.Buttons" />
                },
                {
                    hex: '#6c6c6c55',
                    description: <FormattedMessage id="ReferenceDataFunctions.SecondaryButtons" />
                }
            ],
            gradients: [
                {
                    hex: 'linear-gradient(48deg, #414141 0%, #D0BD7E 100%)',
                    description: <FormattedMessage id="ReferenceDataFunctions.Gradient" />
                },
                {
                    hex: 'linear-gradient(71deg, #D0BD7E 0%, #9E693D 100%)',
                    description: <FormattedMessage id="ReferenceDataFunctions.SecondGrandient" />
                }
            ]
        },
        {
            value: 'Essenza',
            label: <FormattedMessage id="ComplexClassification.Essenza" />,
            colors: [
                {
                    hex: '#D0BD7E',
                    description: <FormattedMessage id="ReferenceDataFunctions.MainColor" />
                },
                {
                    hex: '#0A0909',
                    description: <FormattedMessage id="ReferenceDataFunctions.Text" />
                },
                {
                    hex: '#f6f2e5',
                    description: <FormattedMessage id="ReferenceDataFunctions.Secondary" />
                },
                {
                    hex: '#A80C19',
                    description: <FormattedMessage id="ReferenceDataFunctions.Warning" />
                },
                {
                    hex: '#9E693D',
                    description: <FormattedMessage id="ReferenceDataFunctions.Buttons" />
                },
                {
                    hex: '#d0bd7e',
                    description: <FormattedMessage id="ReferenceDataFunctions.SecondaryButtons" />
                }
            ],
            gradients: [
                {
                    hex: 'linear-gradient(48deg, #414141 0%, #D0BD7E 100%)',
                    description: <FormattedMessage id="ReferenceDataFunctions.Gradient" />
                },
                {
                    hex: 'linear-gradient(71deg, #D0BD7E 0%, #9E693D 100%)',
                    description: <FormattedMessage id="ReferenceDataFunctions.SecondGrandient" />
                }
            ]
        },
        {
            value: 'RiverSide',
            label: <FormattedMessage id="ComplexClassification.RiverSide" />,
            colors: [
                {
                    hex: '#25A18E',
                    description: <FormattedMessage id="ReferenceDataFunctions.MainColor" />
                },
                {
                    hex: '#222B29',
                    description: <FormattedMessage id="ReferenceDataFunctions.Text" />
                },
                {
                    hex: '#d3ece8',
                    description: <FormattedMessage id="ReferenceDataFunctions.Secondary" />
                },
                {
                    hex: '#F46262',
                    description: <FormattedMessage id="ReferenceDataFunctions.Warning" />
                },
                {
                    hex: '#63D5D0',
                    description: <FormattedMessage id="ReferenceDataFunctions.Buttons" />
                },
                {
                    hex: '#63d5d0',
                    description: <FormattedMessage id="ReferenceDataFunctions.SecondaryButtons" />
                }
            ],
            gradients: [
                {
                    hex: 'linear-gradient(228deg, rgba(37, 161, 142, 1) 0%, rgba(24, 71, 106, 1) 100%)',
                    description: <FormattedMessage id="ReferenceDataFunctions.Gradient" />
                },
                {
                    hex: 'linear-gradient(81deg, rgba(99, 213, 208, 1) 0%, rgba(37, 161, 142, 1) 100%)',
                    description: <FormattedMessage id="ReferenceDataFunctions.SecondGrandient" />
                }
            ]
        },
        {
            value: 'Summer',
            label: <FormattedMessage id="ComplexClassification.Summer" />,
            colors: [
                {
                    hex: '#3AC0FC',
                    description: <FormattedMessage id="ReferenceDataFunctions.MainColor" />
                },
                {
                    hex: '#424C50',
                    description: < FormattedMessage id="ReferenceDataFunctions.Text" />
                },
                {
                    hex: '#8BE9FE',
                    description: <FormattedMessage id="ReferenceDataFunctions.Secondary" />
                },
                {
                    hex: '#FF8686',
                    description: <FormattedMessage id="ReferenceDataFunctions.Warning" />
                },
                {
                    hex: '#FFBE7E',
                    description: <FormattedMessage id="ReferenceDataFunctions.Buttons" />
                },
                {
                    hex: '#ffbe7e',
                    description: <FormattedMessage id="ReferenceDataFunctions.SecondaryButtons" />
                }
            ],
            gradients: [
                {
                    hex: 'linear-gradient(48deg, #BAF0FC 0%, #6DCCF7 100%)',
                    description: <FormattedMessage id="ReferenceDataFunctions.Gradient" />
                },
                {
                    hex: 'linear-gradient(81deg, #8BE9FE 0%, #3AC0FC 100%)',
                    description: <FormattedMessage id="ReferenceDataFunctions.SecondGrandient" />
                }
            ]
        },
        {
            value: 'Sienna',
            label: <FormattedMessage id="ComplexClassification.Sienna" />,
            colors: [
                {
                    hex: '#495057',
                    description: <FormattedMessage id="ReferenceDataFunctions.MainColor" />
                },
                {
                    hex: '#222B29',
                    description: <FormattedMessage id="ReferenceDataFunctions.Text" />
                },
                {
                    hex: '#F8F9FA',
                    description: <FormattedMessage id="ReferenceDataFunctions.Secondary" />
                },
                {
                    hex: '#F46262',
                    description: <FormattedMessage id="ReferenceDataFunctions.Warning" />
                },
                {
                    hex: '#6C757D',
                    description: <FormattedMessage id="ReferenceDataFunctions.Buttons" />
                },
                {
                    hex: 'rgba(73, 80, 87, 0.2)',
                    description: <FormattedMessage id="ReferenceDataFunctions.SecondaryButtons" />
                }
            ],
            gradients: [
                {
                    hex: 'transparent linear-gradient(228deg, rgba(248, 249, 259, 1) 30%, rgba(222, 226, 230, 1) 100%) 0% 0% no-repeat padding-box',
                    description: <FormattedMessage id="ReferenceDataFunctions.Gradient" />
                },
                {
                    hex: 'transparent linear-gradient(81deg, rgba(173, 181, 189, 1) 0%, rgba(108, 117, 125, 1) 100%) 0% 0% no-repeat padding-box',
                    description: <FormattedMessage id="ReferenceDataFunctions.SecondGrandient" />
                }
            ]
        },
        {
            value: 'Umbral',
            label: <FormattedMessage id="ComplexClassification.Umbral" />,
            colors: [
                {
                    hex: '#2A3175',
                    description: <FormattedMessage id="ReferenceDataFunctions.MainColor" />
                },
                {
                    hex: '#404041',
                    description: <FormattedMessage id="ReferenceDataFunctions.Text" />
                },
                {
                    hex: '#E6E7E8',
                    description: <FormattedMessage id="ReferenceDataFunctions.Secondary" />
                },
                {
                    hex: '#EA525C',
                    description: <FormattedMessage id="ReferenceDataFunctions.Warning" />
                },
                {
                    hex: '#00ADB6',
                    description: <FormattedMessage id="ReferenceDataFunctions.Buttons" />
                },
                {
                    hex: '92D5E0',
                    description: <FormattedMessage id="ReferenceDataFunctions.SecondaryButtons" />
                }
            ],
            gradients: [
                {
                    hex: 'transparent linear-gradient(228deg, #92D5E0 30%, #00ADB6 100%) 0% 0% no-repeat padding-box',
                    description: <FormattedMessage id="ReferenceDataFunctions.Gradient" />
                },
                {
                    hex: 'transparent linear-gradient(81deg, #E6E7E8 0%, #92D5E0 100%) 0% 0% no-repeat padding-box',
                    description: <FormattedMessage id="ReferenceDataFunctions.SecondGrandient" />
                }
            ]
        }
    ];
    return beTemplates;
}

//If hotel's timezone is configured, converts the date received to that timezone, if not, it uses UTC +00:00
export function getConvertedDateToHotelTimezone(date, hasTimeZone = false) {
    date = hasTimeZone ? date : date + '+00:00';

    var convertedDate = date ? global.hotel && global.hotel.hotelTimezone ? moment(date).tz(global.hotel.hotelTimezone) : moment(date).utc() : '';

    return convertedDate;
};

export function getBookingsForSameDayOptions() {
    const bookingsForSameDayOptions = [
        {
            value: 'Yes',
            label: <FormattedMessage id="BookingsForSameDay.Yes" />
        },
        {
            value: 'WithLimitations',
            label: <FormattedMessage id="BookingsForSameDay.WithLimitations" />
        },
        {
            value: 'No',
            label: <FormattedMessage id="BookingsForSameDay.No" />
        }
    ];

    return bookingsForSameDayOptions;
}

export function getQueueTypesOptions() {
    const queueTypes = [
        {
            value: true,
            label: 'Inbound'
        },
        {
            value: false,
            label: 'Outbound'
        }
    ];
    return queueTypes;
}

export function getBELangs() {
    return [
        {
            value: 'en',
            label: 'English'
        },
        {
            value: 'pt',
            label: 'Português'
        },
        {
            value: 'pl',
            label: 'Polski'
        },
        {
            value: 'fr',
            label: 'Français'
        },
        {
            value: 'de',
            label: 'Deutsch'
        },
        {
            value: 'es',
            label: 'Castellano'
        },
        {
            value: 'it',
            label: 'Italiano'
        }
    ];
}
export function getLanguages() {
    return [
        { value: 'en-GB', label: <FormattedMessage id="generic.en-GB" /> },
        { value: 'pt-PT', label: <FormattedMessage id="generic.pt-PT" /> },
        { value: 'fr-FR', label: <FormattedMessage id="generic.fr-FR" /> },
        { value: 'es-ES', label: <FormattedMessage id="generic.es-ES" /> },
        { value: 'de-DE', label: <FormattedMessage id="generic.de-DE" /> },
        { value: 'it-IT', label: <FormattedMessage id="generic.it-IT" /> },
        { value: 'pl-PL', label: <FormattedMessage id="generic.pl-PL" /> }
    ];
}

export function getChannelsLoadDataFromFile() {
    const channelsLoadDataFromFile = [
        'HOTELREZ',
        'SABRE',
        'SABREENTERPRISE',
        'SITEMINDER',
        'SYNXIS',
        'RATEGAIN',
        'TRAVELCLICK',
        'OMNIBEES',
        'RATETIGER',
        "B2BRESERVAS",
        "SHRGROUP"
    ];
    return channelsLoadDataFromFile;
}

export function getChannelsWithSingleRoomRestriction() {
    const channelsWithSingleRoomRestriction = [
        'BEDS24',
        'HOMEAWAY',
        'SAPO'
    ];
    return channelsWithSingleRoomRestriction;
}

export function getChannelsReadOnly() {
    const channelsReadOnly = [
        'GOOGLEHOTELADS'
    ];
    return channelsReadOnly;
}

export function getIsNotSapo(code) {
    return code !== "SAPO" && code !== "HEYBE";
}

export function getIsHostelWorld(code) {
    return code === "HOSTELWORLD";
}

export function getHasAvailabilityGroupMapping(code) {
    const channelsWithAvailabilityGroupMapping = [
        'DINGUS',
        'EDREAMS',
        'FASTPAYHOTELS',
        'HOTUSA',
        'INTERRIAS',
        'KEYTEL',
        'LOGITRAVEL',
        'LOVEHOLIDAYS',
        'MTSGLOBE',
        'ROIBACK',
        'SIDETOURS',
        'SOLFERIAS',
        'TOUR10',
        'VIAJESOLYMPIA',
        'SYNERGY',
        'VIAJESELCORTEINGLES',
        'CNTRAVEL',
        'IESCAPE',
        'BRITISHAIRWAYS',
        'TRAVELREPUBLIC',
        'GUESTINCOMING',
        'TOPWORLDHOTEL'
    ];
    return channelsWithAvailabilityGroupMapping.find(c => code === c) !== undefined;
}

export function getHasContract(code) {
    const contractChannels = [
        'JUMBOTOURS',
        'HOTELBEDS'
    ]
    return contractChannels.concat(getJuniperChannels()).some(c => c === code);
}

export function getHasChannelData(code) {
    const channelDataChannels = [
        'DOUBLELAND',
        'HOST',
        'HEYTRAVEL',
        'HEYBE',
        'GOOGLEHOTELADS',
        'HOMEAWAY'
    ];
    return channelDataChannels.some(c => c === code);
}

export function getApplyDifferencesAsDiscounts(code) {
    const channelDifferencesAsDiscountsChannels = [
        'DOUBLELAND',
        'AVAILPRO',
        'DEDGE',
        'SITEMINDER'
    ];
    return channelDifferencesAsDiscountsChannels.some(c => c === code);
}

export function getIsChannelReadOnly(code) {
    return getChannelsReadOnly().find(chn => chn === code) !== undefined;
}

export function getTravelGateXChannels() {
    const travelGateXChannels = [
        'LOGITRAVEL',
        'TRAVELGATEX',
        'INTERRIAS',
        'LOVEHOLIDAYS',
        'TOUR10',
        'VIAJESOLYMPIA',
        'VIAJESELCORTEINGLES',
        'CNTRAVEL',
        'IESCAPE',
        'BRITISHAIRWAYS',
        'GUESTINCOMING',
        'TOPWORLDHOTEL'
    ];

    return travelGateXChannels;
}

export function getJuniperChannels() {
    const juniperChannels = [
        'JUNIPER',
        'ECTRAVEL',
        'ONTRAVELSOLUTIONS',
        'PORTIMAR',
        'ROIBOS',
        'SOLFERIAS',
        'TOPATLANTICO',
        'WORLD2MEET',
        'CVC',
        'JET2HOLIDAYS'
    ];
    return juniperChannels;
}

export function getIsJuniper(code) {
    return getJuniperChannels().some(c => c === code);
}

export function getIsHrs(code) {
    return code === "HRS";
}

export function getIsJumboTours(code) {
    return code === "JUMBOTOURS";
}

export function getShowInterfaceMode4(code) {
    const channels = [
        'KEYTEL',
        'HOTUSA',
        'SABRE',
        'SABREENTERPRISE',
        "AVORIS"
    ];

    return channels.some(c => c === code);
}

export function getShowInterfaceMode6(code) {
    return getTravelGateXChannels().some(c => c === code);
}

export function getIsHotelBeds(code) {
    return code === "HOTELBEDS";
}

export function hasImportListings(code) {
    const channelConfig = getChannelConfig(code);

    return channelConfig && channelConfig.Actions.some(el => el === 'importListings');
}

export function getPercentage(dayAvailability, isHost) {
    if (dayAvailability) {
        return getPercentageValue(isHost, dayAvailability.freeRooms, dayAvailability.inventory, dayAvailability.outOfInventory, dayAvailability.outOfOrder);
    }
}

export function getPercentageDynamic(dayAvailability, isHost, dayAvailabilityField) {
    if (dayAvailability) {
        return getPercentageValue(isHost, dayAvailability[dayAvailabilityField.freeRooms], dayAvailability[dayAvailabilityField.inventory], dayAvailability[dayAvailabilityField.outOfInventory], dayAvailability[dayAvailabilityField.outOfOrder]);
    }
}

function getPercentageValue(isHost, freeRooms, inventory, outOfInventory, outOfOrder) {
    if (isHost) {
        if (inventory > 0) {
            const inventoryVal = inventory - outOfInventory - outOfOrder;
            const freeRoomsVal = freeRooms < 0 ? inventoryVal : inventoryVal - freeRooms;
            return (parseInt((freeRoomsVal / inventoryVal) * 100));
        }
        else {
            return 100;
        }
    }
}

export function getPmsTypes() {
    const pmsTypes = [
        {
            value: 'Host',
            label: <FormattedMessage id="HotelLicenses.Host" />
        },
        {
            value: 'None',
            label: <FormattedMessage id="HotelLicenses.None" />
        },
        {
            value: 'BookingSpaceBeds',
            label: <FormattedMessage id="HotelLicenses.BookingSpaceBeds" />
        },
        {
            value: 'NewHotel',
            label: "NewHotel"
        }
    ];

    return pmsTypes;
}

export function getRmsTypes() {
    const rmsTypes = [
        {
            value: 'None',
            label: <FormattedMessage id="HotelLicenses.None" />
        },
        {
            value: 'Xlr8',
            label: <FormattedMessage id="HotelLicenses.Xlr8" />
        },
        {
            value: 'Climber',
            label: <FormattedMessage id="HotelLicenses.Climber" />
        }
    ];

    return rmsTypes;
}

export const priceMarkupIncludePCOptions = [
    { value: false, label: <FormattedMessage id="EditChannelMapping.Rate" /> },
    { value: true, label: <FormattedMessage id="EditChannelMapping.Rate&PriceComponent" /> }
];

export const getBookingEngines = [
    {
        value: 'GOOGLEHOTELADS',
        label: <img alt="" src={logoGoogle} height="15px" />,
        name: 'Google'
    }
];


export function getMonths(intl) {
    return [
        { value: 1, label: intl.formatMessage({ id: "ReferenceDataFunctions.January" }) },
        { value: 2, label: intl.formatMessage({ id: "ReferenceDataFunctions.February" }) },
        { value: 3, label: intl.formatMessage({ id: "ReferenceDataFunctions.March" }) },
        { value: 4, label: intl.formatMessage({ id: "ReferenceDataFunctions.April" }) },
        { value: 5, label: intl.formatMessage({ id: "ReferenceDataFunctions.May" }) },
        { value: 6, label: intl.formatMessage({ id: "ReferenceDataFunctions.June" }) },
        { value: 7, label: intl.formatMessage({ id: "ReferenceDataFunctions.July" }) },
        { value: 8, label: intl.formatMessage({ id: "ReferenceDataFunctions.August" }) },
        { value: 9, label: intl.formatMessage({ id: "ReferenceDataFunctions.September" }) },
        { value: 10, label: intl.formatMessage({ id: "ReferenceDataFunctions.October" }) },
        { value: 11, label: intl.formatMessage({ id: "ReferenceDataFunctions.November" }) },
        { value: 12, label: intl.formatMessage({ id: "ReferenceDataFunctions.December" }) }
    ]
};

export function getDependecyOrderTypes() {
    return ['amount', 'percentage'];
}


//#region Mail Extractor
export function getExtractorType(intl) {
    return [
        { value: 'Template', label: intl.formatMessage({ id: "ReferenceDataFunctions.Template" }) },
        { value: 'Code', label: intl.formatMessage({ id: "ReferenceDataFunctions.Code" }) }
    ]
};


export function getExtractorLinesField() {
    //colocar por ordem e por nivel
    return [
        { value: 'resIdValue', label: "Reservation Id Value" },
        { value: 'resIdDate', label: "Reservation Id Date" },
        { value: 'state', label: "State" },
        { value: 'totalBooking', label: "Total Booking" },
        //Room
        { value: 'room', label: "Room" },
        { value: 'roomCheckIn', label: "Room Check-in", dependent: 'room' },
        { value: 'roomCheckOut', label: "RoomCheckOut", dependent: 'room' },
        { value: 'roomType', label: "Room Type", dependent: 'room' },
        { value: 'totalNights', label: "Total Nights", dependent: 'room' },
        { value: 'guestCount', label: "Guest Count", dependent: 'room' },
        { value: 'adults', label: "Adults", dependent: 'room' },
        { value: 'mealPlan', label: "Meal Plan", dependent: 'room' },
        { value: 'rateCode', label: "Rate Code", dependent: 'room' },
        { value: 'contract', label: "Contract", dependent: 'room' },
        { value: 'currencyCode', label: "Currency Code", dependent: 'room' },
        //Guests
        { value: 'guests', label: "Guests" },
        { value: 'guestsFirstNames', label: "Guests First Names", dependent: 'guests' },
        { value: 'guestsLastNames', label: "Guests Last Names", dependent: 'guests' }
    ]
};


export function getExtractorLinesType(intl) {
    return [
        { value: 'List', label: intl.formatMessage({ id: "ReferenceDataFunctions.List" }) },
        { value: 'Value', label: intl.formatMessage({ id: "ReferenceDataFunctions.Value" }) }
    ]
};

//#endregion

//#region Chat GPT
export function getToneOptions() {
    return [
        { label: 'Funny', value: "Funny" },
        { label: 'Casual', value: "Casual" },
        { label: 'Neutral', value: "Neutral" },
        { label: 'Professional', value: "Professional" },
    ]
};

//#endregion

//#region
const mimeTypes = {
    "pdf": "application/pdf",
    "jpeg": "image/jpeg",
    "jpg": "image/jpeg",
    "gif": "image/gif"
}

export function getMimeTypeFromExtension(extension) {
    return mimeTypes[extension]
}
//#endregion