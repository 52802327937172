import React, { Component, useState } from 'react';
import { Row, Col, Button, Card, CardHeader, CardBody, Input, Label, CustomInput, Modal } from 'reactstrap';
import { EmptyCard } from '../../Base/Common/CommonUIComponents';
import { FormattedMessage, injectIntl } from 'react-intl';
import { handleNotification } from '../../Base/Notification';
import { postAPI } from '../../Base/API';
import BlockUi from 'react-block-ui';
import { ErrorAlert } from "../../Common/ErrorAlert";
import CustomSelect from '../../Base/Common/CustomSelect';
import Authorization from '../../Base/Authorization';

export class ChannelConfigDetail extends Component {

    constructor(props) {
        super(props);
        this.addUrl = this.addUrl.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleBoolean = this.handleBoolean.bind(this);
        this.channelConfigForm = React.createRef();
        this.state = {
            channelConfig: this.props.modalData,
            url: null,
            appSecretModal: false,
            supports: [
                {
                    value: 'StopSales',
                    label: <FormattedMessage id="ChannelConfigDetail.StopSales" />
                },
                {
                    value: 'Price',
                    label: <FormattedMessage id="ChannelConfigDetail.Price" />
                },
                {
                    value: 'Availability',
                    label: <FormattedMessage id="ChannelConfigDetail.Availability" />
                },
                {
                    value: 'MinStays',
                    label: <FormattedMessage id="ChannelConfigDetail.MinStays" />
                },
                {
                    value: 'MaxStays',
                    label: <FormattedMessage id="ChannelConfigDetail.MaxStays" />
                },
                {
                    value: 'CTA',
                    label: <FormattedMessage id="ChannelConfigDetail.CTA" />
                },
                {
                    value: 'CTD',
                    label: <FormattedMessage id="ChannelConfigDetail.CTD" />
                },
                {
                    value: 'MinAdvancedBookingOffset',
                    label: <FormattedMessage id="ChannelConfigDetail.MinAdvancedBookingOffset" />
                },
                {
                    value: 'MaxAdvancedBookingOffset',
                    label: <FormattedMessage id="ChannelConfigDetail.MaxAdvancedBookingOffset" />
                }
            ]
        }
    }

    handleFieldChange = (name, evt) => {
        const value = evt.target ? evt.target.value : null;
        this.setState(prevState => (
            {
                channelConfig: {
                    ...prevState.channelConfig,
                    [name]: value
                }
            })
        );
    }

    handleIntFieldChange = (name, evt) => {
        const value = evt.target ? evt.target.value : null;
        this.setState(prevState => (
            {
                channelConfig: {
                    ...prevState.channelConfig,
                    [name]: parseInt(value)
                }
            })
        );
    }

    handleComboBoxFieldChange = (name, combo) => {
        const value = combo ? combo.value : null;
        this.setState({
            channelConfig: {
                ...this.state.channelConfig,
                [name]: value
            }
        });
    }

    addUrl() {
        const name = this.state.url;
        this.setState(prevState => (
            {
                url: null,
                channelConfig: {
                    ...prevState.channelConfig,
                    [name]: ''
                }
            })
        );
    }

    handleSave() {
        this.setState({ block: true });
        const limitMaxStays = document.getElementById('limitMaxStays');

        if (this.state.channelConfig.limitMaxStays > this.state.channelConfig.maxDays) {
            limitMaxStays.setCustomValidity(this.props.intl.formatMessage({ id: "ChannelConfigDetail.LimitMaxStaysBiggerThanMaxDays" }));
        }
        else {
            limitMaxStays.setCustomValidity("");
        }

        if (this.channelConfigForm.current.reportValidity()) {
            const body = {
                request: this.state.channelConfig
            };
            postAPI(result => {
                const { data, error, isLoading } = result;
                this.setState({ block: isLoading });
                const errorMessage = [];
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState(({ block: false, error: errorMessage }));
                        return;
                    }
                    if (data.response) {
                        handleNotification(data, <FormattedMessage id="ChannelConfigDetail.SaveMessageSuccess" />, <FormattedMessage id="General.Success" />);
                        if (data.response.supported === null) {
                            data.response.supported = [];
                        }
                        this.props.addOrUpdateChannelConfig(data.response);
                        this.setState(({ channelConfig: data.response, block: false }));
                    }
                }
                this.setState(({ block: false, error: errorMessage }));
            }, '/api/Rate/ChannelConfig/v1/ChannelConfig', JSON.stringify(body));
        }

        this.setState(({ block: false }));
    }

    handleUrlChange(name, combo) {
        const value = combo ? combo.value : null;
        this.setState(({ [name]: value }));
    }

    handleBoolean(evt) {
        if (evt.target) {
            const { channelConfig } = this.state;
            const name = evt.target.name;
            channelConfig[name] = evt.target.checked;
            this.setState({ channelConfig });
        }
    }

    handleRestrictionsChange(arr) {
        const { channelConfig } = this.state;
        channelConfig.supported = arr !== null ? arr.map(opt => opt.value) : [];
        this.setState({ channelConfig });
    }

    toggleModal = (modal) => {
        this.setState(prevState => ({
            [modal]: !prevState[modal]
        }));
    }

    render() {
        const { channelConfig, supports, appSecretModal } = this.state;

        const urls = [
            {
                'value': 'urlavailability',
                label: <FormattedMessage id="ChannelConfigDetail.UrlAvailability" />,
                isPresent: this.state.channelConfig && this.state.channelConfig.urlavailability
            },
            {
                'value': 'urlprices',
                label: <FormattedMessage id="ChannelConfigDetail.UrlPrices" />,
                isPresent: this.state.channelConfig && this.state.channelConfig.urlprices
            },
            {
                'value': 'urlreservations',
                label: <FormattedMessage id="ChannelConfigDetail.UrlReservations" />,
                isPresent: this.state.channelConfig && this.state.channelConfig.urlreservations
            },
            {
                'value': 'urlreservationsModif',
                label: <FormattedMessage id="ChannelConfigDetail.UrlReservationsModif" />,
                isPresent: this.state.channelConfig && this.state.channelConfig.urlreservationsModif
            },
            {
                'value': 'extraUrl1',
                label: <FormattedMessage id="ChannelConfigDetail.UrlExtraUrl1" />,
                isPresent: this.state.channelConfig && this.state.channelConfig.extraUrl1
            },
            {
                'value': 'extraUrl2',
                label: <FormattedMessage id="ChannelConfigDetail.UrlExtraUrl2" />,
                isPresent: this.state.channelConfig && this.state.channelConfig.extraUrl2
            },
            {
                'value': 'extraUrl3',
                label: <FormattedMessage id="ChannelConfigDetail.UrlExtraUrl3" />,
                isPresent: this.state.channelConfig && this.state.channelConfig.extraUrl3
            }
        ];

        const internalChannelOptions = [
            {
                value: true,
                label: <FormattedMessage id="HotelLicenses.True" />
            },
            {
                value: false,
                label: <FormattedMessage id="HotelLicenses.False" />
            }
        ];

        return (
            <BlockUi tag="div" blocking={this.state.block}>
                <ErrorAlert error={this.state.error} />
                <Card className="shadow border-0 h-100">
                    <CardHeader>
                        <FormattedMessage id="ChannelConfigDetail.Intro" />
                        <strong>
                            {this.props.modalData && this.props.modalData.code}
                        </strong>

                        <Authorization
                            perform="channelConfigDetail:save"
                            yes={() => (
                                <>
                                    <Button color=" btn-host btn-sm float-right" onClick={this.handleSave}>
                                        <i className="fas fa-save" />
                                    </Button>

                                    <Button color=" btn-host btn-sm float-right mr-2" onClick={_ => this.toggleModal('appSecretModal')}>
                                        <i className="fas fa-key" />
                                    </Button>
                                </>
                            )}
                            no={() => <div></div>}
                        />
                    </CardHeader>
                    <CardBody>
                        <form ref={this.channelConfigForm}>
                            {this.state.channelConfig && this.state.channelConfig.id ?
                                <Row className="pt-2">
                                    <Col className="col-2">
                                        <Label for="code">
                                            PMS
                                        </Label>
                                    </Col>
                                    <Col className="col-4">
                                        <Input
                                            type="text" className="text-sm"
                                            value={this.state.channelConfig.pmsId ? this.state.channelConfig.pmsId : ''}
                                            readOnly
                                        />
                                    </Col>
                                </Row>
                            : ''}
                            <Row className="pt-2">
                                <Col className="col-2">
                                    <Label for="code">
                                        <FormattedMessage id="ChannelConfigDetail.Code" />
                                    </Label>
                                </Col>
                                <Col className="col-4">
                                    <FormattedMessage id="ChannelConfigDetail.Code">
                                        {
                                            placeholder => <Input type="Text" name="code" maxLength="20" className="text-sm" required placeholder={placeholder} value={this.state.channelConfig && this.state.channelConfig.code ? this.state.channelConfig.code : ''} onChange={this.handleFieldChange.bind(this, 'code')} />
                                        }
                                    </FormattedMessage>
                                </Col>
                                <Col className="col-2">
                                    <Label for="name">
                                        <FormattedMessage id="ChannelConfigDetail.Name" />
                                    </Label>
                                </Col>
                                <Col className="col-4">
                                    <FormattedMessage id="ChannelConfigDetail.Name">
                                        {
                                            placeholder => <Input type="Text" name="name" className="text-sm" maxLength="100" placeholder={placeholder} value={this.state.channelConfig && this.state.channelConfig.name ? this.state.channelConfig.name : ''} onChange={this.handleFieldChange.bind(this, 'name')} />
                                        }
                                    </FormattedMessage>
                                </Col>
                            </Row>
                            <Row className="pt-2">
                                <Col className="col-2">
                                    <Label for="appId" >
                                        <FormattedMessage id="ChannelConfigDetail.AppId" />
                                    </Label>
                                </Col>
                                <Col className="col-4">
                                    <FormattedMessage id="ChannelConfigDetail.AppId">
                                        {
                                            placeholder => <Input type="Text" name="appId" className="text-sm " maxLength="100" placeholder={placeholder} value={this.state.channelConfig && this.state.channelConfig.appId ? this.state.channelConfig.appId : ''} onChange={this.handleFieldChange.bind(this, 'appId')} />
                                        }
                                    </FormattedMessage>
                                </Col>
                            </Row>
                            <Row className="pt-2">                                
                                <Col className="col-2">
                                    <Label for="source">
                                        <FormattedMessage id="ChannelConfigDetail.Source" />
                                    </Label>
                                </Col>
                                <Col className="col-4">
                                    <FormattedMessage id="ChannelConfigDetail.Source">
                                        {
                                            placeholder => <Input type="Text" name="source" className="text-sm " maxLength="20" placeholder={placeholder} value={this.state.channelConfig && this.state.channelConfig.source ? this.state.channelConfig.source : ''} onChange={this.handleFieldChange.bind(this, 'source')} />
                                        }
                                    </FormattedMessage>
                                </Col>
                                <Col className="col-2">
                                    <Label for="internalChannel">
                                        <FormattedMessage id="ChannelConfigDetail.InternalChannel" />
                                    </Label>
                                </Col>
                                <Col className="col-4">
                                    <CustomSelect name="internalChannel" options={internalChannelOptions} onChange={this.handleComboBoxFieldChange.bind(this, 'internalChannel')} value={internalChannelOptions.find(option => option.value == (this.state.channelConfig && this.state.channelConfig.internalChannel))} placeholder={<FormattedMessage id="ChannelConfigDetail.InternalChannel" />} isClearable required />
                                </Col>
                            </Row>
                            <Row className="pt-2">
                                <Col className="col-2">
                                    <Label for="maxDays">
                                        <FormattedMessage id="ChannelConfigDetail.MaxDays" />
                                    </Label>
                                </Col>
                                <Col className="col-4">
                                    <FormattedMessage id="ChannelConfigDetail.MaxDays">
                                        {
                                            placeholder => <Input type="number" name="maxDays" className="text-sm " placeholder={placeholder} value={this.state.channelConfig && this.state.channelConfig.maxDays} onChange={this.handleIntFieldChange.bind(this, 'maxDays')} />
                                        }
                                    </FormattedMessage>
                                </Col>
                                <Col className="col-2">
                                    <Label for="maxDays">
                                        <FormattedMessage id="ChannelConfigDetail.LimitMaxStays" />
                                    </Label>
                                </Col>
                                <Col className="col-4">
                                    <FormattedMessage id="ChannelConfigDetail.LimitMaxStays">
                                        {
                                            placeholder => <Input type="number" min="0" id="limitMaxStays" name="limitMaxStays" className="text-sm " placeholder={placeholder} value={this.state.channelConfig && this.state.channelConfig.limitMaxStays} onChange={this.handleIntFieldChange.bind(this, 'limitMaxStays')} />
                                        }
                                    </FormattedMessage>
                                </Col>
                            </Row>
                            <Row className="pt-2 align-items-center">
                                <Col className="col-2">
                                    <Label for="maxDays">
                                        <FormattedMessage id="ChannelConfigDetail.Restrictions" />
                                    </Label>
                                </Col>
                                <Col className="col-4">
                                    <CustomSelect
                                        options={supports}
                                        isMulti={true}
                                        onChange={this.handleRestrictionsChange.bind(this)}
                                        value={supports.filter(opt => channelConfig.supported.find(s => opt.value === s) !== undefined)}
                                        placeholder={<FormattedMessage id="ChannelConfigDetail.Restrictions" />}
                                    />
                                </Col>
                                <Col className="col-2 pb-2">
                                    <CustomInput
                                        type="checkbox"
                                        name="isCreatable"
                                        id="isCreatable"
                                        bsSize="sm"
                                        className="text-sm"
                                        label={<FormattedMessage id="ManageChannelConfig.IsCreatable" />}
                                        checked={channelConfig.isCreatable}
                                        onChange={this.handleBoolean}
                                    />
                                </Col>
                                <Col className="d-flex">
                                    <div className="pb-2 pr-3">
                                        <CustomInput
                                            type="checkbox"
                                            name="isIcal"
                                            id="isIcal"
                                            bsSize="sm"
                                            className="text-sm"
                                            label={<FormattedMessage id="ManageChannelConfig.IsICal" />}
                                            checked={channelConfig.isIcal}
                                            onChange={this.handleBoolean}
                                        />
                                    </div>
                                    <div className="pb-2 pl-3">
                                        <CustomInput
                                            type="checkbox"
                                            name="hasRoomLevelRestrictions"
                                            id="hasRoomLevelRestrictions"
                                            bsSize="sm"
                                            className="text-sm"
                                            label={<FormattedMessage id="ManageChannelConfig.HasRoomLevelRestrictions" />}
                                            checked={channelConfig.hasRoomLevelRestrictions}
                                            onChange={this.handleBoolean}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <div className="mb-4"></div>
                            <Row className="pt-2 align-items-center">
                                <Col className="col-2">
                                    <Label for="urls">
                                        <b>  <FormattedMessage id="ChannelConfigDetail.Urls" /></b>
                                    </Label>
                                </Col>
                                <Col sm={4}>
                                    <CustomSelect name="url" options={urls.filter(url => !url.isPresent)} onChange={this.handleUrlChange.bind(this, 'url')} placeholder={<FormattedMessage id="ChannelConfigDetail.Urls" />} value={urls.find(url => url.value == this.state.url)} isClearable />
                                </Col>
                                <Col sm={2}>
                                    <Button color=" btn-host btn-sm" onClick={this.addUrl} disabled={!this.state.url}>
                                        <i className="fas fa-plus" />
                                    </Button>
                                </Col>

                                <Col>
                                    <CustomInput
                                        type="checkbox"
                                        name="overrideUrls"
                                        id="overrideUrls"
                                        bsSize="sm"
                                        className="text-sm"
                                        label={"Override Url"}
                                        checked={channelConfig.overrideUrls}
                                        onChange={this.handleBoolean}
                                    />
                                </Col>
                            </Row>

                            <hr />
                            {
                                this.state.channelConfig && this.state.channelConfig.urlavailability != null ?
                                    <Row className="pt-2">
                                        <Col className="col-2">
                                            <Label for="urlavailability">
                                                <FormattedMessage id="ChannelConfigDetail.UrlAvailability" />
                                            </Label>
                                        </Col>
                                        <Col>
                                            <FormattedMessage id="ChannelConfigDetail.UrlAvailability">
                                                {
                                                    placeholder => <Input type="Text" name="urlavailability" className="text-sm" maxLength="200" placeholder={placeholder} value={this.state.channelConfig.urlavailability} onChange={this.handleFieldChange.bind(this, 'urlavailability')} />
                                                }
                                            </FormattedMessage>
                                        </Col>
                                    </Row>
                                    :
                                    <div/>
                            }
                            {
                                this.state.channelConfig && this.state.channelConfig.urlprices != null ?
                                    <Row className="pt-2">
                                        <Col className="col-2">
                                            <Label for="urlprices">
                                                <FormattedMessage id="ChannelConfigDetail.UrlPrices" />
                                            </Label>
                                        </Col>
                                        <Col>
                                            <FormattedMessage id="ChannelConfigDetail.UrlPrices">
                                                {
                                                    placeholder => <Input type="Text" name="urlprices" className="text-sm" maxLength="200" placeholder={placeholder} value={this.state.channelConfig.urlprices} onChange={this.handleFieldChange.bind(this, 'urlprices')} />
                                                }
                                            </FormattedMessage>
                                        </Col>
                                    </Row>
                                    :
                                    <div/>
                            }
                            {
                                this.state.channelConfig && this.state.channelConfig.urlreservations != null ?
                                    <Row className="pt-2">
                                        <Col className="col-2">
                                            <Label for="urlreservations">
                                                <FormattedMessage id="ChannelConfigDetail.UrlReservations" />
                                            </Label>
                                        </Col>
                                        <Col>
                                            <FormattedMessage id="ChannelConfigDetail.UrlReservations">
                                                {
                                                    placeholder => <Input type="Text" name="urlreservations" className="text-sm" maxLength="200" placeholder={placeholder} value={this.state.channelConfig.urlreservations} onChange={this.handleFieldChange.bind(this, 'urlreservations')} />
                                                }
                                            </FormattedMessage>
                                        </Col>
                                    </Row>
                                    :
                                    <div/>
                            }
                            {
                                this.state.channelConfig && this.state.channelConfig.urlreservationsModif != null ?
                                    <Row className="pt-2">
                                        <Col className="col-2">
                                            <Label for="urlreservationsModif">
                                                <FormattedMessage id="ChannelConfigDetail.UrlReservationsModif" />
                                            </Label>
                                        </Col>
                                        <Col>
                                            <FormattedMessage id="ChannelConfigDetail.UrlReservationsModif">
                                                {
                                                    placeholder => <Input type="Text" name="urlreservationsModif" className="text-sm" maxLength="200" placeholder={placeholder} value={this.state.channelConfig.urlreservationsModif} onChange={this.handleFieldChange.bind(this, 'urlreservationsModif')} />
                                                }
                                            </FormattedMessage>
                                        </Col>
                                    </Row>
                                    :
                                    <div/>
                            }
                            {
                                this.state.channelConfig && this.state.channelConfig.extraUrl1 != null ?
                                    <Row className="pt-2">
                                        <Col className="col-2">
                                            <Label for="extraUrl1">
                                                <FormattedMessage id="ChannelConfigDetail.UrlExtraUrl1" />
                                            </Label>
                                        </Col>
                                        <Col>
                                            <FormattedMessage id="ChannelConfigDetail.UrlExtraUrl1">
                                                {
                                                    placeholder => <Input type="Text" name="extraUrl1" className="text-sm" maxLength="200" placeholder={placeholder} value={this.state.channelConfig.extraUrl1} onChange={this.handleFieldChange.bind(this, 'extraUrl1')} />
                                                }
                                            </FormattedMessage>
                                        </Col>
                                    </Row>
                                    :
                                    <div/>
                            }
                            {
                                this.state.channelConfig && this.state.channelConfig.extraUrl2 != null ?
                                    <Row className="pt-2">
                                        <Col className="col-2">
                                            <Label for="extraUrl2">
                                                <FormattedMessage id="ChannelConfigDetail.UrlExtraUrl2" />
                                            </Label>
                                        </Col>
                                        <Col>
                                            <FormattedMessage id="ChannelConfigDetail.UrlExtraUrl2">
                                                {
                                                    placeholder => <Input type="Text" name="extraUrl2" className="text-sm" maxLength="200" placeholder={placeholder} value={this.state.channelConfig.extraUrl2} onChange={this.handleFieldChange.bind(this, 'extraUrl2')} />
                                                }
                                            </FormattedMessage>
                                        </Col>
                                    </Row>
                                    :
                                    <div/>
                            }
                            {
                                this.state.channelConfig && this.state.channelConfig.extraUrl3 != null ?
                                    <Row className="pt-2">
                                        <Col className="col-2">
                                            <Label for="extraUrl3">
                                                <FormattedMessage id="ChannelConfigDetail.UrlExtraUrl3" />
                                            </Label>
                                        </Col>
                                        <Col>
                                            <FormattedMessage id="ChannelConfigDetail.UrlExtraUrl3">
                                                {
                                                    placeholder => <Input type="Text" name="extraUrl3" className="text-sm" maxLength="200" placeholder={placeholder} value={this.state.channelConfig.extraUrl3} onChange={this.handleFieldChange.bind(this, 'extraUrl3')} />
                                                }
                                            </FormattedMessage>
                                        </Col>
                                    </Row>
                                    :
                                    <div />
                            }
                        </form>
                    </CardBody>
                </Card>

                {appSecretModal ?
                    <SetAppSecret
                        modal={appSecretModal}
                        toggleModal={_ => this.toggleModal('appSecretModal')}
                        channelConfigId={channelConfig.id}
                    />
                : ''}
            </BlockUi>                
        )
    }
}
export default injectIntl(ChannelConfigDetail);


const SetAppSecret = ({ channelConfigId, toggleModal, modal }) => {
    const [appSecret, setAppSecret] = useState('');
    const [block, setBlock] = useState();
    const [error, setError] = useState();

    const updateAppSecret = () => {
        setBlock(true);

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
            }
            if (data?.errors && data.errors.length > 0) {
                handleNotification(data);
            }
            else if (data?.response.length > 0 && data.response[0] === 'success') {
                handleNotification(data, <FormattedMessage id="ChannelConfigDetail.AppSecretSaved" />, <FormattedMessage id="General.Success" />);
                toggleModal();
            }

            setError(errorMessage);
            setBlock(false);
        }, `/api/Rate/ChannelConfig/v1/${channelConfigId}/AppSecret`, appSecret);
    }

    return (
        <Modal isOpen={modal} toggle={toggleModal} fade={false} className="modal-md" body style={{ minWidth: '0' }}>
            <EmptyCard error={error} block={block}>
                <Row className="mb-3">
                    <Col>
                        <h5><FormattedMessage id="ChannelConfigDetail.SetAppSecret" /></h5>
                    </Col>
                    <Col>
                        <Button color=" btn-host btn-sm float-right" onClick={updateAppSecret}>
                            <i className="fas fa-save" />
                        </Button>
                    </Col>
                </Row>

                <Label>
                    <FormattedMessage id="ChannelConfigDetail.AppSecret" />
                </Label>
                <FormattedMessage id="ChannelConfigDetail.AppSecret">
                    {
                        placeholder =>
                            <Input
                                type="Text"
                                name="appSecret"
                                className="text-sm "
                                maxLength="100"
                                placeholder={placeholder}
                                value={appSecret || ''}
                                onChange={e => setAppSecret(e.target?.value)}
                            />
                    }
                </FormattedMessage>
            </EmptyCard>
        </Modal>
    )
}