import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Label, Input, Row, Col, Button, Card, CardHeader, CardBody, Form, CustomInput, FormText } from 'reactstrap';
import { postAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';
import { ErrorAlert } from '../Common/ErrorAlert';
import BlockUi from 'react-block-ui';
import CustomSelect from '../Base/Common/CustomSelect';

class ChannelInstanceAdminConfig extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            block: false,
            excludedSourcesOptions: [{ value: '12447', label: 'Roiback (12447)' }],
            config: {
                clientUrl: this.props.selectedChannel?.clientUrl || null,
                migrationChannel: this.props.selectedChannel?.migrationChannel,
                sendOverbookingAvailability: this.props.selectedChannel?.sendOverbookingAvailability,
                excludeReservationSources: this.props.selectedChannel?.excludeReservationSources
            }
        };
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;

    }

    saveAdminConfig = (evt) => {
        evt.preventDefault();
        this.setState({ block: true });

        const body = {...this.state.config}

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ block: false, error: errorMessage }));
                }
                else if (data.response) {
                    handleNotification(data, <FormattedMessage id="ChannelInstanceAdminConfig.ConfigSaved" />, <FormattedMessage id="General.Success" />);
                    this.setState(({ block: false, error: errorMessage }));
                }
            }
            else {
                this.setState(({ block: false, error: errorMessage }));
            }
        }, `/api/Rate/ChannelConfig/v1/ChannelInstance/${this.props.selectedChannel.id}/AdminConfig`, JSON.stringify(body));
    }

    handleChange = (evt) => {
        const { name, value } = evt.target;

        this.setState(prevState => ({
            config: {
                ...prevState.config,
                [name]: value
            }
        }));
    }

	handleSwitch = (evt) => {
		const { name, checked } = evt.target;

        this.setState(prevState => ({
            config: {
                ...prevState.config,
                [name]: checked
            }
        }));
    }

    handleSelect = (name, combo) => {
        this.setState(prevState => ({
            config: {
                ...prevState.config,
                [name]: combo ? combo.map(el => el.value) : null
            }
        }));
    }

    render() {
        const { config, error, block, excludedSourcesOptions } = this.state;

        return (
            <BlockUi tag="div" blocking={block}>
                <Form onSubmit={this.saveAdminConfig}>
                    <Card className="shadow border-0">
                        <ErrorAlert error={error} />
                        <CardHeader>
                            <Row>
                                <Col>
                                    <i className="fas fa-cog mr-2" />
                                    <FormattedMessage id="ChannelConfig.AdminConfig" />
                                </Col>
                                <Col>
                                    <Button className="btn-host btn-sm float-right" type="submit">
                                        <i className="fas fa-save" />
                                    </Button>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Row className="pt-2">
                                <Col className="col-3">
                                    <Label for="urlavailability">
                                        <FormattedMessage id="ChannelInstanceAdminConfig.ClientUrl" />
                                    </Label>
                                </Col>
                                <Col>
                                    <Input
                                        type="text"
                                        name="clientUrl"
                                        maxLength="200"
                                        value={config.clientUrl || ''}
                                        onChange={(e) => this.handleChange(e)}
                                    />
                                </Col>
                            </Row>
                            {this.props.selectedChannel.code === "JUMBOTOURS" || this.props.selectedChannel.code === "AGODA" || this.props.selectedChannel.code === "BOOKING"  ?
                            
                                <Row className="pt-2">
                                    <Col className="col-3">

                                    </Col>
                                    <Col>
                                        <FormText >
                                            <FormattedMessage id={`ChannelInstanceAdminConfig.${this.props.selectedChannel.code}Version`} />
                                        </FormText>
                                    </Col>
                                </Row>
                                : ''
                            }
                            {this.props.selectedChannel.code === "AVAILPRO" ?
                                <Row className="pt-2">
                                    <Col className="col-3">
                                        <Label >
                                            <FormattedMessage id="ChannelInstanceAdminConfig.ExcludeReservationSources" />
                                        </Label>
                                    </Col>
                                    <Col>
                                        <CustomSelect
                                            placeholder=""
                                            options={excludedSourcesOptions}
                                            onChange={(e) => this.handleSelect('excludeReservationSources', e)}
                                            value={config.excludeReservationSources ? excludedSourcesOptions.filter(opt => config.excludeReservationSources.some(ers => ers === opt.value)): ''}
                                            isMulti
                                        />
                                    </Col>
                                </Row>
                            : ''}
                            <Row className="pt-2 align-items-center">
                                <Col className="col-3 pr-0">
                                    <Label for="migrationChannel">
                                        <FormattedMessage id="ChannelInstanceAdminConfig.migrationChannel"/>
                                    </Label>
                                </Col>
                                <Col>
                                    <CustomInput
										name="migrationChannel"
										type="switch"
										id="migrationChannel"
										checked={config.migrationChannel}
										onChange={(e) => this.handleSwitch(e)}
                                    />
                                </Col>
                                <Col className="col-3 pr-0">
                                    <Label for="sendOverbookingAvailability">
                                        <FormattedMessage id="ChannelInstanceAdminConfig.sendOverbookingAvailability"/>
                                    </Label>
                                </Col>
                                <Col>
                                    <CustomInput
										name="sendOverbookingAvailability"
										type="switch"
										id="sendOverbookingAvailability"
										checked={config.sendOverbookingAvailability}
										onChange={(e) => this.handleSwitch(e)}
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Form>
            </BlockUi>
        )
    }
}

export default injectIntl(ChannelInstanceAdminConfig);