import React, { Component } from 'react';
import { Col, FormGroup, Input, Label } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import CustomSelect from '../Base/Common/CustomSelect';

export class RenderPaybyrdForm extends Component {

    render() {
        const { paymentGateway, handleData, idx, mbValidatyDaysOptions } = this.props;

        return (
            <div>
                <FormGroup row>
                    <Label sm={2}>
                        <FormattedMessage id="PaymentGateway.ApiKey" />
                    </Label>
                    <Col sm={10}>
                        <Input type="text" maxLength="100" name="accessToken" id="accessToken" value={paymentGateway.accessToken ? paymentGateway.accessToken : ''} onChange={evt => handleData(evt, idx)} required />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label sm={2} className="pr-0">
                        <FormattedMessage id="PaymentGateway.MbValidityDays" />
                    </Label>
                    <Col sm={10}>
                        <CustomSelect options={mbValidatyDaysOptions} value={mbValidatyDaysOptions.find(opt => opt.value === 1)} isDisabled="true" />
                    </Col>
                </FormGroup>
            </div>
        );
    }
}