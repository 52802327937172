import React, { Component } from 'react';
import { ListGroup, ListGroupItem, FormGroup, Row, Col, Label, InputGroup, Card, Input, Button, ButtonGroup, Form } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { StyledCard } from '../../Base/Common/CommonUIComponents'
import CustomSelect from '../../Base/Common/CustomSelect';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import { getAPI, postAPI } from "../../Base/API";
import { handleNotification } from "../../Base/Notification"
import BlockUi from 'react-block-ui';
import { ErrorAlert } from '../../Common/ErrorAlert';

export class AddRule extends Component {    

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.getTypeValues = this.getTypeValues.bind(this);
        this.onClickWeekDays = this.onClickWeekDays.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.selectAllRates = this.selectAllRates.bind(this);
        this.selectAllChannels = this.selectAllChannels.bind(this);
        this.stepOneForm = React.createRef();
        this.state = {
            block: true,
            CurrentStep: '1',
            yieldRule: this.props.yieldRule,
            channelInstances: [],
            channelInstancesOptions: [],
            rates: [],
            rateCodeChannels: [],
            Steps: [
                { step: '1', active: true, enabled: true },
                { step: '2', active: false, enabled: false },
                { step: '3', active: false, enabled: false },
                { step: '4', active: false, enabled: false }
            ],
            channelInstanceIds: [],
            heyBeAndGoogleChannels: [],
            validYieldRulesTypes: [],
            labels: [],
            hideLabels: true,
        };
    }    

    componentDidMount() {
        let validYieldRulesTypes = [...this.props.validYieldRulesTypes];

        if (global.operationMode === "PmsFull") {
            validYieldRulesTypes = validYieldRulesTypes.filter(el => el.value !== 'Release Days');
        }
        if (global.operationMode === 'None') {
            validYieldRulesTypes = validYieldRulesTypes.filter(el => el.value !== 'Price Override By Complex Availability');
        }

        this.setState({ validYieldRulesTypes });


        if (this.props.yieldRule.dateFrom && this.props.yieldRule.dateTo) {
            //deixar de usar os campos dateFrom e dateTo e passá-los para o array de datas
            const yieldRule = { ...this.state.yieldRule };

            yieldRule.yieldRuleDates.push({ dateFrom: yieldRule.dateFrom, dateTo: yieldRule.dateTo });

            yieldRule.dateFrom = null;
            yieldRule.dateTo = null;

            this.setState({ yieldRule }, this.getRateCodeChannels)
        }
        else {
            this.getRateCodeChannels();
        }
    }

    getRateCodeChannels = () => {

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ block: false, error: errorMessage }, this.getData);
                return;
            }
            if (data && data.response && data.response.length) {
                const rateCodeChannels = data.response.filter(item => item.active).map(item => {
                    const rcc = {
                        id: item.id,
                        channelInstanceId: item.channelInstanceId,
                        rateCodeId: item.rateCodeId
                    };
                    return rcc;
                });
                this.setState({ block: false, rateCodeChannels: rateCodeChannels }, this.getData);
            }
            else {
                this.setState({ block: false }, this.getData);
            }
        }, `/api/Rate/RateCodeChannel/v1`)
    }

    getData = () => {
        this.getRates();
    }

    getRates = () => {
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ block: false, error: errorMessage }, this.getChannels);
                return;
            }
            if (data && data.response && data.response.length > 0) {
                const { rateCodeChannels } = this.state;
                const combo = [];
                data.response.filter(item => item.active && rateCodeChannels.find(rcc => rcc.rateCodeId === item.id) !== undefined).map(item => combo.push({ 'value': item.id, 'label': item.name, 'labelId': item.labelId }));
                this.setState({ rates: combo }, this.getChannels);
            }
            else {
                this.setState({ block: false }, this.getChannels);
            }
        }, '/api/Rate/Rate/v1/Info');
    }

    getChannels = () => {
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ block: false });
                return;
            }
            if (data) {
                const { rateCodeChannels, yieldRule } = this.state;
                const combo = [], heyBeAndGoogleChannels = [];

                data.filter(item => item.active && rateCodeChannels.find(rcc => rcc.channelInstanceId === item.id) !== undefined)
                    .forEach(item => {
                        combo.push({ 'value': item.id, 'label': `${item.name} (${item.propertyCode})`, code: item.code });

                        if (item.code === 'HEYBE' || item.code === 'GOOGLEHOTELADS') {
                            heyBeAndGoogleChannels.push({ 'code': item.id, 'name': `${item.name} (${item.propertyCode})`});
                        }
                    });

                const rateCodeChannelsFiltered = rateCodeChannels.filter(rcc => yieldRule.rateCodeIds.find(rc => rc.code == rcc.rateCodeId));
                const channelInstancesOptions = combo.filter(item => rateCodeChannelsFiltered.find(rcc => rcc.channelInstanceId === item.value) !== undefined);
                
                this.setState({ block: false, channelInstances: combo, channelInstancesOptions: channelInstancesOptions, heyBeAndGoogleChannels }, () => this.getLabels());
            }
            else {
                this.setState({ block: false }, () => this.getLabels());
            }
        }, '/api/Rate/ChannelConfig/v1/ChannelInstance');
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    next(val) {
        var stateCopy = Object.assign({}, this.state);
        stateCopy.Steps = stateCopy.Steps.slice();
        for (var i = 0; i < stateCopy.Steps.length; i++) {
            if (stateCopy.Steps[i].step === val) {
                stateCopy.Steps[i] = Object.assign({}, stateCopy.Steps[i]);
                stateCopy.Steps[i].active = true;
                stateCopy.Steps[i].enabled = true;
            }
            else {
                stateCopy.Steps[i] = Object.assign({}, stateCopy.Steps[i]);
                stateCopy.Steps[i].active = false;
            }
        }
        this.setState(stateCopy);        
    }

    filterRate = (e) => {
        const { rates } = this.state;
        const selectedLabels = e??[];
        
        const labelsIds = selectedLabels.map(({value}) => value);
        const eligleRates = rates.filter(({labelId}) => labelsIds.includes(labelId));

        this.setState({ selectedLabels }, () => {
            this.setRates(null, eligleRates);
        });
    }

    setFilterState = (name, combo) => {
        this.setState(prevState => ({ yieldRule: { ...prevState.yieldRule, [name]: combo ? combo.value : null } }));
    }

    getTypeValues() {
        const customSelectWidth = '160px';
        if (this.state.yieldRule.ruleType === 'Automatic Closure' ) {
            return <div>
                <Row>
                    <Col>
                        <Form inline>
                            <FormattedMessage id="AddRule.AutomaticClosureMessage" />
                            <Input className="mx-1" type="number" min="0" max="20" name={'availabilityValue'} placeholder="0" value={this.state.yieldRule.availabilityValue} onChange={(e) => this.setState({ yieldRule: { ...this.state.yieldRule, availabilityValue: e.target.value, availabilityType: 'Amount' } })} required />
                            <FormattedMessage id="AddRule.Rooms" />
                        </Form>
                    </Col>
                </Row>                
            </div>;                  
        }
        else if (this.state.yieldRule.ruleType === 'Availability Limit') {
            return <div>
                <Row>
                    <Col>
                        <Form inline>
                            <FormattedMessage id="AddRule.AvailabilityLimitMessage1" />
                            <Input className="mx-1" type="number" min="0" max="20" name={'applyValue'} placeholder="0" value={this.state.yieldRule.applyValue} onChange={(e) => this.setState({ yieldRule: { ...this.state.yieldRule, applyValue: e.target.value, applyType: 'Amount' } })} required />
                            <FormattedMessage id="AddRule.AvailabilityLimitMessage2" />
                            <div style={{ width: customSelectWidth }}>
                                <CustomSelect className="mx-1" options={this.props.validYieldRuleAvailabilityPricingType} isSearchable onChange={this.setFilterState.bind(this, 'applyType')} value={this.props.validYieldRuleAvailabilityPricingType.find(el => el.value === this.state.yieldRule.applyType)} placeholder={<FormattedMessage id="AddRule.ChooseType" />} required /></div>
                            <Input className="mx-1" type="number" min="-500" max="500" name={'availabilityValue'} placeholder="0" value={this.state.yieldRule.availabilityValue} onChange={(e) => this.setState({ yieldRule: { ...this.state.yieldRule, availabilityValue: e.target.value } })} required />
                        </Form>
                    </Col>
                </Row>
            </div>;
        }
        else if (this.state.yieldRule.ruleType === 'Price Change' || this.state.yieldRule.ruleType === 'Price Override By Complex Availability' ) {
            return <div>
                <Row className="mb-1">
                    <Col>
                        <Form inline>
                            {this.state.yieldRule.ruleType === 'Price Change' ?
                                <FormattedMessage id={this.state.yieldRule.availabilityType === "Percent" ? "AddRule.PriceChangeAvailabilityMessage" : 'AddRule.PriceChangeAvailabilityMessageAmount'} />
                                :
                                <FormattedMessage id={this.state.yieldRule.availabilityType === "Percent" ? "AddRule.PriceChangeComplexAvailabilityMessage" : 'AddRule.PriceChangeComplexAvailabilityMessageAmount'} />
                            }
                            <div style={{ width: customSelectWidth }}>
                                <CustomSelect className="mx-1" name={'availabilityType'} options={this.props.validYieldRuleAvailabilityPricingType} isSearchable onChange={this.setFilterState.bind(this, 'availabilityType')} value={this.props.validYieldRuleAvailabilityPricingType.find(el => el.value === this.state.yieldRule.availabilityType)} placeholder={<FormattedMessage id="AddRule.ChooseType" />} required />
                            </div>
                            <Input style={{ width: '100px' }} className="mx-1" type="number" min="-500" max="500" name={'availabilityValue'} placeholder="0" value={this.state.yieldRule.availabilityValue} onChange={(e) => this.setState({ yieldRule: { ...this.state.yieldRule, availabilityValue: e.target.value } })} required />
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form inline>
                            <FormattedMessage id="AddRule.PriceChangeIncrementMessage" />
                            <div style={{ width: customSelectWidth }}>
                                <CustomSelect className="mx-1" name={'applyType'} options={this.props.validYieldRuleAvailabilityPricingType} isSearchable onChange={this.setFilterState.bind(this, 'applyType')} value={this.props.validYieldRuleAvailabilityPricingType.find(el => el.value === this.state.yieldRule.applyType)} placeholder={<FormattedMessage id="AddRule.ChooseType" />} required />
                            </div>
                            <FormattedMessage id="AddRule.Value">
                                {
                                    placeholder => <Input style={{ width: '100px' }} className="mx-1" type="number" min="-500" name={'applyValue'} placeholder={placeholder} value={this.state.yieldRule.applyValue} onChange={(e) => this.setState({ yieldRule: { ...this.state.yieldRule, applyValue: e.target.value } })} required />
                                }
                            </FormattedMessage>
                        </Form>
                    </Col>
                </Row>                
            </div>;
        }
        else if (this.state.yieldRule.ruleType === 'Release Days') {
            return <div>
                <Row className="mb-1">
                    <Col>
                        <Form inline>
                            <FormattedMessage id="AddRule.SellingLimit"/>                            
                            <Input type="number" min="1" max="90" name={'releaseDays'} value={this.state.yieldRule.releaseDays} onChange={(e) => this.setState({ yieldRule: { ...this.state.yieldRule, releaseDays: e.target.value } })} required />
                            <FormattedMessage id="AddRule.BeforeCheckIn" />
                        </Form>
                    </Col>
                </Row>
            </div>;
        }
        else if (this.state.yieldRule.ruleType === 'Price Override') {
            return <div>
                <Row>
                    <Col>
                        <Form inline>
                            <FormattedMessage id="AddRule.PriceOverrideReviewMessage" />
                            <div style={{ width: customSelectWidth }}>
                                <CustomSelect className="mx-1" name={'applyType'} options={this.props.validYieldRuleAvailabilityPricingType} isSearchable onChange={this.setFilterState.bind(this, 'applyType')} value={this.props.validYieldRuleAvailabilityPricingType.find(el => el.value === this.state.yieldRule.applyType)} placeholder={<FormattedMessage id="AddRule.ChooseType" />} required />
                            </div>
                            <FormattedMessage id="AddRule.Value">
                                {
                                    placeholder => <Input style={{ width: '100px' }} className="mx-1" type="number" min="-500" name={'applyValue'} placeholder={placeholder} value={this.state.yieldRule.applyValue} onChange={(e) => this.setState({ yieldRule: { ...this.state.yieldRule, applyValue: e.target.value } })} required />
                                }
                            </FormattedMessage>
                        </Form>
                    </Col>
                </Row>
            </div>;
        }
    }

    setRates(_, combo) {
        const { rateCodeChannels, channelInstances } = this.state;
        const rateCodeIds = combo ? combo.map(item => { const newItem = { code: item.value, name: item.label, labelId: item.labelId }; return newItem; }) : [];

        const rateCodeChannelsFiltered = rateCodeChannels.filter(rcc => rateCodeIds.find(rc => rc.code === rcc.rateCodeId));
        const channelInstancesOptions = channelInstances.filter(item => rateCodeChannelsFiltered.find(rcc => rcc.channelInstanceId === item.value) !== undefined);
        
        this.setState(prevState => ({
            yieldRule: {
                ...prevState.yieldRule,
                rateCodeIds: rateCodeIds
            },
            channelInstancesOptions: channelInstancesOptions
        }));
    }

    setChannels(name, combo, action) {
        if (action) {
            const { heyBeAndGoogleChannels, yieldRule, validYieldRulesTypes } = this.state;
            let restrictRuleTypes = null;

            //heybe and google channels are selected and removed simultaneously
            if (action.action === "select-option") {
                //Add
                if (action.option && heyBeAndGoogleChannels.some(el => el.code === action.option.value)) {
                    yieldRule.channelInstanceIds = yieldRule.channelInstanceIds.concat(heyBeAndGoogleChannels);
                }
                else {
                    yieldRule.channelInstanceIds = combo && combo.map(item => {
                        const newItem = { code: item.value, name: item.label };
                        return newItem;
                    });
                }

                //HEYTRAVEL
                if (action.option.code === "HEYTRAVEL") {
                    restrictRuleTypes = true;
                }
            }
            else {
                //Remove
                if (action.removedValue && heyBeAndGoogleChannels.some(el => el.code === action.removedValue.value)) {
                    yieldRule.channelInstanceIds = yieldRule.channelInstanceIds.filter(ci => !heyBeAndGoogleChannels.some(el => el.code === ci.code));
                }
                else {
                    yieldRule.channelInstanceIds = combo && combo.map(item => {
                        const newItem = { code: item.value, name: item.label };
                        return newItem;
                    });
                }

                //HEYTRAVEL
                if (action.action === "clear" || action.removedValue.code === "HEYTRAVEL") {
                    restrictRuleTypes = false;
                }
            }

            const [yieldRuleUpdated, validYieldRulesTypesUpdated] = this.getValidYieldRulesTypes(yieldRule, validYieldRulesTypes, restrictRuleTypes);

            this.setState({ yieldRule: yieldRuleUpdated, validYieldRulesTypes: validYieldRulesTypesUpdated })
        }
    }

    //HEYTRAVEL only 'Price Change', 'Price Override By Complex Availability' and 'Price Override' are available
    //if restrictRuleTypes is null nothing changes
    getValidYieldRulesTypes = (yieldRule, ruleTypes, restrictRuleTypes) => {
        const heytravelRuleTypes = ["Price Change", "Price Override By Complex Availability", "Price Override"];
        
        if (restrictRuleTypes === true) {
            ruleTypes.forEach(rt => {
                rt.hidden = !heytravelRuleTypes.some(el => el === rt.value);
            })

            if (!heytravelRuleTypes.some(el => el === yieldRule.ruleType)) {
                yieldRule.ruleType = null
            }
        }
        else if (restrictRuleTypes === false) {
            ruleTypes.forEach(rt => rt.hidden = false )
        }

        return [ yieldRule, ruleTypes ];
    }

    onClickWeekDays(weekDay) {
        const weekdays = this.state.yieldRule.weekdays;
        if (weekdays.find(weekday => weekday === weekDay)) {
            const elementsToRemove = 1;
            weekdays.splice(weekdays.indexOf(weekdays.find(item => item === weekDay)), elementsToRemove);
            this.setState(prevState => ({ yieldRule: { ...prevState.yieldRule, weekdays: weekdays } }));
        }
        else {
            weekdays.push(weekDay);
            this.setState(prevState => ({ yieldRule: { ...prevState.yieldRule, weekdays: weekdays } }));
        }
    }

    handleSave() {
        if (this.stepOneForm.current.reportValidity()) {
            this.setState(({ block: true }));

            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ block: false, error: errorMessage });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState(({ block: false, error: errorMessage  }));
                    }
                    else if (data.response) {
                        handleNotification(data, <FormattedMessage id="AddRule.SuccessSave" />, <FormattedMessage id="AddRule.Success" />);
                        
                        if (data.response.active) {
                            handleNotification({ warnings: [{ message: <FormattedMessage id="RuleConfig.FullPushNote" /> }] });
                        }

                        this.setState(({ block: false, error: errorMessage , yieldRule: data.response }));
                        this.props.onSave(data.response);
                    }
                    else {
                        this.setState(({ block: false, error: errorMessage  }));
                    }
                }
                else {
                    this.setState(({ block: false, error: errorMessage  }));
                }
            }, '/api/Rate/YieldRules/v1', JSON.stringify(this.state.yieldRule));
        }
        else {
            this.next('1');
        }
    }

    selectAllRates(evt) {
        if (evt) {
            const { channelInstances, rates, rateCodeChannels, yieldRule } = this.state;

            rates.forEach(rate => {
                if (yieldRule.rateCodeIds.find(rc => rc.code === rate.value) === undefined) {
                    yieldRule.rateCodeIds.push({ code: rate.value, name: rate.label });
                }
            });

            const rateCodeChannelsFiltered = rateCodeChannels.filter(rcc => yieldRule.rateCodeIds.find(rc => rc.code === rcc.rateCodeId));
            const channelInstancesOptions = channelInstances.filter(item => rateCodeChannelsFiltered.find(rcc => rcc.channelInstanceId === item.value) !== undefined);

            this.setState({ yieldRule, channelInstancesOptions: channelInstancesOptions });
        }
    }

    selectAllChannels(evt) {
        if (evt) {
            const { yieldRule, channelInstancesOptions, validYieldRulesTypes } = this.state;
            let restrictRuleTypes = null;

            channelInstancesOptions.forEach(channel => {
                if (yieldRule.channelInstanceIds.find(ci => ci.code === channel.value) === undefined) {
                    yieldRule.channelInstanceIds.push({ code: channel.value, name: channel.label });
                }

                if (channel.code === "HEYTRAVEL") {
                    restrictRuleTypes = true;
                }
            });

            const [yieldRuleUpdated, validYieldRulesTypesUpdated] = this.getValidYieldRulesTypes(yieldRule, validYieldRulesTypes, restrictRuleTypes);

            this.setState({ yieldRule: yieldRuleUpdated, validYieldRulesTypes: validYieldRulesTypesUpdated });
        }
    }

    handleDates = (startDate, endDate, index) => {
        const { yieldRule } = this.state;
        const currentDate = yieldRule.yieldRuleDates[index];

        if (currentDate) {
            if (startDate) {
                currentDate.dateFrom = startDate.format('YYYY-MM-DD');
            }
            if (endDate) {
                currentDate.dateTo = endDate.format('YYYY-MM-DD');
            }


            if (currentDate.id) {
                //Add new rule with updated dates and delete current dates
                delete currentDate.id;
            }

            this.setState({ yieldRule });
        }
    }

    addDate = () => {
        const { yieldRule } = this.state;

        yieldRule.yieldRuleDates.push({});

        this.setState({ yieldRule });
    }

    removeDate = (idx) => {
        const { yieldRule } = this.state;

        yieldRule.yieldRuleDates.splice(idx, 1);

        this.setState({ yieldRule });
    }

    isOutsideRange = (day) => {
        const { yieldRule } = this.state;
        const formatedDay = moment(day).format("YYYY-MM-DD");

        return yieldRule.yieldRuleDates.some((unavailableDay) => {
            if (unavailableDay.dateFrom && unavailableDay.dateTo) {
                const dateFrom = moment(unavailableDay.dateFrom).format("YYYY-MM-DD");
                const dateTo = moment(unavailableDay.dateTo).format("YYYY-MM-DD");

                return moment(formatedDay).isSameOrAfter(dateFrom) && moment(formatedDay).isSameOrBefore(dateTo)
            }
            else {
                return false;
            }
        });
    }

    getLabels() {
        getAPI(result => {
            const { data, error } = result;
            const { rates } = this.state;
            var apiLabels = [];
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ block: false, error: errorMessage });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data.response) {
                    data.response.forEach((item) => {
                        var hasAssociatedRate = rates.find(rate => rate.labelId === item.id);
                        if (hasAssociatedRate) {
                            const label = { 'value': item.id, 'label': item.label };
                            apiLabels.push(label)
                        }
                    })
                    this.setState({ labels: apiLabels, hideLabels: !(apiLabels.length > 0) });
                }
            }
        }, '/api/Rate/Rate/v1/RateLabel');
    }

    render() {
        const { yieldRule, labels, hideLabels } = this.state;

        const validYieldRulesTypes = [...this.state.validYieldRulesTypes].filter(el => el.hidden !== true);


        const validWeekDays = [
            {
                value: "Sun",
                label: <FormattedMessage id="AddRule.Sun" />
            },
            {
                value: "Mon",
                label: <FormattedMessage id="AddRule.Mon" />
            },
            {
                value: "Tue",
                label: <FormattedMessage id="AddRule.Tue" />
            },
            {
                value: "Wed",
                label: <FormattedMessage id="AddRule.Wed" />
            },
            {
                value: "Thu",
                label: <FormattedMessage id="AddRule.Thu" />
            },
            {
                value: "Fri",
                label: <FormattedMessage id="AddRule.Fri" />
            },
            {
                value: "Sat",
                label: <FormattedMessage id="AddRule.Sat" />
            }
        ];
        const yieldRates = this.state.yieldRule.rateCodeIds ? this.state.yieldRule.rateCodeIds.map(rate => rate.code) : [];
        const yieldChannelInstances = this.state.yieldRule.channelInstanceIds ? this.state.yieldRule.channelInstanceIds.map(channelInstance => channelInstance.code) : [];

        return (
            <div>
                <BlockUi tag="div" blocking={this.state.block}>
                <ErrorAlert error={this.state.error} />
                <Card>
                    <Row>
                        <Col className="col-3 pr-0">
                            <ListGroup>
                                <ListGroupItem tag="button" className={'btn ' + (this.state.Steps[0].active === true ? 'active' : '') + (this.state.Steps[0].enabled === true ? ' ' : ' disabled ')} action onClick={() => this.next('1')}><strong><FormattedMessage id="AddRule.Step1" />: </strong><p> <FormattedMessage id="AddRule.ChooseRule" /> </p></ListGroupItem>
                                <ListGroupItem tag="button" className={'btn ' + (this.state.Steps[1].active === true ? 'active' : '') + (this.state.Steps[1].enabled === true ? ' ' : ' disabled ')} action onClick={() => this.next('2')}><strong><FormattedMessage id="AddRule.Step2" />: </strong><p> <FormattedMessage id="AddRule.Limits&Dates" /> </p></ListGroupItem>
                                <ListGroupItem tag="button" className={'btn ' + (this.state.Steps[2].active === true ? 'active' : '') + (this.state.Steps[2].enabled === true ? ' ' : ' disabled ')} action onClick={() => this.next('3')}><strong><FormattedMessage id="AddRule.Step3" />: </strong><p> <FormattedMessage id="RuleConfig.Rules" /> </p></ListGroupItem>
                                <ListGroupItem tag="button" className={'btn ' + (this.state.Steps[3].active === true ? 'active' : '') + (this.state.Steps[3].enabled === true ? ' ' : ' disabled ')} action onClick={() => this.next('4')}><strong><FormattedMessage id="AddRule.Step4" />: </strong><p> <FormattedMessage id="AddRule.Review" /> </p></ListGroupItem>
                            </ListGroup>
                        </Col>
                        <Col className="col-9 pl-0 pt-0 row-eq-height">
                            <div className="border-0 h-100" >                               
                                <Card className="border-0" style={{ display: (this.state.Steps[0].active === true ? 'block' : 'none'), height: '100%' }} >
                                    <StyledCard title="AddRule.Aplicability">
                                        <form ref={this.stepOneForm}>                                           
                                            <Row className="mb-3">
                                                <Col className="col-2">
                                                    <FormattedMessage id="AddRule.RuleName" />
                                                </Col>
                                                <Col className="col-10">
                                                    <InputGroup>
                                                        <FormattedMessage id="AddRule.RuleName">{placeholder => <Input name="name" placeholder={placeholder} value={this.state.yieldRule.name} onChange={(e) => this.setState({ yieldRule: { ...this.state.yieldRule, name: e.target.value } })} required />}</FormattedMessage>
                                                    </InputGroup>
                                                </Col>
                                            </Row>
                                                <Row className="mb-3">
                                                    <Col className="col-2">
                                                        <FormattedMessage id="AddRule.RuleType" />
                                                    </Col>
                                                    <Col className="col-10">
                                                        <CustomSelect name={'ruleType'} options={validYieldRulesTypes} isSearchable onChange={this.setFilterState.bind(this, 'ruleType')} value={validYieldRulesTypes.find(el => el.value === this.state.yieldRule.ruleType) || ''} placeholder={<FormattedMessage id="AddRule.RuleType" />} required />
                                                    </Col>
                                                </Row>
                                                {global.operationMode !== 'PmsFull' && !hideLabels ?
                                                    <Row className="mb-3">
                                                        <Col className="col-2">
                                                            <FormattedMessage id="Inventory.SelectRateLabel" />
                                                        </Col>
                                                        <Col className="col-8 align-items-center">
                                                            <CustomSelect
                                                                isClearable
                                                                isSearchable
                                                                placeholder={<FormattedMessage id="Announcement.ChooseType" />}
                                                                options={labels}
                                                                isMulti={true}
                                                                onChange={evt => this.filterRate(evt)}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    : ''}
                                            <Row className="mb-3">
                                                <Col className="col-2">
                                                    <FormattedMessage id="AddRule.Rate" />
                                                </Col>
                                                <Col className="col-8">
                                                    <BlockUi tag="div" blocking={this.state.block}>
                                                        <CustomSelect name={'rateCodeIds'} options={this.state.rates} isSearchable placeholder={<FormattedMessage id="Announcement.ChooseType" />} value={this.state.rates.filter(rate => yieldRates.find(rt => rt == rate.value) != null)} onChange={this.setRates.bind(this, 'rateCodeIds')} isMulti required={true} />
                                                    </BlockUi>
                                                </Col>
                                                <Col className="col-2">
                                                    <Button className="btn btn-host btn-sm btn-block" style={{ height: "100%" }} onClick={this.selectAllRates}>
                                                        <FormattedMessage id="AddRule.SelectAll" />
                                                    </Button>
                                                </Col>
                                            </Row>
                                            <Row className="mb-5">
                                                <Col className="col-2">
                                                    <FormattedMessage id="AddRule.Channels" />
                                                </Col>
                                                <Col className="col-8">
                                                    <BlockUi tag="div" blocking={this.state.block}>
                                                        <CustomSelect name={'channelInstanceIds'}
                                                            options={this.state.channelInstancesOptions}
                                                            isSearchable
                                                            placeholder={<FormattedMessage id="Announcement.ChooseType" />}
                                                            value={this.state.channelInstances.filter(channelInstance => yieldChannelInstances.find(cInst => cInst == channelInstance.value) != null)}
                                                            onChange={this.setChannels.bind(this, 'channelInstanceIds')}
                                                            isMulti
                                                            required={true}
                                                        />
                                                    </BlockUi>
                                                </Col>
                                                <Col className="col-2">
                                                    <Button className="btn btn-host btn-sm btn-block" style={{ height: "100%" }} onClick={this.selectAllChannels}>
                                                        <FormattedMessage id="AddRule.SelectAll" />
                                                    </Button>
                                                </Col>
                                            </Row>
                                            <Row style={{ position: 'absolute', bottom: '0', width: '100%' }} className="mb-2">
                                                <Col className="text-right">
                                                    <Button color="btn btn-host" onClick={() => this.stepOneForm.current.reportValidity() ? this.next('2') : null}><i className="fas fa-angle-right"/></Button>
                                                </Col>
                                            </Row>
                                        </form>
                                    </StyledCard>
                                </Card>                                
                                <Card className="h-100" style={{ display: (this.state.Steps[1].active === true ? 'block' : 'none') }}>
                                    <StyledCard title={"AddRule.SetRuleLimits"}>                                 
                                        <Row>
                                            <Col className="col-12">
                                                <FormGroup row>
                                                    <Label sm={4}> <FormattedMessage id="AddRule.WeekDays" /> </Label>
                                                    <Col sm={8}>
                                                        <ButtonGroup name="weekdays">
                                                            {
                                                                validWeekDays.map((weekDay, key) =>
                                                                    <Button className={this.state.yieldRule.weekdays && this.state.yieldRule.weekdays.filter(el => el === weekDay.value).length === 0 ? 'btn' : 'btn btn-host'} onClick={() => this.onClickWeekDays(weekDay.value)} key={key}>
                                                                        {weekDay.label}
                                                                    </Button>
                                                                )
                                                            }
                                                        </ButtonGroup>
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row className="mb-4">
                                            <Col sm={4}>
                                                <Label><FormattedMessage id="AddRule.ValidDate"/></Label>
                                            </Col>
                                            <Col sm={7}>
                                                {yieldRule.yieldRuleDates && yieldRule.yieldRuleDates.map((date, idx) =>
                                                    <Row className="mb-1 align-items-center" key={idx}>
                                                        <Col>
                                                            <DateRangePicker
                                                                startDate={date.dateFrom ? moment(date.dateFrom).locale(moment.locale()) : null}
                                                                startDateId={`startDate${idx}`}
                                                                isOutsideRange={day => day <= moment().subtract(1, 'day') || day > moment().add(20, 'year')}
                                                                isDayBlocked={(day) => this.isOutsideRange(day)}
                                                                endDate={date.dateTo ? moment(date.dateTo).locale(moment.locale()) : null}
                                                                endDateId={`endDate${idx}`}
                                                                onDatesChange={({ startDate, endDate }) => this.handleDates(startDate, endDate, idx)}
                                                                focusedInput={this.state[`focusedInput${idx}`]}
                                                                onFocusChange={focusedInput => this.setState(({ [`focusedInput${idx}`]: focusedInput }))}
                                                                small={true}
                                                                numberOfMonths={2}
                                                                minimumNights={0}
                                                                showDefaultInputIcon={true}
                                                                renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <Button color="btn btn-sm btn-host " onClick={() => this.removeDate(idx)}>
                                                                <i className="fas fa-trash-alt" />
                                                            </Button>
                                                        </Col>
                                                    </Row>                                                    
                                                )}     
                                            </Col>
                                            <Col className="text-right col-1">
                                                {yieldRule.yieldRuleDates && yieldRule.yieldRuleDates.length < 15 ?
                                                    <Button color="btn btn-sm btn-host " onClick={this.addDate}>
                                                        <i className="fas fa-plus" />
                                                    </Button>
                                                : ''}
                                            </Col>
                                        </Row>                                        
                                        <Row style={{ position: 'absolute', bottom: '0', width: '100%' }} className="mb-2">
                                            <Col className="col-12">
                                                <Button color="btn btn-host float-left " onClick={() => this.next('1')}><i className="fas fa-angle-left" /></Button>
                                                    <Button color="btn btn-host float-right " onClick={() => this.next('3')} disabled={!yieldRule.yieldRuleDates || yieldRule.yieldRuleDates.length === 0 || yieldRule.yieldRuleDates.some(el => !el.dateFrom || !el.dateTo)}>
                                                        <i className="fas fa-angle-right" />
                                                    </Button>
                                            </Col>
                                        </Row>
                                    </StyledCard>
                                </Card>
                                <Card className="h-100" style={{ display: (this.state.Steps[2].active === true ? 'block' : 'none') }}>
                                    <StyledCard title={"AddRule.DefineRules"}>                                        
                                        <Row>
                                            <Col className="col-12">
                                                {this.getTypeValues()}
                                            </Col>
                                        </Row>
                                        <br/>
                                        <Row style={{ position: 'absolute', bottom: '0', width: '100%' }} className="mb-2">
                                            <Col className="col-12">
                                                <Button color="btn btn-host float-left " onClick={() => this.next('2')}><i className="fas fa-angle-left" /></Button>
                                                <Button color="btn btn-host float-right " onClick={() => this.next('4')}><i className="fas fa-angle-right" /></Button>
                                            </Col>
                                        </Row>
                                    </StyledCard>
                                </Card>
                                <Card className="h-100" style={{ display: (this.state.Steps[3].active === true ? 'block' : 'none') }}>
                                    <StyledCard title={"AddRule.Applicability"}>
                                        <Row>
                                            <Col className="col-11">
                                                <div>
                                                    <FormattedMessage id="AddRule.YieldRuleName"/> <b>{this.state.yieldRule.name}</b> <FormattedMessage id="AddRule.YieldRuleType"/> <b>{this.props.validYieldRulesTypes.find(type => type.value === this.state.yieldRule.ruleType) ? this.props.validYieldRulesTypes.find(type => type.value === this.state.yieldRule.ruleType).label : ''}</b>
                                                </div>
                                            </Col>
                                            <Col className="col-1">
                                                <Button color="btn btn-host float-right " onClick={this.handleSave}>
                                                    <i className="fas fa-save" />
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {yieldRule.yieldRuleDates && yieldRule.yieldRuleDates.map((date, idx) =>
                                                    <div key={idx}>
                                                        <FormattedMessage id="AddRule.YieldRuleFrom" /> <b>{moment(date.dateFrom).format("YYYY-MM-DD")}</b> <FormattedMessage id="AddRule.YieldRuleTo" /> <b>{moment(date.dateTo).format("YYYY-MM-DD")}</b>
                                                    </div>
                                                )}
                                            </Col>
                                            <Col>
                                                    {this.state.yieldRule.weekdays && this.state.yieldRule.weekdays.length > 0 ? <span> <FormattedMessage id="AddRule.YieldRuleOn" /> {this.state.yieldRule.weekdays.map((weekday, idx) => <b key={idx}> {weekday} </b>)} </span> : ''}
                                            </Col>
                                        </Row>
                                        <Row className="my-2">
                                            <Col>
                                                <div>
                                                    {this.state.yieldRule.ruleType === 'Automatic Closure' ? <div><FormattedMessage id="AddRule.AutomaticClosureMessage" /> <b>{this.state.yieldRule.availabilityValue} {this.state.yieldRule.availabilityType == 'Percent' ? '%' : ''}</b> <FormattedMessage id="AddRule.Rooms" /></div> : ''}
                                                    {this.state.yieldRule.ruleType === 'Availability Limit' ? <div><FormattedMessage id="AddRule.AvailabilityLimitMessage1" /> <b>{this.state.yieldRule.applyValue}</b> <FormattedMessage id="AddRule.AvailabilityLimitMessage2" /> <b>{this.state.yieldRule.availabilityValue} {this.state.yieldRule.applyType == 'Percent' ? '%' : ''}</b></div> : ''}
                                                    {this.state.yieldRule.ruleType === 'Price Change' ? <div><FormattedMessage id={this.state.yieldRule.availabilityType === "Percent" ? "AddRule.PriceChangeAvailabilityMessage" : 'AddRule.PriceChangeAvailabilityMessageAmount'} /> <b>{this.state.yieldRule.availabilityValue} {this.state.yieldRule.availabilityType == 'Percent' ? '%' : ''}</b> <FormattedMessage id="AddRule.PriceChangeIncrementMessage" /> <b>{this.state.yieldRule.applyValue} {this.state.yieldRule.applyType == 'Percent' ? '%' : ''}</b></div> : ''}
                                                    {this.state.yieldRule.ruleType === 'Release Days' ? <div><FormattedMessage id="AddRule.SellingLimit" /> <b>{this.state.yieldRule.releaseDays}</b>  <FormattedMessage id="AddRule.BeforeCheckIn" /></div> : ''}
                                                    {this.state.yieldRule.ruleType === 'Price Override' ? <div><FormattedMessage id="AddRule.PriceOverrideReviewMessage" /> <b>{this.state.yieldRule.applyValue} {this.state.yieldRule.applyType == 'Percent' ? '%' : ''}</b></div> : ''}
                                                    {this.state.yieldRule.ruleType === 'Price Override By Complex Availability' ? <div><FormattedMessage id={this.state.yieldRule.availabilityType === "Percent" ? "AddRule.PriceChangeComplexAvailabilityMessage" : 'AddRule.PriceChangeComplexAvailabilityMessageAmount'} /> <b>{this.state.yieldRule.availabilityValue} {this.state.yieldRule.availabilityType == 'Percent' ? '%' : ''}</b> <FormattedMessage id="AddRule.PriceChangeIncrementMessage" /> <b>{this.state.yieldRule.applyValue} {this.state.yieldRule.applyType == 'Percent' ? '%' : ''}</b></div> : ''}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col>
                                                <div>
                                                    <FormattedMessage id="AddRule.YieldRuleRates"/>
                                                        <ul>{this.state.yieldRule.rateCodeIds && this.state.yieldRule.rateCodeIds.map((rate, idx) => <li key={idx}>{rate.name}</li>)}</ul>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div>
                                                    <FormattedMessage id="AddRule.YieldRuleChannels"/>
                                                        <ul>{this.state.yieldRule.channelInstanceIds && this.state.yieldRule.channelInstanceIds.map((cInst, idx) => <li key={idx}>{cInst.name}</li>)}</ul>
                                                </div>
                                            </Col>
                                        </Row>

                                            {this.state.yieldRule.ruleType === "Release Days" ?
                                                <Row className="align-items-center mb-5">
                                                    <Col>
                                                        <i className="fas fa-info-circle mr-2" style={{ color: 'orange' }} /><FormattedMessage id="AddRule.ReleaseDaysNote" />
                                                    </Col>
                                                </Row>
                                            : ''}

                                        <Row style={{ position: 'absolute', bottom: '0', width: '100%' }} className="mb-2">
                                            <Col>
                                                <Button color="btn btn-host float-left " onClick={() => this.next('3')}><i className="fas fa-angle-left" /></Button>
                                            </Col>
                                        </Row>
                                    </StyledCard>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Card>
                </BlockUi>
            </div>
        );
    }
}

export default injectIntl(AddRule);