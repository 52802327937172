import React, { Component } from 'react';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import BlockUi from 'react-block-ui';
import { deleteAPI, getAPI, postAPI } from '../Base/API';
import { ErrorAlert } from '../Common/ErrorAlert';
import { handleNotification } from '../Base/Notification';
import Authorization from '../Base/Authorization';

export class RenderPaybyrdWebookConfig extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            webhook: null
        };
    }

    componentDidMount() {
        this.getWebhookConfigs();
    }

    getWebhookConfigs = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response && data.response.length > 0) {
                if (data.response[0].data && data.response[0].data.length > 0) {
                    this.setState({ error: errorMessage, block: false, webhook: data.response[0].data[0] });
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/Price/Payment/v1/paybyrd/webhook`);
    }

    createWebhook = () => {
        this.setState({ block: true });
        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                }
                if (data.response && data.response.length > 0) {
                    if (data.response[0].data) {
                        handleNotification(data, <FormattedMessage id="PaymentGateway.SuccessSaveWebookCreaction" />, <FormattedMessage id="generic.success" />);
                        this.setState({ error: errorMessage, block: false, webhook: data.response[0].data });
                    }
                    else {
                        this.setState({ error: errorMessage, block: false });
                    }
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/Price/Payment/v1/paybyrd/webhook`);
    }

    removeWebhook = () => {
        const { webhook } = this.state;
        if (webhook) {
            this.setState({ block: true });
            deleteAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ error: errorMessage, block: false });
                    }
                    else {
                        handleNotification(data, <FormattedMessage id="PaymentGateway.SuccessSaveWebookRemove" />, <FormattedMessage id="generic.success" />);
                        this.setState({ error: errorMessage, block: false, webhook: null });
                    }
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }, `/api/Price/Payment/v1/paybyrd/webhook/${webhook.id}`);
        }
    }

    render() {
        const { block, error, webhook } = this.state;

        return (
            <div className="pt-2">
                <ErrorAlert error={error} />
                <BlockUi tag="div" blocking={block}>
                    <div>
                        <Row>
                            <Col className={global.isMobile ? "d-flex justify-content-between align-items-center mb-2" : ""}>
                                <h5>
                                    <FormattedMessage id="PaymentGateway.WebhookSettings" />
                                </h5>
                            </Col>
                        </Row>
                        <hr className="my-1" />
                        <FormGroup row>
                            <Label sm={2}>
                                <FormattedMessage id="PaymentGateway.Url" />
                            </Label>
                            <Col sm={10}>
                                <Input type="text" name="url" id="url" value={webhook ? webhook.url : ''} disabled />
                            </Col>
                        </FormGroup>
                        <Authorization
                            perform="paybyrd:webhook"
                            yes={() => (
                                <Row className="mb-2">
                                    <Col className={`d-flex ${global.isMobile ? 'justify-content-end' : ''}`}>
                                        <Button className="btn btn-host btn-sm mr-2" onClick={this.createWebhook} disabled={!(webhook === null || webhook.id === null)}>
                                            <i className="fas fa-plus" />
                                        </Button>
                                        <Button className="btn btn-host btn-sm" onClick={this.removeWebhook} disabled={webhook === null || webhook.id === null}>
                                            <i className="fas fa-trash-alt" />
                                        </Button>
                                    </Col>
                                </Row>
                            )}
                            no={() => <div></div>}
                        />
                    </div>
                </BlockUi>
            </div>
        );
    }
}